import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

import { CVTypography } from '../../components/ui-elements/CVTypography';
import { CVHeadline } from '../../components/ui-parts/CVHeadline';
import usageBanner from '../../images/usage_banner.png';
import usageCopylight from '../../images/usage_copyright.png';
import usageListen from '../../images/usage_listen.png';

const tableOfContentsBodyMarginLeft = { xs: 1, md: 2 };

export const Usage = () => (
  <>
    <Box>
      <CVHeadline size="h1">CREEVOの使いかた</CVHeadline>
    </Box>

    <Box
      sx={{
        backgroundColor: 'bg.secondary',
        marginTop: 4.75,
        marginLeft: { xs: 0, md: 2 },
        marginRight: { xs: 0, md: 2 },
        marginBottom: 4.75,
        paddingY: 4,
        paddingX: { xs: 2, md: 4 },
      }}
    >
      <Box>
        <CVTypography size="t2">目次</CVTypography>
      </Box>
      <Box sx={{ marginLeft: { xs: 1, md: 2 }, marginBottom: 1 }}>
        <Box sx={{ marginTop: 3 }}>
          <CVTypography size="t3">
            <a href="#compose">作品を作成する</a>
          </CVTypography>
        </Box>
        <Box sx={{ marginTop: 2, marginLeft: tableOfContentsBodyMarginLeft }}>
          <CVTypography size="b1">
            <a href="#auto-and-design">おまかせ作曲とデザイン作曲</a>
          </CVTypography>
        </Box>
        <Box sx={{ marginTop: 3 }}>
          <CVTypography size="t3" sx={{ marginTop: 2 }}>
            <a href="#auto-compose">おまかせ作曲の場合</a>
          </CVTypography>
        </Box>
        <Box sx={{ marginTop: 2, marginLeft: tableOfContentsBodyMarginLeft }}>
          <CVTypography size="b1">
            <a href="#auto-lyrics">歌詞を入力</a>
          </CVTypography>
          <CVTypography size="b1">
            <a href="#auto-yomigana">読みがなを編集</a>
          </CVTypography>
          <CVTypography size="b1">
            <a href="#auto-title">タイトルを入力する</a>
          </CVTypography>
          <CVTypography size="b1">
            <a href="#auto-choose-sample">メロディーサンプルの生成と選択</a>
          </CVTypography>
          <CVTypography size="b1">
            <a href="#auto-complete">生成したメロディーの確認</a>
          </CVTypography>
        </Box>
        <Box sx={{ marginTop: 3 }}>
          <CVTypography size="t3">
            <a href="#design-compose">デザイン作曲の場合</a>
          </CVTypography>
        </Box>
        <Box sx={{ marginTop: 2, marginLeft: tableOfContentsBodyMarginLeft }}>
          <CVTypography size="b1">
            <a href="#design-lyrics">歌詞を入力</a>
          </CVTypography>
          <CVTypography size="b1">
            <a href="#design-yomigana">読みがなを編集</a>
          </CVTypography>
          <CVTypography size="b1">
            <a href="#design-melody-detail">曲調をデザインする</a>
          </CVTypography>
          <CVTypography size="b1">
            <a href="#design-choose-melody">好きなメロディーのサンプルを選ぶ</a>
          </CVTypography>
          <CVTypography size="b1">
            <a href="#design-title">タイトルを入力する</a>
          </CVTypography>
          <CVTypography size="b1">
            <a href="#design-complete">生成したメロディーの確認</a>
          </CVTypography>
        </Box>
        <Box sx={{ marginTop: 3 }}>
          <CVTypography size="t3">
            <a href="#listen-works">作品を聴く</a>
          </CVTypography>
        </Box>
        <Box sx={{ marginTop: 3 }}>
          <CVTypography size="t3">
            <a href="#copyright">作品の著作権について</a>
          </CVTypography>
        </Box>
      </Box>
    </Box>

    <Box
      sx={{
        textAlign: 'center',
        marginTop: 10,
      }}
    >
      <Box
        component="img"
        src={usageBanner}
        sx={{
          width: { lg: '75%', md: '100%', xs: '100%' },
          borderRadius: '9.259259% / 26.66667%',
        }}
      />
    </Box>

    <Box>
      <Box sx={{ marginTop: 3 }} id="compose">
        <CVHeadline size="h2">作品を作成する</CVHeadline>
        <CVTypography
          size="b1"
          sx={{
            marginTop: 1,
            paddingLeft: { xs: 2, md: 5 },
            paddingRight: { xs: 0, md: 5 },
          }}
        >
          Creevoでは、入力した歌詞に対して、設定に応じたメロディーを生成することが出来ます。
          <br />
          生成したあとには、楽譜や音声などの閲覧とダウンロードが出来ます。
          <br />
          作品を作成するには、<Link to="/terms-and-conditions/">利用規約</Link>
          に同意してから<Link to="/composition/select-type/">作曲ページ</Link>
          に行きます。
        </CVTypography>
      </Box>

      <Box sx={{ marginTop: 3 }} id="auto-and-design">
        <CVHeadline size="h3">おまかせ作曲とデザイン作曲</CVHeadline>
        <CVTypography
          size="b1"
          sx={{
            marginTop: 1,
            paddingLeft: { xs: 2, md: 5 },
            paddingRight: { xs: 0, md: 5 },
          }}
        >
          Creevoには「おまかせ作曲」と「デザイン作曲」の2つの作曲モードがあります。まずはどちらのモードで作曲を行うか選んでください。
          <br />
          おまかせ作曲では、入力された歌詞から全自動で作曲を行います。いくつかのメロディーサンプルを生成されますので、この中から最もよいと思ったサンプルを1つ選んでください。この選択によって、最終的な作品が決まります。
          <br />
          デザイン作曲では、歌詞のほかにテンポやコード進行、伴奏楽器など様々な設定を指定することができ、この設定に基づいてメロディーサンプルが生成されます。
        </CVTypography>
      </Box>

      <Box sx={{ marginTop: 3 }} id="auto-compose">
        <CVHeadline size="h2">おまかせ作曲の場合</CVHeadline>
      </Box>

      <Box sx={{ marginTop: 3 }} id="auto-lyrics">
        <CVHeadline size="h3">歌詞を入力</CVHeadline>
        <CVTypography
          size="b1"
          sx={{
            marginTop: 1,
            paddingLeft: { xs: 2, md: 5 },
            paddingRight: { xs: 0, md: 5 },
          }}
        >
          作曲に使う歌詞を、歌詞ボックスに入力します。
          <br />
          歌詞の長さは10文字から50文字が目安です。
          <br />
          入力したら、「次へ」を押してください。
          <br />
          ここに入力した歌詞は作品ページにそのまま表示されます。
          <br />
          歌詞には、誹謗中傷を含む内容や公序良俗に反する内容は使えません。
        </CVTypography>
      </Box>

      <Box sx={{ marginTop: 3 }} id="auto-yomigana">
        <CVHeadline size="h3">読みがなを編集</CVHeadline>
        <CVTypography
          size="b1"
          sx={{
            marginTop: 1,
            paddingLeft: { xs: 2, md: 5 },
            paddingRight: { xs: 0, md: 5 },
          }}
        >
          入力された歌詞から読みがなが生成されます。これを変更したい場合は、このページで編集してください。
          <br />
          読みがなボックスでは、平仮名と記号「()ー・^_」が利用できます。
          <br />
          基本的に平仮名1文字に対して1音符生成されます。「っ」や二重母音は半角括弧「()」で囲うことで1音符になります。
          <br />
          各小節には最大16音符まで入れることができます。
          <br />
          読みがなは1小節ごとにスペースまたは改行で区切ってください。
          <br />
          記号「ー」は音の延長、「・」は休符を表すものとして使えます。これらは自動かな変換によって付くことがあります。
          <br />
          記号「^」と「_」は、アクセントの上下を表します。メロディー生成の際にメロディー音高の上下に反映されます。
          <br />
          読みがなを確認したら、「次へ」を押してください。
        </CVTypography>
      </Box>

      <Box sx={{ marginTop: 3 }} id="auto-title">
        <CVHeadline size="h3">タイトルを入力する</CVHeadline>
        <CVTypography
          size="b1"
          sx={{
            marginTop: 1,
            paddingLeft: { xs: 2, md: 5 },
            paddingRight: { xs: 0, md: 5 },
          }}
        >
          作品を作成するために、タイトルを入力します。
          <br />
          歌声音声と歌詞入りの楽譜は、作者自身のみが閲覧・ダウンロードできます（ログインしていない場合は一定時間のみ可能）。
          <br />
          タイトルや歌詞などの言語情報を伏せた作品（インストゥルメンタル版）は、作品一覧ページにて公開されます。
          <br />
          <b>
            本システムで生成された作品は、
            <a
              href="https://creativecommons.org/publicdomain/mark/1.0/deed.ja"
              target="_blank"
              rel="noreferrer noopener nofollow"
            >
              パブリックドメイン
            </a>
            （著作権なし）のデータとして扱われます。
          </b>
          <br />
          作品を作成する時点で、データの取り扱いを含む
          <Link to="/terms-and-conditions/">利用規約</Link>
          に同意したものと見なされます。
        </CVTypography>
      </Box>

      <Box sx={{ marginTop: 3 }} id="auto-choose-sample">
        <CVHeadline size="h3">メロディーサンプルの生成と選択</CVHeadline>
        <CVTypography
          size="b1"
          sx={{
            marginTop: 1,
            paddingLeft: { xs: 2, md: 5 },
            paddingRight: { xs: 0, md: 5 },
          }}
        >
          メロディーサンプルの生成をお待ちください。1分ほどでメロディーサンプルが表示されます。
          <br />
          おまかせ作曲では、メロディーサンプルが3つ生成されます。それぞれのメロディーの再生ボタンを押して視聴します。
          <br />
          気に入ったメロディーサンプルを、再生ボタン下のチェックボックスで選択してください。
          <br />
          最後に、作品の作成を完了するために「次へ」を押してください。
        </CVTypography>
      </Box>

      <Box sx={{ marginTop: 3 }} id="auto-complete">
        <CVHeadline size="h3">生成したメロディーの確認</CVHeadline>
        <CVTypography
          size="b1"
          sx={{
            marginTop: 1,
            paddingLeft: { xs: 2, md: 5 },
            paddingRight: { xs: 0, md: 5 },
          }}
        >
          生成した楽曲を閲覧・ダウンロードすることができます。
          <br />
          作曲を行った本人には歌詞付きの音声が、作曲者以外のユーザーには楽器のみの音声がそれぞれ視聴可能になります。
          <br />
          作曲時にログインしていた場合は、楽曲が保存され、いつでも楽曲が閲覧できます。ログインしていない場合は、20分後には歌詞付きの音声を視聴することができなくなりますのでご注意ください。
          <br />
          生成した楽曲を評価することで、楽譜ファイルや動画ファイルの閲覧・ダウンロードが可能になります。Creevoは利用者の皆様の評価を基に進化していくため、ぜひ生成した楽曲に星をつけて評価をお願いします。
        </CVTypography>
      </Box>

      <Box sx={{ marginTop: 3 }} id="design-compose">
        <CVHeadline size="h2">デザイン作曲の場合</CVHeadline>
      </Box>

      <Box sx={{ marginTop: 3 }} id="design-lyrics">
        <CVHeadline size="h3">歌詞を入力</CVHeadline>
        <CVTypography
          size="b1"
          sx={{
            marginTop: 1,
            paddingLeft: { xs: 2, md: 5 },
            paddingRight: { xs: 0, md: 5 },
          }}
        >
          作曲に使う歌詞を、歌詞ボックスに入力します。
          <br />
          歌詞の長さは10文字から50文字が目安です。
          <br />
          入力したら、「次へ」を押してください。
          <br />
          ここに入力した歌詞は作品ページにそのまま表示されます。
          <br />
          歌詞には、誹謗中傷を含む内容や公序良俗に反する内容は使えません。
        </CVTypography>
      </Box>

      <Box sx={{ marginTop: 3 }} id="design-yomigana">
        <CVHeadline size="h3">読みがなを編集</CVHeadline>
        <CVTypography
          size="b1"
          sx={{
            marginTop: 1,
            paddingLeft: { xs: 2, md: 5 },
            paddingRight: { xs: 0, md: 5 },
          }}
        >
          入力された歌詞から読みがなが生成されます。これを変更したい場合は、このページで編集してください。
          <br />
          読みがなボックスでは、平仮名と記号「()ー・^_」が利用できます。
          <br />
          基本的に平仮名1文字に対して1音符生成されます。「っ」や二重母音は半角括弧「()」で囲うことで1音符になります。
          <br />
          各小節には最大16音符まで入れることができます。
          <br />
          読みがなは1小節ごとにスペースまたは改行で区切ってください。
          <br />
          記号「ー」は音の延長、「・」は休符を表すものとして使えます。これらは自動かな変換によって付くことがあります。
          <br />
          記号「^」と「_」は、アクセントの上下を表します。メロディー生成の際にメロディー音高の上下に反映されます。
          <br />
          読みがなを確認したら、「次へ」を押してください。
        </CVTypography>
      </Box>

      <Box sx={{ marginTop: 3 }} id="design-melody-detail">
        <CVHeadline size="h3">曲調をデザインする</CVHeadline>
        <CVTypography
          size="b1"
          sx={{
            marginTop: 1,
            paddingLeft: { xs: 2, md: 5 },
            paddingRight: { xs: 0, md: 5 },
          }}
        >
          デザイン作曲では、メロディー生成のために「コード進行」「メロディーのスタイル（雰囲気）」「伴奏（パターン・使用楽器）」を設定することができます。お好みで設定してください。
          <br />
          さらに、これらの設定を用いて生成したメロディーサンプルから、好きな箇所を組み合わせて新たなメロディーを作成することができます。これについては、以下で詳しく紹介します。
        </CVTypography>
      </Box>

      <Box sx={{ marginTop: 3 }} id="design-choose-melody">
        <CVHeadline size="h3">好きなメロディーのサンプルを選ぶ</CVHeadline>
        <CVTypography
          size="b1"
          sx={{
            marginTop: 1,
            paddingLeft: { xs: 2, md: 5 },
            paddingRight: { xs: 0, md: 5 },
          }}
        >
          おまかせ作曲では、生成したメロディーを組み合わせて好みのメロディーに編集することができます。
          <br />
          まず、サンプルを生成するために「生成」ボタンを押します。そうすると、上の「生成サンプル」画面に複数のメロディーの選択肢が現れるので、このサンプルたちの中から小節ごとに好きなサンプルを選択します。
          <br />
          下にある「選択メロディー」の画面には、選択中のサンプルが表示されています。
          <br />
          一度選択した小節を解除するには、上の「生成サンプル」画面で青く選択されている小説をもう一度クリックすると解除されます。
          <br />
          入力が終わったら、「確定する」を押して次の画面へ進みます。
        </CVTypography>
      </Box>

      <Box sx={{ marginTop: 3 }} id="design-title">
        <CVHeadline size="h3">タイトルを入力する</CVHeadline>
        <CVTypography
          size="b1"
          sx={{
            marginTop: 1,
            paddingLeft: { xs: 2, md: 5 },
            paddingRight: { xs: 0, md: 5 },
          }}
        >
          作品を作成するために、タイトルを入力します。
          <br />
          歌声音声と歌詞入りの楽譜は、作者自身のみが閲覧・ダウンロードできます（ログインしていない場合は一定時間のみ可能）。
          <br />
          タイトルや歌詞などの言語情報を伏せた作品（インストゥルメンタル版）は、作品一覧ページにて公開されます。
          <br />
          <b>
            本システムで生成された作品は、
            <a
              href="https://creativecommons.org/publicdomain/mark/1.0/deed.ja"
              target="_blank"
              rel="noreferrer noopener nofollow"
            >
              パブリックドメイン
            </a>
            （著作権なし）のデータとして扱われます。
          </b>
          <br />
          作品を作成する時点で、データの取り扱いを含む
          <Link to="/terms-and-conditions/">利用規約</Link>
          に同意したものと見なされます。
        </CVTypography>
      </Box>

      <Box sx={{ marginTop: 3 }} id="design-complete">
        <CVHeadline size="h3">生成したメロディーの確認</CVHeadline>
        <CVTypography
          size="b1"
          sx={{
            marginTop: 1,
            paddingLeft: { xs: 2, md: 5 },
            paddingRight: { xs: 0, md: 5 },
          }}
        >
          生成した楽曲を閲覧・ダウンロードすることができます。
          <br />
          作曲を行った本人には歌詞付きの音声が、作曲者以外のユーザーには楽器のみの音声がそれぞれ視聴可能になります。
          <br />
          作曲時にログインしていた場合は、楽曲が保存され、いつでも楽曲が閲覧できます。ログインしていない場合は、20分後には歌詞付きの音声を視聴することができなくなりますのでご注意ください。
          <br />
          生成した楽曲を評価することで、楽譜ファイルや動画ファイルの閲覧・ダウンロードが可能になります。Creevoは利用者の皆様の評価を基に進化していくため、ぜひ生成した楽曲に星をつけて評価をお願いします。
        </CVTypography>
      </Box>
    </Box>

    <Box
      sx={{
        textAlign: 'center',
        marginTop: 12,
      }}
    >
      <Box
        component="img"
        src={usageListen}
        sx={{
          width: { lg: '75%', md: '100%', xs: '100%' },
          borderRadius: '9.259259% / 26.66667%',
        }}
      />
    </Box>

    <Box>
      <Box sx={{ marginTop: 3 }} id="listen-works">
        <CVHeadline size="h2">作品を聴く</CVHeadline>
        <CVTypography
          size="b1"
          sx={{
            marginTop: 1,
            paddingLeft: { xs: 2, md: 5 },
            paddingRight: { xs: 0, md: 5 },
          }}
        >
          保存された作品一覧は
          <Link to="/projects/">みんなの作品一覧ページ</Link>
          にて見ることができます。
          <br />
          ログイン状態で保存した自分の作品一覧は、
          <Link to="/user-projects/">自分の作品一覧ページ</Link>
          にて、ログイン後に見ることができます。
          <br />
          リストにある作品をクリックすると、音が聴けます。
          <br />
          「みんなの作曲一覧」よりアクセスした場合、作品のインストゥルメンタル版のみ視聴できますが、「自分の作品一覧」よりアクセスした場合は、作品の楽譜の閲覧・ダウンロードや歌声合成版の視聴が可能です。
        </CVTypography>
      </Box>
    </Box>

    <Box
      sx={{
        textAlign: 'center',
        marginTop: 12,
      }}
    >
      <Box
        component="img"
        src={usageCopylight}
        sx={{
          width: { lg: '75%', md: '100%', xs: '100%' },
          borderRadius: '9.259259% / 26.66667%',
        }}
      />
    </Box>

    <Box
      sx={{
        marginBottom: 12,
      }}
    >
      <Box sx={{ marginTop: 3 }} id="copyright">
        <CVHeadline size="h2">作品の著作権について</CVHeadline>
        <CVTypography
          size="b1"
          sx={{
            marginTop: 1,
            paddingLeft: { xs: 2, md: 5 },
            paddingRight: { xs: 0, md: 5 },
          }}
        >
          CREEVOで生成されたメロディーや伴奏は、
          <b>
            <a
              href="https://creativecommons.org/publicdomain/mark/1.0/deed.ja"
              target="_blank"
              rel="noreferrer noopener nofollow"
            >
              パブリックドメイン
            </a>
            （著作権なし）
          </b>
          のデータとして扱われます。
          Sinsyの歌声が入っている音声データを公開・配布する際には「Sinsy」と表示する必要があります。
          コンピューターで自動生成された音楽についての法律的問題については、以下の記事が参考になります。
        </CVTypography>
        <CVTypography
          size="b1"
          sx={{
            marginTop: 1,
            paddingLeft: { xs: 2, md: 5 },
            paddingRight: { xs: 0, md: 5 },
          }}
        >
          トップコート国際法律事務所 解説記事
          <a
            href="https://topcourt-law.com/ai-iot/ai_copyright#i-27"
            target="_blank"
            rel="noreferrer noopener nofollow"
          >
            「AIの創作物に誰が著作権を持つのか？AIの法律問題を弁護士が解説」
          </a>
        </CVTypography>
        <CVTypography
          size="b1"
          sx={{
            marginTop: 1,
            paddingLeft: { xs: 2, md: 5 },
            paddingRight: { xs: 0, md: 5 },
          }}
        >
          データの取り扱いについては、
          <Link to="/terms-and-conditions/">利用規約</Link>をご確認ください。
        </CVTypography>
      </Box>
    </Box>
    <Box
      sx={{
        marginBottom: 30,
      }}
    />
  </>
);
