import { Box } from '@mui/material';

import { CVTypography } from '../ui-elements/CVTypography';

interface NewsItem {
  date: string;
  message: string;
}

interface Props {
  items: NewsItem[];
}

export const News = (props: Props) => {
  const numberOfItems = props.items.length;
  return (
    <Box>
      {props.items.map((item, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            borderTop: 'solid',
            borderBottom: index + 1 === numberOfItems ? 'solid' : 'none',
            borderWidth: 1,
            borderColor: 'base.disabled',
          }}
        >
          <CVTypography
            size="c1"
            sx={{
              marginTop: 2.25,
              marginBottom: 2.25,
              marginLeft: 2,
              marginRight: 2,
            }}
          >
            {item.date.replaceAll('-', '/')}
          </CVTypography>
          <CVTypography size="c1" sx={{ marginTop: 2.25, marginBottom: 2.25, marginLeft: 1.25 }}>
            {/* NewsはFirebaseよりHTML形式でfetchする仕様のため、ここではno-dangerをsuppressしてHTML要素を直接挿入する */}
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: item.message }} />
          </CVTypography>
        </Box>
      ))}
    </Box>
  );
};
