import { Box } from '@mui/material';

import { CVTypography } from '../../components/ui-elements/CVTypography';
import { CVHeadline } from '../../components/ui-parts/CVHeadline';

const MemberTable = () => (
  <Box component="table" sx={{ width: '100%', marginLeft: 2 }}>
    <Box component="tr">
      <Box component="td">
        <CVTypography size="b1">名前</CVTypography>
      </Box>
      <Box component="td">
        <CVTypography size="b1">期間</CVTypography>
      </Box>
      <Box component="td">
        <CVTypography size="b1">担当</CVTypography>
      </Box>
    </Box>
    <Box component="tr">
      <Box component="td">
        <CVTypography size="b1" sx={{ marginLeft: 2 }}>
          中村 栄太
        </CVTypography>
      </Box>
      <Box component="td">
        <CVTypography size="b1" sx={{ marginLeft: 2 }}>
          2020年 6月 - 現在
        </CVTypography>
      </Box>
      <Box component="td">
        <CVTypography size="b1" sx={{ marginLeft: 2 }}>
          プロジェクト運営
        </CVTypography>
      </Box>
    </Box>
    <Box component="tr">
      <Box component="td">
        <CVTypography size="b1" sx={{ marginLeft: 2 }}>
          柴田 剛
        </CVTypography>
      </Box>
      <Box component="td">
        <CVTypography size="b1" sx={{ marginLeft: 2 }}>
          2020年 6月 – 現在
        </CVTypography>
      </Box>
      <Box component="td">
        <CVTypography size="b1" sx={{ marginLeft: 2 }}>
          サーバー立ち上げ、フロントエンド、バックエンド
        </CVTypography>
      </Box>
    </Box>
    <Box component="tr">
      <Box component="td">
        <CVTypography size="b1" sx={{ marginLeft: 2 }}>
          大山 偉永
        </CVTypography>
      </Box>
      <Box component="td">
        <CVTypography size="b1" sx={{ marginLeft: 2 }}>
          2021年 6月 - 現在
        </CVTypography>
      </Box>
      <Box component="td">
        <CVTypography size="b1" sx={{ marginLeft: 2 }}>
          バックエンド、サーバー管理
        </CVTypography>
      </Box>
    </Box>
    <Box component="tr">
      <Box component="td">
        <CVTypography size="b1" sx={{ marginLeft: 2 }}>
          齋藤 遼太郎
        </CVTypography>
      </Box>
      <Box component="td">
        <CVTypography size="b1" sx={{ marginLeft: 2 }}>
          2021年 6月 - 現在
        </CVTypography>
      </Box>
      <Box component="td">
        <CVTypography size="b1" sx={{ marginLeft: 2 }}>
          フロントエンド
        </CVTypography>
      </Box>
    </Box>
    <Box component="tr">
      <Box component="td">
        <CVTypography size="b1" sx={{ marginLeft: 2 }}>
          上野 和歌子
        </CVTypography>
      </Box>
      <Box component="td">
        <CVTypography size="b1" sx={{ marginLeft: 2 }}>
          2022年 5月 - 現在
        </CVTypography>
      </Box>
      <Box component="td">
        <CVTypography size="b1" sx={{ marginLeft: 2 }}>
          デザイナー
        </CVTypography>
      </Box>
    </Box>
    <Box component="tr">
      <Box component="td">
        <CVTypography size="b1" sx={{ marginLeft: 2 }}>
          橋爪 雅史
        </CVTypography>
      </Box>
      <Box component="td">
        <CVTypography size="b1" sx={{ marginLeft: 2 }}>
          2023年 2月 - 現在
        </CVTypography>
      </Box>
      <Box component="td">
        <CVTypography size="b1" sx={{ marginLeft: 2 }}>
          フロントエンド、バックエンド
        </CVTypography>
      </Box>
    </Box>
    <Box component="tr">
      <Box component="td">
        <CVTypography size="b1" sx={{ marginLeft: 2 }}>
          音部 拓海
        </CVTypography>
      </Box>
      <Box component="td">
        <CVTypography size="b1" sx={{ marginLeft: 2 }}>
          2023年 5月 - 現在
        </CVTypography>
      </Box>
      <Box component="td">
        <CVTypography size="b1" sx={{ marginLeft: 2 }}>
          フロントエンド
        </CVTypography>
      </Box>
    </Box>
    <Box component="tr">
      <Box component="td">
        <CVTypography size="b1" sx={{ marginLeft: 2 }}>
          Du Yicheng
        </CVTypography>
      </Box>
      <Box component="td">
        <CVTypography size="b1" sx={{ marginLeft: 2 }}>
          2021年 4月 – 2021年 10月
        </CVTypography>
      </Box>
      <Box component="td">
        <CVTypography size="b1" sx={{ marginLeft: 2 }}>
          サーバー管理、フロントエンド、バックエンド
        </CVTypography>
      </Box>
    </Box>
    <Box component="tr">
      <Box component="td">
        <CVTypography size="b1" sx={{ marginLeft: 2 }}>
          錦見 亮
        </CVTypography>
      </Box>
      <Box component="td">
        <CVTypography size="b1" sx={{ marginLeft: 2 }}>
          2020年 6月 – 2021年 3月
        </CVTypography>
      </Box>
      <Box component="td">
        <CVTypography size="b1" sx={{ marginLeft: 2 }}>
          UI担当
        </CVTypography>
      </Box>
    </Box>
  </Box>
);

export const About = () => (
  <>
    <Box sx={{ marginTop: 4.375 }}>
      <CVHeadline size="h1">CREEVOについて</CVHeadline>
    </Box>
    <Box
      sx={{
        backgroundColor: 'bg.secondary',
        marginTop: 4.75,
        marginLeft: 2,
        marginRight: 2,
        padding: 4,
      }}
    >
      <Box>
        <CVTypography size="t2">目次</CVTypography>
      </Box>
      <Box sx={{ marginTop: 3, marginLeft: 2, marginBottom: 1 }}>
        <CVTypography size="t3">
          <a href="#EvolvingSystem">進化する自動作曲システム</a>
        </CVTypography>
        <CVTypography size="t3" sx={{ marginTop: 2.5 }}>
          <a href="#Goal">CREEVOの目的</a>
        </CVTypography>
        <CVTypography size="t3" sx={{ marginTop: 2.5 }}>
          <a href="#Researches">自動作曲の研究</a>
        </CVTypography>
        <CVTypography size="t3" sx={{ marginTop: 2.5 }}>
          <a href="#Members">CREEVO開発プロジェクトのメンバー</a>
        </CVTypography>
        <CVTypography size="t3" sx={{ marginTop: 2.5 }}>
          <a href="#Acknowledgments">謝辞</a>
        </CVTypography>
      </Box>
    </Box>
    <Box id="EvolvingSystem" sx={{ marginTop: 9 }}>
      <CVHeadline size="h2">進化する自動作曲システム</CVHeadline>
      <CVTypography size="b1" sx={{ marginLeft: 4, marginRight: 4 }}>
        CREEVO（クリーボ）を使えば、入力した歌詞からオリジナルなメロディーを簡単に創れます。
        利用者の評価を基にして、新しい自動作曲（作曲AI）モデルが生まれ続けます。
        みんなで使えばどんどん高品質で新しい音楽が創作できるようになる、それがCREEVOの目指す世界です。
      </CVTypography>
    </Box>
    <Box id="Goal" sx={{ marginTop: 7 }}>
      <CVHeadline size="h2">CREEVOの目的</CVHeadline>
      <CVTypography size="b1" sx={{ marginLeft: 4, marginRight: 4 }}>
        このシステムは、文化の創造と進化 (creation and evolution)
        を支える知能の仕組みを理解するための研究プロジェクトの一部として運営しています。
        既存の音楽の模倣を目指す従来のAI技術よりも先進的なシステムです。
        このシステムは研究成果の社会還元を行う場であり、一般参加協力型の科学実験の場でもあります。
        システムの利用にあたりましては、ご理解とご支援のほどよろしくお願いいたします。
      </CVTypography>
      <CVTypography size="b1" sx={{ textAlign: 'right', marginTop: 2.25, marginRight: 4 }}>
        京都大学 白眉センター 中村栄太
      </CVTypography>
    </Box>
    <Box sx={{ marginTop: 7 }}>
      <Box id="Researches">
        <CVHeadline size="h2">自動作曲の研究</CVHeadline>
        <CVTypography size="b1" sx={{ marginLeft: 4, marginRight: 4 }}>
          コンピュータを使った自動作曲は1950年代から研究されており、これまで多くの自動作曲システムが開発されています。
          いくつかのシステムを紹介します。
        </CVTypography>
      </Box>
      <Box sx={{ marginTop: 6 }}>
        <CVHeadline size="h3">
          <a href="https://www.orpheus-music.org/" target="_blank" rel="noreferrer noopener nofollow">
            Orpheus (オルフェウス)
          </a>
        </CVHeadline>
        <CVTypography size="b1" sx={{ marginLeft: 4, marginRight: 4 }}>
          日本語の歌詞に対してメロディーを自動作曲してくれるシステムです。
          東京大学（開発当時）の嵯峨山茂樹氏らにより開発されました。
          メロディーの動きを確率モデルで表現することで、日本語の高低アクセントに沿ったメロディーが生成される仕組みになっています。
          また、コード進行、リズムパターン、伴奏パターンなどを組み合わせることで多様なスタイルの楽曲が生成できるため、
          完全自動作曲を超えた音楽創作支援システムとして用いることができます。
          Web上で一般利用可能で高度な自動作曲システムとして、世界的にも先駆的な研究成果です。
          Orpheusのシステム構成は、CREEVOの設計でも多くの部分で参考になっています。
        </CVTypography>
      </Box>
      <Box sx={{ marginTop: 6 }}>
        <CVHeadline size="h3">
          <a href="https://folkrnn.org/" target="_blank" rel="noreferrer noopener nofollow">
            FolkRNN
          </a>
        </CVHeadline>
        <CVTypography size="b1" sx={{ marginLeft: 4, marginRight: 4 }}>
          アイリッシュ音楽などのメロディーを自動生成してくれるシステムです。
          英国ロンドン大学クイーンメアリ校（開発当時）のボブ・スターム氏らにより開発されました。
          セクション構造や反復構造を持つ楽曲を自動生成することができます。
          大量の楽譜データで学習した再帰的ニューラルネットワーク (RNN) による手法が用いられています。
        </CVTypography>
      </Box>
      <Box sx={{ marginTop: 6 }}>
        <CVHeadline size="h3">
          <a href="https://openai.com/blog/jukebox/" target="_blank" rel="noreferrer noopener nofollow">
            Open AI Jukebox
          </a>
        </CVHeadline>
        <CVTypography size="b1" sx={{ marginLeft: 4, marginRight: 4 }}>
          ジャンル、アーティスト、歌詞を入力すると、伴奏付きの音楽を出力するシステムです。 米国の非営利団体 OpenAI
          により開発されました。 楽譜を経由することなく、直接音楽音声ファイルを生成することができます。
          VQ-VAEという深層学習手法を用いて設計されています。
        </CVTypography>
      </Box>
    </Box>
    <Box id="Members" sx={{ marginTop: 9.75 }}>
      <CVHeadline size="h2">CREEVO開発プロジェクトのメンバー</CVHeadline>
      <MemberTable />
    </Box>
    <Box id="Acknowledgments" sx={{ marginTop: 7, marginBottom: 60 }}>
      <CVHeadline size="h2">謝辞</CVHeadline>
      <CVTypography size="b1" sx={{ marginLeft: 4, marginRight: 4 }}>
        本プロジェクトは
        <a href="https://www.hakubi.kyoto-u.ac.jp/" target="_blank" rel="noreferrer noopener nofollow">
          京都大学白眉プロジェクト
        </a>
        および日本学術振興会 科学研究費 19K20340、22H03661、JST 創発的研究支援事業 JPMJFR226X からの支援を受けています。
      </CVTypography>
    </Box>
  </>
);
