import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import * as React from 'react';

import { CVTypography } from '../components/ui-elements/CVTypography';

interface Props {
  errorMsg: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ValidationError = (props: Props) => (
  <Dialog open={props.open} onClose={() => props.setOpen(false)}>
    <DialogTitle>読みがなを確認してください</DialogTitle>
    <DialogContent>
      <DialogContentText>
        <CVTypography size="c1">{props.errorMsg}</CVTypography>
        <DialogActions>
          <Button onClick={() => props.setOpen(false)} color="primary" variant="text">
            OK
          </Button>
        </DialogActions>
      </DialogContentText>
    </DialogContent>
  </Dialog>
);
