import { useSelector } from 'react-redux';

import { ApiError } from '../models/ApiError';
import { myListErrorSelector } from '../selectors/myListSelector';
import { errorSelector as projectErrorSelector } from '../selectors/projectSelector';
import { rankingErrorSelector } from '../selectors/rankingSelector';
import { userErrorSelector } from '../selectors/userSelector';

import { Error } from './Error';

interface Props {
  children: JSX.Element;
}

type Error = ApiError | null;

export const ErrorProvider = (props: Props) => {
  const projectError = useSelector(projectErrorSelector);
  const myListError = useSelector(myListErrorSelector);
  const rankingError = useSelector(rankingErrorSelector);
  const userError = useSelector(userErrorSelector);
  const errors: Error[] = [userError, projectError, myListError, rankingError];

  if (errors.some((error) => error !== null)) {
    const code = errors.find((error) => error !== null)?.code ?? 500;
    const message = errors.find((error) => error !== null)?.message ?? '不明なエラーが発生しました';
    return <Error errorCode={code} errorMsg={message} />;
  }
  return props.children;
};
