import { Box } from '@mui/material';
import { ReactNode } from 'react';

import { CVTypography } from '../ui-elements/CVTypography';

interface Props {
  children: ReactNode;
  size: 'h1' | 'h2' | 'h3';
}

export const CVHeadline = (props: Props) => {
  switch (props.size) {
    case 'h1': {
      return (
        <Box sx={{ color: 'primary.main' }}>
          <CVTypography size="t1">{props.children}</CVTypography>
        </Box>
      );
    }
    case 'h2': {
      return (
        <CVTypography size="t2">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                width: '1em', // 親要素のfontsizeを継承するようにemで指定した
                height: '1em',
                borderRadius: '50%',
                backgroundColor: 'primary.main',
                flexShrink: 0,
                marginRight: 1.25,
              }}
            />
            {props.children}
          </Box>
        </CVTypography>
      );
    }
    case 'h3': {
      return (
        <CVTypography size="t3">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                width: '1em', // 親要素のfontsizeを継承するようにemで指定した
                height: '1em',
                borderRadius: '50%',
                backgroundColor: 'secondary.main',
                marginRight: '10px',
                flexShrink: 0,
              }}
            />
            {props.children}
          </Box>
        </CVTypography>
      );
    }
    default: {
      return null;
    }
  }
};
