import { Slider, Box } from '@mui/material';

import { DESIGN_CONFIG, DEFAULT_DESIGN_CONFIG_VALUE } from '../../../../constants';
import { CVTypography } from '../../../ui-elements/CVTypography';

/*
  遊び度（configではbeta）
    範囲：1~10 、整数
    デフォルト値：5
    （できれば）変更時の説明：吹き出しで（「メロディーの遊び度を変更できます。1：最も典型的、10：最もはちゃめちゃ」）
    対応：1から順に：beta = 2.5 , 2.0 , 1.7 , 1.5 , 1.3 , 1.15 ,
*/

interface Props {
  betaIndex: number;
  onChange: (newBetaIndex: number) => void;
}

export const BetaSlider = (props: Props) => {
  const L = DESIGN_CONFIG.beta.length;
  const marks = [];
  const onChange = (_: Event, index: number | number[]) => {
    if (typeof index === 'number') {
      props.onChange(index);
    }
  };
  for (let i = 0; i < L; i++) {
    const mark = (i + 1).toString();
    const type = i === 0 ? '典型的' : i === L - 1 ? 'はちゃめちゃ' : '';
    marks.push({
      value: i,
      label: (
        <Box style={{ textAlign: 'center' }}>
          {mark}
          <br />
          {type}
        </Box>
      ),
    });
  }

  return (
    <Box sx={{ marginLeft: 4, marginRight: 4, marginTop: 6 }}>
      <CVTypography size="c1" sx={{ color: 'base.secondary' }}>
        遊び度
      </CVTypography>
      <Slider
        min={0}
        max={DESIGN_CONFIG.beta.length - 1}
        onChange={onChange}
        marks={marks}
        value={props.betaIndex}
        defaultValue={DEFAULT_DESIGN_CONFIG_VALUE.betaIndex}
        color="primary"
      />
    </Box>
  );
};
