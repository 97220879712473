import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

import { CVTypography } from '../ui-elements/CVTypography';

export const FooterMenu = () => (
  <Box sx={{ display: 'flex', marginLeft: '10%', marginBottom: 7.75 }}>
    <CVTypography size="b2" sx={{ marginRight: 5, color: 'primary.main' }}>
      <Link to="/terms-and-conditions/" style={{ textDecoration: 'underline', color: '#008B3A' }}>
        利用規約
      </Link>
    </CVTypography>
    <CVTypography size="b2" sx={{ marginRight: 5, color: 'primary.main' }}>
      <Link to="/contact/" style={{ textDecoration: 'underline', color: '#008B3A' }}>
        お問い合わせ
      </Link>
    </CVTypography>
    <CVTypography size="b2" sx={{ marginRight: 5, color: 'primary.main' }}>
      <a
        href="https://twitter.com/CreevoMusic"
        target="_blank"
        rel="noreferrer noopener nofollow"
        style={{ textDecoration: 'underline', color: '#008B3A' }}
      >
        公式Twitter
      </a>
    </CVTypography>
    <CVTypography size="b2" sx={{ color: 'primary.main' }}>
      <a
        href="https://twitter.com/intent/tweet?text=%E9%80%B2%E5%8C%96%E3%81%99%E3%82%8B%E8%87%AA%E5%8B%95%E4%BD%9C%E6%9B%B2CREEVO%20%23CREEVO%20%23%E8%87%AA%E5%8B%95%E4%BD%9C%E6%9B%B2%0A%20https%3A%2F%2Fcreevo-music.com%20"
        target="_blank"
        rel="noreferrer noopener nofollow"
        style={{ textDecoration: 'underline', color: '#008B3A' }}
      >
        CreevoをTwitterで共有する
      </a>
    </CVTypography>
  </Box>
);
