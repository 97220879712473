import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import * as api from '../api/ProjectApi';
import { saveTmpProjects, recreateTmpProjects } from '../api/TmpProjectsApi';
import {
  Candidate,
  ChordProgression,
  CompositionType,
  ModelParameter,
  AccompPattern,
  DrumPattern,
  Note,
  ProjectParameters,
} from '../models';
import { ApiError } from '../models/ApiError';
import { assertIsDefined } from '../utils/Assert';
import { handleError } from '../utils/handleError';

import { UserInfo } from './userSlice';

export interface ProjectState {
  isGeneratingMelody: boolean;
  isConvertingMelodyToAbcString: boolean;
  isConvertingLyrics: boolean;
  isFetchingChordProgressions: boolean;
  isFetchingModelParameters: boolean;
  isFetchingAccompPatterns: boolean;
  isFetchingDrumPatterns: boolean;
  isSavingProject: boolean;
  compositionType: CompositionType;
  chordProgressions: ChordProgression[];
  modelParameters: ModelParameter[];
  accompPatterns: AccompPattern[];
  drumPatterns: DrumPattern[];
  candidate: Candidate;
  nBar: number;
  lyrics: string;
  lyricsYomi: string;
  savedProject: {
    id: number | null;
    key: string | null;
  };
  abcStringForAudition: string | null;
  errorMessage: ApiError | null;
}

const initialState: ProjectState = {
  isGeneratingMelody: false,
  isConvertingMelodyToAbcString: false,
  isConvertingLyrics: false,
  isFetchingChordProgressions: false,
  isFetchingModelParameters: false,
  isFetchingAccompPatterns: false,
  isFetchingDrumPatterns: false,
  isSavingProject: false,
  compositionType: '',
  chordProgressions: [],
  modelParameters: [],
  accompPatterns: [],
  drumPatterns: [],
  candidate: new Candidate(),
  nBar: 0,
  lyrics: '',
  lyricsYomi: '',
  savedProject: {
    id: null,
    key: null,
  },
  abcStringForAudition: '',
  errorMessage: null,
};

interface GenerateMelodyParameters extends ProjectParameters {
  minPitch: number;
  maxPitch: number;
  beta: number;
  alpha: number;
  anacrusis: number;
  numberSample: number;
}
interface SaveProjectParameters extends ProjectParameters {
  userInfo: UserInfo;
}
type SaveTmpProjectParameters = Pick<ProjectParameters, 'title' | 'username' | 'lyrics' | 'lyricsYomi'>;

type RecreateTmpProjectParameters = {
  tmpProjectGroupId: number;
  tmpProjectGroupKey: string;
};

export const onConvertLyrics = createAsyncThunk<
  { lyrics: string; lyricsYomi: string; nBar: number },
  { lyrics: string },
  { rejectValue: ApiError }
>('project/onConvertLyrics', async (params, { rejectWithValue }) => {
  try {
    const response = await api.convertLyrics(params.lyrics);
    const { lyricsYomi } = response.data;
    const trimmed = lyricsYomi?.trim();
    const nBar = trimmed ? trimmed.split(/\s+/).length : 0;
    return { lyrics: params.lyrics, lyricsYomi, nBar };
  } catch (e) {
    return rejectWithValue(handleError(e));
  }
});

export const onGenerateMelody = createAsyncThunk<
  {
    melodies: Note[][][][];
    chordSequence: ChordProgression['chordSequence'];
    sampleId: number;
  },
  GenerateMelodyParameters,
  { rejectValue: ApiError }
>('project/onGenerateMelody', async (params, { rejectWithValue }) => {
  try {
    const response = await api.generateMelody(
      params.title,
      params.username,
      params.chordProgressionId,
      params.modelParameterId,
      params.accompPatternId1,
      params.accompInstrumentId1,
      params.accompPatternId2,
      params.accompInstrumentId2,
      params.drumPatternId,
      params.lyrics,
      params.lyricsYomi,
      params.minPitch,
      params.maxPitch,
      params.key,
      params.beta,
      params.alpha,
      params.instrument,
      params.bpm,
      params.anacrusis,
      params.fixBar,
      params.numberSample
    );
    return {
      melodies: response.data.melodies,
      chordSequence: response.data.chordProgression.chordSequence,
      sampleId: response.data.sampleId,
    };
  } catch (e) {
    return rejectWithValue(handleError(e));
  }
});

export const onGetABCString = createAsyncThunk<{ abcString: string }, ProjectParameters, { rejectValue: ApiError }>(
  'project/onGetABCString',
  async (params, { rejectWithValue }) => {
    try {
      const response = await api.convertSelectedMelodyToAbcString(
        params.title,
        params.username,
        params.chordProgressionId,
        params.modelParameterId,
        params.accompPatternId1,
        params.accompInstrumentId1,
        params.accompPatternId2,
        params.accompInstrumentId2,
        params.drumPatternId,
        params.lyrics,
        params.lyricsYomi,
        params.key,
        params.instrument,
        params.bpm,
        params.fixBar
      );
      const abcString = response.data.abcString as string;
      assertIsDefined(abcString);
      return { abcString };
    } catch (e) {
      return rejectWithValue(handleError(e));
    }
  }
);

export const onGetChordProgressions = createAsyncThunk<
  { chordProgressions: ChordProgression[] },
  void,
  { rejectValue: ApiError }
>('project/onGetChordProgressions', async (_, { rejectWithValue }) => {
  try {
    const response = await api.getChordProgressions();
    return { chordProgressions: response.data.chordProgressions };
  } catch (e) {
    return rejectWithValue(handleError(e));
  }
});

export const onGetModelParameters = createAsyncThunk<
  { modelParameters: ModelParameter[] },
  void,
  { rejectValue: ApiError }
>('project/onGetModelParameters', async (_, { rejectWithValue }) => {
  try {
    const response = await api.getModelParameters();
    return { modelParameters: response.data.modelParameters };
  } catch (e) {
    return rejectWithValue(handleError(e));
  }
});

export const onGetAccompPatterns = createAsyncThunk<
  { accompPatterns: AccompPattern[] },
  void,
  { rejectValue: ApiError }
>('project/onGetAccompPatterns', async (_, { rejectWithValue }) => {
  try {
    const response = await api.getAccompPatterns();
    return { accompPatterns: response.data.accompPatterns };
  } catch (e) {
    return rejectWithValue(handleError(e));
  }
});

export const onGetDrumPatterns = createAsyncThunk<{ drumPatterns: DrumPattern[] }, void, { rejectValue: ApiError }>(
  'project/onGetDrumPatterns',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getDrumPatterns();
      return { drumPatterns: response.data.drumPatterns };
    } catch (e) {
      return rejectWithValue(handleError(e));
    }
  }
);

export const onSaveProject = createAsyncThunk<
  { savedProject: { id: number; key: string } },
  SaveProjectParameters,
  { rejectValue: ApiError }
>('project/onSaveProject', async (params, { rejectWithValue }) => {
  try {
    const response = await api.saveProject(
      params.title,
      params.username,
      params.chordProgressionId,
      params.modelParameterId,
      params.accompPatternId1,
      params.accompInstrumentId1,
      params.accompPatternId2,
      params.accompInstrumentId2,
      params.drumPatternId,
      params.lyrics,
      params.lyricsYomi,
      params.key,
      params.instrument,
      params.bpm,
      params.fixBar,
      params.userInfo
    );
    return {
      savedProject: {
        id: response.data.projectId,
        key: response.data.projectKey,
      },
    };
  } catch (e) {
    return rejectWithValue(handleError(e));
  }
});

export const onSaveTmpProjects = createAsyncThunk<
  { savedProject: { id: number; key: string } },
  SaveTmpProjectParameters,
  { rejectValue: ApiError }
>('project/onSaveTmpProjects', async (params, { rejectWithValue }) => {
  try {
    const response = await saveTmpProjects(params.title, params.username, params.lyrics, params.lyricsYomi);
    return {
      savedProject: {
        id: response.data.tmpProjectGroupId,
        key: response.data.tmpProjectGroupKey,
      },
    };
  } catch (e) {
    return rejectWithValue(handleError(e));
  }
});

export const onRecreateTmpProjects = createAsyncThunk<
  { savedProject: { id: number; key: string } },
  RecreateTmpProjectParameters,
  { rejectValue: ApiError }
>('project/onRecreateTmpProjects', async (params, { rejectWithValue }) => {
  try {
    const response = await recreateTmpProjects(params.tmpProjectGroupId, params.tmpProjectGroupKey);
    return {
      savedProject: {
        id: response.data.tmpProjectGroupId,
        key: response.data.tmpProjectGroupKey,
      },
    };
  } catch (e) {
    return rejectWithValue(handleError(e));
  }
});

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    selectCompositionType: (state, action: PayloadAction<CompositionType>) => ({
      ...state,
      compositionType: action.payload,
    }),
    updateLyricsYomi: (state, action: PayloadAction<string>) => {
      const trimmed = action.payload.trim();
      // TODO
      // const { minNBar, maxNBar } = getNBarRange(state.chordProgressions);
      // const res = validateLyricsYomi(trimmed, minNBar, maxNBar);
      return {
        ...state,
        lyricsYomi: action.payload,
        // TODO: エラー処理を設ける必要あり
        nBar: trimmed ? trimmed.split(/\s+/).length : 0,
      };
    },
    clearMelodies: (state) => ({
      ...state,
      candidate: new Candidate(),
    }),
    clearProject: (state) => ({
      ...state,
      isGeneratingMelody: initialState.isGeneratingMelody,
      isConvertingMelodyToAbcString: initialState.isConvertingMelodyToAbcString,
      isConvertingLyrics: initialState.isConvertingLyrics,
      isFetchingChordProgressions: initialState.isFetchingChordProgressions,
      isFetchingModelParameters: initialState.isFetchingModelParameters,
      isFetchingAccompPatterns: initialState.isFetchingModelParameters,
      isFetchingDrumPatterns: initialState.isFetchingDrumPatterns,
      isSavingProject: initialState.isSavingProject,
      chordProgressions: initialState.chordProgressions,
      modelParameters: initialState.modelParameters,
      accompPatterns: initialState.accompPatterns,
      drumPatterns: initialState.drumPatterns,
      candidate: initialState.candidate,
      lyricsYomi: initialState.lyricsYomi,
      errorMessage: initialState.errorMessage,
    }),
    clearSavedProject: (state) => ({
      ...state,
      savedProject: initialState.savedProject,
    }),
    clearErrorMessage: (state) => ({
      ...state,
      errorMessage: initialState.errorMessage,
    }),
    toggleBarIsFixed: (
      state,
      action: PayloadAction<{
        sampleIndex: number;
        phraseIndex: number;
        barIndex: number;
      }>
    ) => {
      const newCandidate = state.candidate.copy();
      newCandidate.toggleBarIsFixed(action.payload.sampleIndex, action.payload.phraseIndex, action.payload.barIndex);

      return {
        ...state,
        candidate: newCandidate,
      };
    },
    setAllBarIsFixed: (
      state,
      action: PayloadAction<{
        sampleIndex: number;
        isFixed: boolean;
      }>
    ) => {
      const newCandidate = state.candidate.copy();
      newCandidate.setBarIsFixed(action.payload.sampleIndex, action.payload.isFixed);

      return {
        ...state,
        candidate: newCandidate,
      };
    },
    removeSample: (
      state,
      action: PayloadAction<{
        sampleIndex: number;
      }>
    ) => {
      const newCandidate = state.candidate.copy();
      newCandidate.removeSample(action.payload.sampleIndex);

      return {
        ...state,
        candidate: newCandidate,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(onConvertLyrics.pending, (state) => ({
      ...state,
      isConvertingLyrics: true,
    }));
    builder.addCase(onConvertLyrics.fulfilled, (state, action) => ({
      ...state,
      isConvertingLyrics: false,
      lyrics: action.payload.lyrics,
      lyricsYomi: action.payload.lyricsYomi,
      nBar: action.payload.nBar,
    }));
    builder.addCase(onConvertLyrics.rejected, (state, action) => ({
      ...state,
      isConvertingLyrics: false,
      errorMessage: action.payload ?? null,
    }));

    builder.addCase(onGetChordProgressions.pending, (state) => ({
      ...state,
      isFetchingChordProgressions: true,
    }));
    builder.addCase(onGetChordProgressions.fulfilled, (state, action) => ({
      ...state,
      isFetchingChordProgressions: false,
      chordProgressions: action.payload.chordProgressions,
    }));
    builder.addCase(onGetChordProgressions.rejected, (state, action) => ({
      ...state,
      isFetchingChordProgressions: false,
      errorMessage: action.payload ?? null,
    }));

    builder.addCase(onGetModelParameters.pending, (state) => ({
      ...state,
      isFetchingModelParameters: true,
    }));
    builder.addCase(onGetModelParameters.fulfilled, (state, action) => ({
      ...state,
      isFetchingModelParameters: false,
      modelParameters: action.payload.modelParameters,
    }));
    builder.addCase(onGetModelParameters.rejected, (state, action) => ({
      ...state,
      isFetchingModelParameters: false,
      errorMessage: action.payload ?? null,
    }));

    builder.addCase(onGetAccompPatterns.pending, (state) => ({
      ...state,
      isFetchingAccompPatterns: true,
    }));
    builder.addCase(onGetAccompPatterns.fulfilled, (state, action) => ({
      ...state,
      isFetchingAccompPatterns: false,
      accompPatterns: action.payload.accompPatterns,
    }));
    builder.addCase(onGetAccompPatterns.rejected, (state, action) => ({
      ...state,
      isFetchingAccompPatterns: false,
      errorMessage: action.payload ?? null,
    }));

    builder.addCase(onGetDrumPatterns.pending, (state) => ({
      ...state,
      isFetchingDrumPatterns: true,
    }));
    builder.addCase(onGetDrumPatterns.fulfilled, (state, action) => ({
      ...state,
      isFetchingDrumPatterns: false,
      drumPatterns: action.payload.drumPatterns,
    }));
    builder.addCase(onGetDrumPatterns.rejected, (state, action) => ({
      ...state,
      isFetchingDrumPatterns: false,
      errorMessage: action.payload ?? null,
    }));

    builder.addCase(onGenerateMelody.pending, (state) => ({
      ...state,
      isGeneratingMelody: true,
    }));
    builder.addCase(onGenerateMelody.fulfilled, (state, action) => ({
      ...state,
      isGeneratingMelody: false,
      candidate: new Candidate(
        action.payload.melodies,
        action.payload.chordSequence,
        action.payload.sampleId,
        state.candidate
      ),
    }));
    builder.addCase(onGenerateMelody.rejected, (state, action) => ({
      ...state,
      isGeneratingMelody: false,
      errorMessage: action.payload ?? null,
    }));

    builder.addCase(onGetABCString.pending, (state) => ({
      ...state,
      isConvertingMelodyToAbcString: true,
    }));
    builder.addCase(onGetABCString.fulfilled, (state, action) => ({
      ...state,
      isConvertingMelodyToAbcString: false,
      abcStringForAudition: action.payload.abcString,
    }));
    builder.addCase(onGetABCString.rejected, (state, action) => ({
      ...state,
      isConvertingMelodyToAbcString: false,
      errorMessage: action.payload ?? null,
    }));

    builder.addCase(onSaveProject.pending, (state) => ({
      ...state,
      isSavingProject: true,
    }));
    builder.addCase(onSaveProject.fulfilled, (state, action) => ({
      ...state,
      isSavingProject: false,
      savedProject: action.payload.savedProject,
    }));
    builder.addCase(onSaveProject.rejected, (state, action) => ({
      ...state,
      isSavingProject: false,
      errorMessage: action.payload ?? null,
    }));

    builder.addCase(onSaveTmpProjects.pending, (state) => ({
      ...state,
      isSavingProject: true,
    }));
    builder.addCase(onSaveTmpProjects.fulfilled, (state, action) => ({
      ...state,
      isSavingProject: false,
      savedProject: action.payload.savedProject,
    }));
    builder.addCase(onSaveTmpProjects.rejected, (state, action) => ({
      ...state,
      isSavingProject: false,
      errorMessage: action.payload ?? null,
    }));
    builder.addCase(onRecreateTmpProjects.pending, (state) => ({
      ...state,
      savedProject: initialState.savedProject,
      isGeneratingMelody: true,
    }));
    builder.addCase(onRecreateTmpProjects.fulfilled, (state, action) => ({
      ...state,
      isGeneratingMelody: false,
      savedProject: action.payload.savedProject,
    }));
    builder.addCase(onRecreateTmpProjects.rejected, (state, action) => ({
      ...state,
      isGeneratingMelody: false,
      errorMessage: action.payload ?? null,
    }));
  },
});

export const projectReducer = projectSlice.reducer;
