const isCharHiragana = (s: string): boolean => Boolean((s == null ? '' : s).match(/^[ぁ-ん]$/));

export type YomiValidRes = {
  isOK: boolean;
  message: string;
};

export const validateLyricsYomi = (lyricsYomi: string, minNBar: number, maxNBar: number): YomiValidRes => {
  /**
   * x 小節数：コード進行の小節数 == 歌詞の小節数
   * x 音符数：１音符以上16音符以下かどうか
   * - 想定されていない文字・記号の対処：
   *     x 想定文字（ひらがな，'ー'，'・'，'('，')'，'_'，'^'）以外がないかどうか
   *     x カッコの対応関係と深さ，カッコ内は１文字以上
   *         x 深さは０以上１以下
   *         x カッコ内は１文字以上
   *         x カッコは小節をまたげない
   *     x アクセント記号の文法はとりあえず無視
   *     x スペースの重複を許可
   *     - 長音記号
   *         - 前の文字があるか
   */
  const errorMessage = ((): string => {
    lyricsYomi = lyricsYomi.trim();

    const yomiOfBars: string[] = lyricsYomi.split(/\s+/);

    if (!(minNBar <= yomiOfBars.length && yomiOfBars.length <= maxNBar)) {
      return `空白で区切られた歌詞の小節数は${minNBar}以上、${maxNBar}以下の必要があります`;
    }

    let sumNchars = 0;
    for (let i = 0; i < yomiOfBars.length; i++) {
      let nchars = 0;
      let ncharsInParens = 0;
      let depth = 0;
      for (let j = 0; j < yomiOfBars[i].length; j++) {
        const symbol = yomiOfBars[i][j];
        if (symbol === '(') {
          depth++;
          if (depth > 1) {
            return `第${i + 1}小節${j + 1}文字目: 左カッコが多すぎます`;
          }
        } else if (symbol === ')') {
          depth--;
          if (depth < 0) {
            return `第${i + 1}小節${j + 1}文字目: 右カッコが多すぎます`;
          }
          if (ncharsInParens <= 0) {
            return `第${i + 1}小節${j + 1}文字目: カッコ内に読みが含まれていません`;
          }
          ncharsInParens = 0;
          nchars++;
        } else if (symbol === '^') {
          // Ignore
        } else if (symbol === '_') {
          // Ignore
        } else if (symbol === 'ー' || symbol === '・') {
          if (depth > 0) {
            ncharsInParens++;
          } else {
            nchars++;
          }
        } else if (isCharHiragana(symbol)) {
          if (depth > 0) {
            ncharsInParens++;
          } else {
            nchars++;
          }
        } else {
          return `第${i + 1}小節${
            j + 1
          }文字目: "${symbol}"は使えない文字です（ひらがなと記号"ー ・ ^ _ ( )"のみ使えます）`;
        }

        if (i === 0 && j === 0) {
          if (symbol === 'ー') {
            return `「ー」記号は一番始めには使えません`;
          }
        }
      }
      if (depth !== 0) {
        return `第${i + 1}小節: カッコが閉じていません`;
      }
      if (nchars < 0 || nchars > 16) {
        return `第${i + 1}小節: 文字数が多すぎます（${nchars}文字）。記号"ー"や"・"を含めて、最大16文字までです。`;
      }
      sumNchars += nchars;
    }

    if (sumNchars <= 0) {
      return `読みがなにひらがなが含まれていません`;
    }

    return '';
  })();

  return { isOK: errorMessage === '', message: errorMessage };
};
