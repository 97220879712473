import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';

import { Login } from '../../modals/Login';
import { ErrorProvider } from '../../pages/ErrorProvider';
import { userSelector } from '../../selectors/userSelector';
import { myListSlice } from '../../slices/myListSlice';
import { projectSlice } from '../../slices/projectSlice';
import { rankingSlice } from '../../slices/rankingSlice';
import { userSlice } from '../../slices/userSlice';
import { AppDispatch } from '../../store';

import { Footer } from './Footer';
import { FooterMenu } from './FooterMenu';
import { Header } from './Header';
import { MaintenanceModeProvider } from './Maintenance';

export const Layout = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const userState = useSelector(userSelector);

  const clearError = () => {
    dispatch(myListSlice.actions.clearErrorMessage());
    dispatch(projectSlice.actions.clearErrorMessage());
    dispatch(rankingSlice.actions.clearErrorMessage());
    dispatch(userSlice.actions.clearErrorMessage());
  };

  useEffect(() => {
    clearError();
  }, [location]);

  return (
    <Box
      sx={{
        minHeight: '100vh',
        position: 'relative',
        boxSizing: 'border-box',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
        sx={{
          minHeight: '100vh',
        }}
      >
        <Header />
        <Box sx={{ marginX: { xs: 2, md: 22.5 } }}>
          <ErrorProvider>
            <MaintenanceModeProvider>
              <Outlet />
            </MaintenanceModeProvider>
          </ErrorProvider>
        </Box>
        {userState.isDialogOpen && <Login />}
        {/* FooterMenu が下端に来るように空要素を配置 */}
        <Box style={{ flex: 1 }} />
        <FooterMenu />
        <Footer isTopPage={location.pathname === '/'} />
      </Box>
      <ScrollRestoration />
    </Box>
  );
};
