export {};

declare global {
  interface Array<T> {
    isEmpty(): boolean;
    last(): T | undefined;
  }
}

// eslint-disable-next-line no-extend-native
Array.prototype.isEmpty = function isEmpty() {
  return this.length === 0;
};

// eslint-disable-next-line no-extend-native
Array.prototype.last = function last<T>(this: Array<T>): T | undefined {
  return this[this.length - 1];
};
