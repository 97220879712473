import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import * as api from '../api/RankingApi';
import { ProjectDetail } from '../api/RankingApi';
import { WORKLIST_PROJECTS_PER_PAGE } from '../constants';
import { ProjectMetaData } from '../models';
import { ApiError } from '../models/ApiError';
import { handleError } from '../utils/handleError';

export interface ProjectList {
  projects: ProjectMetaData[];
  hasNext: boolean;
}

export interface Reference extends ProjectList {
  sinceId: number | null;
}

export interface RankingState {
  isProjectLoading: boolean;
  isProjectDetailLoading: boolean;
  references: Reference[];
  projectDetail: ProjectDetail | null;
  referenceIndex: number;
  errorMessage: ApiError | null;
}

const initialState: RankingState = {
  isProjectLoading: true,
  isProjectDetailLoading: false,
  references: [],
  projectDetail: null,
  referenceIndex: -1,
  errorMessage: null,
};

export const onFetchProjects = createAsyncThunk<ProjectList, { sinceId: number | null }, { rejectValue: ApiError }>(
  'ranking/onFetchProjects',
  async (params, { rejectWithValue }) => {
    try {
      const response = await api.getProjects(WORKLIST_PROJECTS_PER_PAGE, params.sinceId);
      return response.data;
    } catch (e) {
      return rejectWithValue(handleError(e));
    }
  }
);

export const onFetchProjectDetail = createAsyncThunk<
  ProjectDetail,
  { projectId: number; projectKey: string | null },
  { rejectValue: ApiError }
>('ranking/onFetchProjectDetail', async (params, { rejectWithValue }) => {
  try {
    const response = await api.getProjectDetail(params.projectId, params.projectKey);
    return response.data;
  } catch (e) {
    return rejectWithValue(handleError(e));
  }
});

export const onUpdateSelfRating = createAsyncThunk<
  { newRating: number | null },
  { projectId: number; newRating: number | null; projectKey: string },
  { rejectValue: ApiError }
>('ranking/onUpdateSelfRating', async (params, { rejectWithValue }) => {
  if (params.newRating) {
    try {
      await api.putSelfRating(params.projectId, params.newRating, params.projectKey);
    } catch (e) {
      return rejectWithValue(handleError(e));
    }
  }
  return { newRating: params.newRating };
});

export const onPutPublicationAgreement = createAsyncThunk<
  { publicationAgreed: boolean },
  { projectId: number; publicationAgreed: boolean; projectKey: string },
  { rejectValue: ApiError }
>('ranking/onPutPublicationAgreement', async (params, { rejectWithValue }) => {
  try {
    await api.putPublicationAgreement(params.projectId, params.publicationAgreed, params.projectKey);
  } catch (e) {
    return rejectWithValue(handleError(e));
  }
  return { publicationAgreed: params.publicationAgreed };
});

export const rankingSlice = createSlice({
  name: 'ranking',
  initialState,
  reducers: {
    incrementReferenceIndex: (state) => ({
      ...state,
      referenceIndex: state.referenceIndex + 1,
    }),
    decrementReferenceIndex: (state) => ({
      ...state,
      referenceIndex: state.referenceIndex - 1,
    }),
    cleanAll: (state) => ({
      ...state,
      isProjectLoading: initialState.isProjectLoading,
      referenceIndex: initialState.referenceIndex,
      references: initialState.references,
      errorMessage: initialState.errorMessage,
    }),
    clearErrorMessage: (state) => ({
      ...state,
      errorMessage: initialState.errorMessage,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(onFetchProjects.pending, (state) => ({
      ...state,
      isProjectLoading: true,
    }));
    builder.addCase(onFetchProjects.fulfilled, (state, action) => ({
      ...state,
      references: state.references.concat({
        ...action.payload,
        sinceId: action.payload.projects.slice(-1)[0].projectId,
      }),
      referenceIndex: state.referenceIndex + 1,
      isProjectLoading: false,
    }));
    builder.addCase(onFetchProjects.rejected, (state) => ({
      ...state,
      isProjectLoading: false,
    }));
    builder.addCase(onFetchProjectDetail.pending, (state) => ({
      ...state,
      isProjectDetailLoading: true,
    }));
    builder.addCase(onFetchProjectDetail.fulfilled, (state, action) => ({
      ...state,
      projectDetail: action.payload,
      isProjectDetailLoading: false,
    }));
    builder.addCase(onFetchProjectDetail.rejected, (state) => ({
      ...state,
      isProjectDetailLoading: false,
    }));
    builder.addCase(onUpdateSelfRating.pending, (state) => ({
      ...state,
      isProjectDetailLoading: true,
    }));
    builder.addCase(onUpdateSelfRating.fulfilled, (state, action) => {
      if (action.payload.newRating !== null && state.projectDetail !== null) {
        return {
          ...state,
          isProjectDetailLoading: false,
          projectDetail: {
            ...state.projectDetail,
            project: {
              ...state.projectDetail.project,
              selfRating: action.payload.newRating,
            },
          },
        };
      }
      return {
        ...state,
        isProjectDetailLoading: false,
      };
    });
    builder.addCase(onPutPublicationAgreement.pending, (state) => ({
      ...state,
      isProjectDetailLoading: true,
    }));
    builder.addCase(onPutPublicationAgreement.fulfilled, (state, action) => {
      if (state.projectDetail !== null) {
        return {
          ...state,
          isProjectDetailLoading: false,
          projectDetail: {
            ...state.projectDetail,
            project: {
              ...state.projectDetail.project,
              publicationAgreed: action.payload.publicationAgreed,
            },
          },
        };
      }
      return {
        ...state,
        isProjectDetailLoading: false,
      };
    });
    builder.addCase(onPutPublicationAgreement.rejected, (state) => ({
      ...state,
      isProjectDetailLoading: false,
    }));
  },
});

export const rankingReducer = rankingSlice.reducer;
