import { isAxiosError } from 'axios';

import { ApiError } from '../models/ApiError';

export const handleError = (err: unknown): ApiError => {
  if (isAxiosError(err)) {
    return {
      message: err.message,
      code: err.response?.status ?? 500,
    };
  }
  return {
    message: '不明なエラー',
    code: 500,
  };
};
