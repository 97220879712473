import { Dialog, DialogContent } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { CVTypography } from '../components/ui-elements/CVTypography';
import { CVButton } from '../components/ui-parts/CVButton';
import { SignIn } from '../components/ui-parts/SignIn';
import { userSelector } from '../selectors/userSelector';
import { userSlice } from '../slices/userSlice';

export const Login = () => {
  const dispatch = useDispatch();
  const userState = useSelector(userSelector);
  const onClose = () => {
    const action = userSlice.actions.onCloseLogInDialog();
    dispatch(action);
  };

  return (
    <Dialog open={userState.isDialogOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent>
        <CVTypography size="t2" sx={{ textAlign: 'center', marginBottom: 2.5 }}>
          ログイン
        </CVTypography>
        <CVTypography size="b2" sx={{ textAlign: 'center' }}>
          ログインすることで、自分が作曲した曲をいつでも聴くことができます。
        </CVTypography>
        <SignIn />
        <CVTypography size="b2" sx={{ textAlign: 'center' }}>
          CREEVOではFirebase
          Authenticationをログインサービスとして利用しており、一切のユーザー情報をサーバー上に保管していません。
        </CVTypography>
      </DialogContent>
      <CVButton
        text="ログインせず続ける"
        size="ll"
        buttonColor="primary.light"
        onClick={onClose}
        sx={{ marginX: 'auto', marginBottom: 5 }}
      />
    </Dialog>
  );
};
