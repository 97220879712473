import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { User as FirebaseUser, getAuth, signOut } from 'firebase/auth';

import { firebaseApp } from '../firebase';
import { ApiError } from '../models/ApiError';
import { handleError } from '../utils/handleError';

export interface UserInfo {
  uid: string;
  avatar: string;
  idToken: string;
}

export interface UserState {
  isLoggedIn: boolean;
  isLoggedInLoading: boolean;
  isDialogOpen: boolean;
  userInfo: UserInfo;
  errorMessage: ApiError | null;
}

const initialState: UserState = {
  isLoggedInLoading: false,
  isLoggedIn: false,
  isDialogOpen: false,
  userInfo: {
    uid: '',
    avatar: '',
    idToken: '',
  },
  errorMessage: null,
};

export const onLogin = createAsyncThunk<UserInfo, FirebaseUser, { rejectValue: ApiError }>(
  'user/onLogin',
  async (params, { rejectWithValue }) => {
    try {
      const idToken = await params.getIdToken();
      return {
        uid: params.uid,
        avatar: params.photoURL ?? '',
        idToken,
      };
    } catch (e) {
      return rejectWithValue(handleError(e));
    }
  }
);

export const onLogOut = createAsyncThunk<void, void, { rejectValue: ApiError }>(
  'user/onLogOut',
  async (_params, { rejectWithValue }) => {
    try {
      const auth = getAuth(firebaseApp);
      await signOut(auth);
    } catch (e) {
      return rejectWithValue(handleError(e));
    }
  }
);

export const onRefreshIdToken = createAsyncThunk<string, void, { rejectValue: ApiError }>(
  'user/onRefreshIdToken',
  async (_, { rejectWithValue }) => {
    try {
      const auth = getAuth(firebaseApp);
      return (await auth.currentUser?.getIdToken()) ?? '';
    } catch (e) {
      return rejectWithValue(handleError(e));
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    onClickLogIn: (state) => ({
      ...state,
      isDialogOpen: true,
    }),
    onCloseLogInDialog: (state) => ({
      ...state,
      isDialogOpen: false,
    }),
    clearErrorMessage: (state) => ({
      ...state,
      errorMessage: initialState.errorMessage,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(onLogin.pending, (state) => ({
      ...state,
      isLoggedInLoading: true,
    }));
    builder.addCase(onLogin.fulfilled, (state, action) => ({
      ...state,
      isLoggedInLoading: false,
      isLoggedIn: true,
      isDialogOpen: false,
      userInfo: action.payload,
    }));
    builder.addCase(onLogin.rejected, (state, action) => ({
      ...state,
      isLoggedInLoading: false,
      isLoggedIn: false,
      isDialogOpen: false,
      userInfo: initialState.userInfo,
      errorMessage: action.payload ?? null,
    }));
    builder.addCase(onRefreshIdToken.fulfilled, (state, action) => ({
      ...state,
      userInfo: {
        ...state.userInfo,
        idToken: action.payload,
      },
    }));
    builder.addCase(onRefreshIdToken.rejected, (state, action) => ({
      ...state,
      errorMessage: action.payload ?? null,
    }));
    builder.addCase(onLogOut.pending, (state) => ({
      ...state,
      isLoggedInLoading: true,
    }));
    builder.addCase(onLogOut.fulfilled, (state) => ({
      ...state,
      isLoggedInLoading: initialState.isLoggedInLoading,
      isLoggedIn: initialState.isLoggedIn,
      isDialogOpen: initialState.isDialogOpen,
      userInfo: initialState.userInfo,
      errorMessage: initialState.errorMessage,
    }));
    builder.addCase(onLogOut.rejected, (state, action) => ({
      ...state,
      isLoggedInLoading: false,
      isDialogOpen: false,
      errorMessage: action.payload ?? null,
    }));
  },
});

export const userReducer = userSlice.reducer;
