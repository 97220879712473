import axios from 'axios';

import { API_BASE_URL } from '../constants';

import { getCountryCode } from './CountryCodeApi';

const initGetClient = async () => {
  const countryCode = await getCountryCode();
  const client = axios.create({
    baseURL: API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      'X-Engine-Language': 'ja',
      'X-Country-Code': countryCode,
    },
    timeout: 10000, // 10 seconds
  });
  return () => client;
};

const initGetClientPromise = initGetClient();

/**
 * Axiosクライアントを取得する関数
 *
 * 毎回、新しいクライアントを初期化することを防ぐために
 * `initGetClient`でクロージャーとしてAxiosクライアント取得関数を得る
 *
 * @returns {Promise<AxiosInstance>} AxiosクライアントのPromise
 */
const getClient = async () => {
  const initGetClient = await initGetClientPromise;
  return initGetClient();
};

export default getClient;
