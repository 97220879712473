import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { CVButton } from '../../components/ui-parts/CVButton';
import { CVHeadline } from '../../components/ui-parts/CVHeadline';
import { ProjectTable } from '../../components/ui-parts/ProjectTable';
import {
  rankingCurrentReferenceSelector,
  rankingIndexSelector,
  rankingIsProjectLoadingSelector,
  rankingReferencesSelector,
} from '../../selectors/rankingSelector';
import { onFetchProjects, rankingSlice } from '../../slices/rankingSlice';
import { AppDispatch } from '../../store';
// import { Loading } from "../misc/Loading";

const useQuery = () => new URLSearchParams(useLocation().search);

export const ProjectList = () => {
  const pageStr = useQuery().get('page');
  const dispatch = useDispatch<AppDispatch>();

  const references = useSelector(rankingReferencesSelector);
  const isProjectLoading = useSelector(rankingIsProjectLoadingSelector);
  const index = useSelector(rankingIndexSelector);

  const navigation = useNavigate();
  const currentReference = useSelector(rankingCurrentReferenceSelector);
  const onClickNext = () => {
    if (references[index + 1] === undefined) {
      void dispatch(onFetchProjects({ sinceId: currentReference.sinceId }));
    } else {
      dispatch(rankingSlice.actions.incrementReferenceIndex());
    }
    navigation(`/projects?page=${index + 2}`);
  };

  const onClickPrevious = () => {
    dispatch(rankingSlice.actions.decrementReferenceIndex());
    navigation(`/projects?page=${index}`);
  };
  const setSelectedProject = (projectId: number) => {
    navigation(`/projects/${projectId}`);
  };

  useEffect(() => {
    const page = pageStr !== null ? parseInt(pageStr, 10) : null;
    if (page === null || page > references.length) {
      dispatch(rankingSlice.actions.cleanAll());
      void dispatch(onFetchProjects({ sinceId: null }));
      navigation(`/projects`, { replace: true });
    } else {
      dispatch(rankingSlice.actions.decrementReferenceIndex());
    }
  }, []);

  if (isProjectLoading) {
    return null;
  }

  return (
    <>
      <Box>
        <CVHeadline size="h1">みんなの作品</CVHeadline>
      </Box>
      <br />
      <ProjectTable type="general" reference={currentReference} setSelectedProject={setSelectedProject} />
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: 17,
          marginBottom: 30,
          marginLeft: '15%',
          marginRight: '15%',
        }}
      >
        <CVButton
          text="前へ"
          onClick={onClickPrevious}
          size="l"
          buttonColor={index !== 0 ? 'primary.light' : 'base.disabled'}
          sx={{ marginTop: 2, marginX: 'auto' }}
          disabled={index === 0}
        />
        <CVButton
          text="次へ"
          onClick={onClickNext}
          size="l"
          buttonColor={currentReference.hasNext ? 'primary.main' : 'base.disabled'}
          sx={{ marginTop: 2, marginX: 'auto' }}
          disabled={!currentReference.hasNext}
        />
      </Box>
    </>
  );
};
