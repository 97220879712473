import { Project } from '../models';
import { UserInfo } from '../slices/userSlice';

import getCreevoClient from './CreevoClient';

export interface ProjectDetail {
  project: Project;
  scoreImagePath: string;
  scorePdfPath: string;
  scoreXmlPath: string;
  scoreMsczPath: string;
  audioPath: string;
  audioInstrPath: string;
  videoPath: string;
  zipPath: string;
  isValidKey: boolean;
  status: 'generating' | 'available' | 'archived';
}

interface GetProjectsResponse {
  projects: Project[];
  hasNext: boolean;
}

export const getProjects = async (perPage: number, sinceId: number | null, uid: string, idToken: string) => {
  const client = await getCreevoClient();
  return client.get<GetProjectsResponse>(
    sinceId === null
      ? `/users/${uid}/projects/?perPage=${perPage}`
      : `/users/${uid}/projects/?perPage=${perPage}&sinceId=${sinceId}`,
    {
      headers: { 'X-Authorization': `Bearer ${idToken}` },
    }
  );
};

export const getProjectDetail = async (projectId: number, userInfo: UserInfo) => {
  const client = await getCreevoClient();
  return client.get<ProjectDetail>(`/users/${userInfo.uid}/projects/${projectId}/`, {
    withCredentials: true,
    headers: { 'X-Authorization': `Bearer ${userInfo.idToken}` },
  });
};

export const putSelfRating = async (projectId: number, selfRating: number, userInfo: UserInfo) => {
  const data = {
    projectId,
    selfRating,
  };
  const client = await getCreevoClient();
  return client.put<null>(`/ranking/projects/self-rating/`, data, {
    headers: { 'X-Authorization': `Bearer ${userInfo.idToken}` },
  });
};

export const putPublicationAgreement = async (projectId: number, publicationAgreed: boolean, userInfo: UserInfo) => {
  const data = {
    projectId,
    publicationAgreed,
  };
  const client = await getCreevoClient();
  return client.put<null>(`/ranking/projects/publication-agreement/`, data, {
    headers: { 'X-Authorization': `Bearer ${userInfo.idToken}` },
  });
};

export const deleteProject = async (projectId: number, userInfo: UserInfo) => {
  const client = await getCreevoClient();
  return client.delete<null>(`/users/${userInfo.uid}/projects/${projectId}/`, {
    headers: { 'X-Authorization': `Bearer ${userInfo.idToken}` },
  });
};
