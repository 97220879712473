import { useEffect } from 'react';

export const TwitterTimeline = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  });
  return (
    <a className="twitter-timeline" data-width={600} data-height={600} href="https://twitter.com/CreevoMusic">
      Tweets by @CreevoMusic
    </a>
  );
};
