import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CVTypography } from '../../components/ui-elements/CVTypography';
import { CVButton } from '../../components/ui-parts/CVButton';
import { CVHeadline } from '../../components/ui-parts/CVHeadline';
import { CVStep } from '../../components/ui-parts/CVStep';
import { OldLoading } from '../../components/ui-parts/OldLoading';
import { AccompStyleSection } from '../../components/ui-parts/design/AccompStyleSection';
import { ChordProgressionSection } from '../../components/ui-parts/design/ChordProgressionSection';
import { MelodyStyleSection } from '../../components/ui-parts/design/MelodyStyleSection';
import { SelectMelodyHolderView } from '../../components/ui-parts/design/SelectMelodyHolderView';
import {
  DESIGN_CONFIG,
  DEFAULT_ACCOMP_INSTRUMENT1,
  DEFAULT_ACCOMP_INSTRUMENT2,
  DEFAULT_ACCOMP_PATTERN1,
  DEFAULT_ACCOMP_PATTERN2,
  DEFAULT_DRUM_PATTERN,
  DEFAULT_DESIGN_CONFIG_VALUE,
  LYRICS_SAMPLE,
  NUMBER_SAMPLE,
} from '../../constants';
import { useNavigateBlocker } from '../../hooks/useNavigateBlocker';
import { usePromptWhenReload } from '../../hooks/usePromptWhenReload';
import { InputTitle } from '../../modals/InputTitle';
import {
  chordProgressionsSelector,
  nBarSelector,
  modelParametersSelector,
  candidateSelector,
  lyricsSelector,
  lyricsYomiSelector,
  isGeneratingSelector,
  isConvertingMelodyToAbcStringSelector,
  savedProjectSelector,
  abcStringSelector,
  isSavingProjectSelector,
} from '../../selectors/projectSelector';
import { userSelector } from '../../selectors/userSelector';
import * as projectThunk from '../../slices/projectSlice';
import { projectSlice } from '../../slices/projectSlice';
import { AppDispatch } from '../../store';
import { assertIsDefined } from '../../utils/Assert';
import * as MelodyPlayer from '../../utils/melodyPlayer';

export const Design = () => {
  usePromptWhenReload();
  const dispatch = useDispatch<AppDispatch>();
  const navigation = useNavigate();

  const chordProgressions = useSelector(chordProgressionsSelector);
  const nBar = useSelector(nBarSelector);
  const usableChordProgressions = chordProgressions.filter((chordProgression) => chordProgression.nBar === nBar);

  const modelParameters = useSelector(modelParametersSelector);

  const candidate = useSelector(candidateSelector);

  const lyrics = useSelector(lyricsSelector);
  const lyricsYomi = useSelector(lyricsYomiSelector);

  const isGeneratingMelody = useSelector(isGeneratingSelector);

  const isConvertingMelodyToAbcString = useSelector(isConvertingMelodyToAbcStringSelector);

  const isSavingProject = useSelector(isSavingProjectSelector);

  const isWaitingApiResult = isGeneratingMelody || isConvertingMelodyToAbcString;

  const userState = useSelector(userSelector);

  const savedProject = useSelector(savedProjectSelector);

  const abcStringForAudition = useSelector(abcStringSelector);

  const [chordProgressionId, setChordProgressionId] = useState<number>(
    usableChordProgressions[Math.floor(Math.random() * usableChordProgressions.length) % 10].id
  );
  const [keyValue, setKeyValue] = useState<number>(0);
  const [modelParameterId, setModelParameterId] = useState<number>(
    modelParameters[Math.floor(Math.random() * modelParameters.length) % 10].id
  );
  const [bpm, setBpm] = useState<number>(150);
  const [instrumentValue, setInstrumentValue] = useState<number>(0);
  const [pitchRange, setPitchRange] = useState<{
    minPitch: number;
    maxPitch: number;
  }>({
    minPitch: DEFAULT_DESIGN_CONFIG_VALUE.pitch.min,
    maxPitch: DEFAULT_DESIGN_CONFIG_VALUE.pitch.max,
  });
  const [betaIndex, setBetaIndex] = useState<number>(DEFAULT_DESIGN_CONFIG_VALUE.betaIndex);
  const [alphaIndex, setAlphaIndex] = useState<number>(DEFAULT_DESIGN_CONFIG_VALUE.alphaIndex);
  const [anacrusis, _] = useState<number>(DEFAULT_DESIGN_CONFIG_VALUE.anacrusis);
  const [accompInstrumentValue1, setAccompInstrumentValue1] = useState<number>(DEFAULT_ACCOMP_INSTRUMENT1);
  const [accompInstrumentValue2, setAccompInstrumentValue2] = useState<number>(DEFAULT_ACCOMP_INSTRUMENT2);
  const [accompPatternValue1, setAccompPatternValue1] = useState<number>(DEFAULT_ACCOMP_PATTERN1);
  const [accompPatternValue2, setAccompPatternValue2] = useState<number>(DEFAULT_ACCOMP_PATTERN2);
  const [drumPatternValue, setDrumPatternValue] = useState<number>(DEFAULT_DRUM_PATTERN);

  const [playedNote, setPlayedNote] = useState<number>(0);
  const [isAuditionButtonClicked, setAuditionButtonClicked] = useState(false);

  const [isInputTitleModalOpen, setInputTitleModalOpen] = useState(false);
  const [title, setTitle] = useState('');

  const [compositionType] = useState<'partially' | 'fully'>('partially');

  const [savingStatus, setSavingStatus] = useState(false);

  const trimmedLyrics = lyrics.trim();
  const defaultTitle = trimmedLyrics !== '' ? trimmedLyrics.split(/\s+/)[0] : LYRICS_SAMPLE[0];

  const onChangePitchRange = (minPitch: number, maxPitch: number) => setPitchRange({ minPitch, maxPitch });
  const onChangeBetaIndex = (index: number) => setBetaIndex(index);
  const onChangeAlphaIndex = (index: number) => setAlphaIndex(index);
  // const onChangeAnacrusis = (anacrusis: number) => setAnacrusis(anacrusis);

  const onClickPrevious = () => navigation('/composition/input-lyrics-yomi');
  const onClickDetermine = () => {
    setInputTitleModalOpen(true);
  };

  const getFixBar = (nBar: number) =>
    candidate.isEmpty() ? Array(nBar).fill('-').join('\n') : candidate.getFixBarString();

  useEffect(() => {
    setTitle(defaultTitle);
  }, [defaultTitle]);

  const onGenerateMelody = () => {
    assertIsDefined(chordProgressionId);
    assertIsDefined(modelParameterId);
    assertIsDefined(accompPatternValue1);
    assertIsDefined(accompInstrumentValue1);
    assertIsDefined(accompPatternValue2);
    assertIsDefined(accompInstrumentValue2);
    assertIsDefined(drumPatternValue);
    void dispatch(
      projectThunk.onGenerateMelody({
        title,
        username: 'N/A', // author
        chordProgressionId,
        modelParameterId,
        accompPatternId1: accompPatternValue1,
        accompInstrumentId1: accompInstrumentValue1,
        accompPatternId2: accompPatternValue2,
        accompInstrumentId2: accompInstrumentValue2,
        drumPatternId: drumPatternValue,
        lyrics,
        lyricsYomi,
        minPitch: pitchRange.minPitch,
        maxPitch: pitchRange.maxPitch,
        key: keyValue,
        beta: DESIGN_CONFIG.beta[betaIndex],
        alpha: DESIGN_CONFIG.alpha[alphaIndex],
        instrument: instrumentValue,
        bpm,
        anacrusis,
        fixBar: getFixBar(nBar),
        numberSample: NUMBER_SAMPLE,
      })
    );
  };

  const onClearMelodies = () => {
    dispatch(projectSlice.actions.clearMelodies());
  };
  const onPlayMidi = async () => {
    assertIsDefined(chordProgressionId);
    assertIsDefined(modelParameterId);
    assertIsDefined(accompPatternValue1);
    assertIsDefined(accompInstrumentValue1);
    assertIsDefined(accompPatternValue2);
    assertIsDefined(accompInstrumentValue2);
    assertIsDefined(drumPatternValue);
    const fixBar = candidate.getFixBarString('void');

    await dispatch(
      projectThunk.onGetABCString({
        title,
        username: 'N/A', // author
        chordProgressionId,
        modelParameterId,
        accompPatternId1: accompPatternValue1,
        accompInstrumentId1: accompInstrumentValue1,
        accompPatternId2: accompPatternValue2,
        accompInstrumentId2: accompInstrumentValue2,
        drumPatternId: drumPatternValue,
        lyrics,
        lyricsYomi,
        key: keyValue,
        instrument: instrumentValue,
        bpm,
        fixBar,
      })
    );
    setAuditionButtonClicked(true);
  };

  const resetPlayedNote = () => setPlayedNote(0);

  const onStop = useCallback(() => {
    resetPlayedNote();
    setAuditionButtonClicked(false);
  }, []);

  const onStopMidi = () => {
    MelodyPlayer.stopMidi(onStop);
  };

  const onSave = () => {
    if (compositionType === 'partially') {
      onGenerateMelody();
    }
    setSavingStatus(true);
  };

  useEffect(() => {
    if (isGeneratingMelody || !savingStatus) return;
    assertIsDefined(chordProgressionId);
    assertIsDefined(modelParameterId);
    assertIsDefined(accompPatternValue1);
    assertIsDefined(accompInstrumentValue1);
    assertIsDefined(accompPatternValue2);
    assertIsDefined(accompInstrumentValue2);
    assertIsDefined(drumPatternValue);
    void dispatch(
      projectThunk.onSaveProject({
        title,
        username: 'N/A', // author
        chordProgressionId,
        modelParameterId,
        accompPatternId1: accompPatternValue1,
        accompInstrumentId1: accompInstrumentValue1,
        accompPatternId2: accompPatternValue2,
        accompInstrumentId2: accompInstrumentValue2,
        drumPatternId: drumPatternValue,
        lyrics,
        lyricsYomi,
        key: keyValue,
        instrument: instrumentValue,
        bpm,
        fixBar: getFixBar(nBar),
        userInfo: userState.userInfo,
      })
    );
  }, [isGeneratingMelody, savingStatus]);

  useEffect(() => {
    if (!savedProject.id && !savedProject.key) return;
    if (compositionType === 'partially') {
      dispatch(projectSlice.actions.clearMelodies());
    }
    if (userState.isLoggedIn) {
      navigation(`/composition/completed?pid=${savedProject.id}`);
    } else {
      navigation(`/composition/completed?pid=${savedProject.id}&project-key=${savedProject.key}`);
    }
    setSavingStatus(false);
  }, [savedProject]);

  // 視聴時にabcStringのステートへの反映を待って再生関連の処理を行うために、副作用フックとして実行
  useEffect(() => {
    if (abcStringForAudition === null) return;

    const onPlayingEvent = () => {
      setPlayedNote((prevPlayedNote) => prevPlayedNote + 1);
    };
    if (isAuditionButtonClicked) {
      void MelodyPlayer.playMidi(abcStringForAudition, resetPlayedNote, onPlayingEvent, onStop);
    }
  }, [isAuditionButtonClicked, abcStringForAudition, onStop]);

  useNavigateBlocker({
    allowPaths: ['/composition/completed', '/composition/input-lyrics-yomi'],
  });

  if (isSavingProject) {
    return <OldLoading />;
  }

  return (
    <>
      <Box sx={{ marginTop: 4.25 }}>
        <CVHeadline size="h1">デザインして作曲する</CVHeadline>
      </Box>
      <CVStep activeStep={2} />
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'start',
        }}
      >
        <CVHeadline size="h2">曲調をデザインする</CVHeadline>
      </Box>
      <ChordProgressionSection
        chordProgressionId={chordProgressionId}
        setChordProgressionId={setChordProgressionId}
        keyValue={keyValue}
        setKeyValue={setKeyValue}
      />
      <MelodyStyleSection
        modelParameterId={modelParameterId}
        setModelParameterId={setModelParameterId}
        setBpm={setBpm}
        instrumentValue={instrumentValue}
        setInstrumentValue={setInstrumentValue}
        pitchRange={pitchRange}
        betaIndex={betaIndex}
        alphaIndex={alphaIndex}
        // anacrusis={anacrusis}
        onChangePitchRange={onChangePitchRange}
        onChangeBetaIndex={onChangeBetaIndex}
        onChangeAlphaIndex={onChangeAlphaIndex}
      // onChangeAnacrusis={onChangeAnacrusis}
      />
      <AccompStyleSection
        accompInstrumentValue1={accompInstrumentValue1}
        setAccompInstrumentValue1={setAccompInstrumentValue1}
        accompPatternValue1={accompPatternValue1}
        setAccompPatternValue1={setAccompPatternValue1}
        accompInstrumentValue2={accompInstrumentValue2}
        setAccompInstrumentValue2={setAccompInstrumentValue2}
        accompPatternValue2={accompPatternValue2}
        setAccompPatternValue2={setAccompPatternValue2}
        drumPatternValue={drumPatternValue}
        setDrumPatternValue={setDrumPatternValue}
      />
      <Box sx={{ marginTop: 7.5 }}>
        <Accordion sx={{ boxShadow: 'none' }}>
          <AccordionSummary sx={{ paddingLeft: 0, paddingRight: 0 }} expandIcon={<ExpandMoreIcon />}>
            <Box>
              <CVHeadline size="h3">好きなメロディーのサンプルを選ぶ</CVHeadline>
              <CVTypography size="c1" sx={{ color: 'base.secondary', marginLeft: 5, marginTop: 2.5 }}>
                生成したサンプルを組み合わせて好みのメロディーに編集できます。
              </CVTypography>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}>
            <Box
              sx={{
                backgroundColor: 'bg.secondary',
                paddingTop: 5.25,
                paddingBottom: 4,
                paddingLeft: 5,
                paddingRight: 5,
              }}
            >
              <CVTypography size="b1" sx={{ color: '#000000' }}>
                現在、Safari及びiOSからは簡易視聴することができません。PC版Chrome/FireFox/Edgeをお使い下さい。
              </CVTypography>
              <Box>
                <CVButton
                  onClick={onGenerateMelody}
                  size="s"
                  Icon={<NoteAddIcon sx={{ width: 16, marginLeft: 1 }} />}
                  text="生成"
                  disabled={isAuditionButtonClicked || isWaitingApiResult}
                  sx={{ width: 72, marginRight: 1.25 }}
                />
                <CVButton
                  onClick={onPlayMidi}
                  size="s"
                  Icon={<PlayCircleIcon sx={{ width: 16, marginLeft: 1 }} />}
                  text="視聴"
                  disabled={isWaitingApiResult || candidate.isEmpty() || isAuditionButtonClicked}
                  sx={{ width: 72, marginRight: 1.25 }}
                />
                <CVButton
                  onClick={onStopMidi}
                  size="s"
                  Icon={<StopCircleIcon sx={{ width: 16, marginLeft: 1 }} />}
                  text="停止"
                  disabled={isWaitingApiResult || !isAuditionButtonClicked}
                  sx={{ width: 72, marginRight: 1.25 }}
                />
                <CVButton
                  onClick={onClearMelodies}
                  size="s"
                  Icon={<DeleteIcon sx={{ width: 16, marginLeft: 1 }} />}
                  text="全削除"
                  buttonColor="base.error"
                  disabled={isWaitingApiResult || candidate.isEmpty() || isAuditionButtonClicked}
                  sx={{ width: 72 }}
                />
              </Box>
              <SelectMelodyHolderView playedNote={playedNote} candidate={candidate} />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: 17,
          marginBottom: 30,
          marginLeft: '15%',
          marginRight: '15%',
        }}
      >
        <CVButton
          text="戻る"
          onClick={onClickPrevious}
          size="l"
          buttonColor="primary.light"
          sx={{ marginTop: 2, marginX: 'auto' }}
        />
        <CVButton
          text="確定する"
          onClick={onClickDetermine}
          size="l"
          buttonColor="primary.main"
          disabled={
            isWaitingApiResult || (!candidate.isEmpty() && !candidate.isAllBarFixed()) || isAuditionButtonClicked
          }
          sx={{ marginTop: 2, marginX: 'auto' }}
        />
      </Box>
      <InputTitle
        open={isInputTitleModalOpen && !userState.isDialogOpen}
        setOpen={setInputTitleModalOpen}
        defaultTitle={defaultTitle}
        setTitle={setTitle}
        onSave={onSave}
      />
    </>
  );
};
