import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';

// スタイルの上書きがsx記法で実現できなかったため、styledを利用

export const CVStepConnector = styled(StepConnector)(({ theme }) => ({
  top: 83,
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 4,
    border: 2,
    backgroundColor: '#D9D9D9',
  },
}));
