import { Box, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CVTypography } from '../../components/ui-elements/CVTypography';
import { BarCount } from '../../components/ui-parts/BarCount';
import { CVButton } from '../../components/ui-parts/CVButton';
import { CVHeadline } from '../../components/ui-parts/CVHeadline';
import { CVStep } from '../../components/ui-parts/CVStep';
import { OldLoading } from '../../components/ui-parts/OldLoading';
import { LYRICS_SAMPLE } from '../../constants';
import { useNavigateBlocker } from '../../hooks/useNavigateBlocker';
import { usePromptWhenReload } from '../../hooks/usePromptWhenReload';
import { InputTitle } from '../../modals/InputTitle';
import { ValidationError } from '../../modals/ValidationError';
import { YomiHelp } from '../../modals/YomiHelp';
import {
  chordProgressionsSelector,
  compositionTypeSelector,
  isSavingProjectSelector,
  lyricsSelector,
  lyricsYomiSelector,
  nBarSelector,
  savedProjectSelector,
} from '../../selectors/projectSelector';
import { userSelector } from '../../selectors/userSelector';
import { projectSlice } from '../../slices/projectSlice';
import * as projectThunk from '../../slices/projectSlice';
import { AppDispatch } from '../../store';
import { validateLyricsYomi } from '../../utils/YomiValidator';
import { getHeadlineTextByCompType } from '../../utils/getHeadlineTextByCompType';
import { getNBarRange } from '../../utils/getNBarRange';

export const InputLyricsYomi = () => {
  usePromptWhenReload();
  const dispatch = useDispatch<AppDispatch>();
  const navigation = useNavigate();
  const determinedLyrics = useSelector(lyricsSelector);
  const lyricsYomi = useSelector(lyricsYomiSelector);
  const compositionType = useSelector(compositionTypeSelector);
  const chordProgressions = useSelector(chordProgressionsSelector);
  const nBar = useSelector(nBarSelector);
  const savedProject = useSelector(savedProjectSelector);
  const userState = useSelector(userSelector);
  const isSavingProject = useSelector(isSavingProjectSelector);

  const { minNBar, maxNBar } = getNBarRange(chordProgressions);
  const trimmedLyrics = determinedLyrics.trim();
  const defaultTitle = trimmedLyrics !== '' ? trimmedLyrics.split(/\s+/)[0] : LYRICS_SAMPLE[0];
  const [isInputTitleModalOpen, setInputTitleModalOpen] = useState(false);
  const [isValidationErrorModalOpen, setValidationErrorModalOpen] = useState(false);
  const [validationErrorMsg, setValidationErrorMsg] = useState('');
  const [title, setTitle] = useState(defaultTitle);
  const [isHelpModalOpen, setHelpModalOpen] = useState<boolean>(false);

  const onChangeLyrics = (e: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(projectSlice.actions.updateLyricsYomi(e.target.value));

  const onSaveTmpProjects = () => {
    void dispatch(
      projectThunk.onSaveTmpProjects({
        title,
        username: 'N/A',
        lyrics: determinedLyrics,
        lyricsYomi,
      })
    );
    setInputTitleModalOpen(false);
  };

  // `projectThunk.onSaveTmpProjects`をdispatchした段階では`savedProject`に値が返ってきてないので
  // `savedProject`が更新されたタイミングで遷移する
  useEffect(() => {
    if (!!savedProject.id && !!savedProject.key) {
      navigation(`/composition/select-favorite?gid=${savedProject.id}&project_key=${savedProject.key}`);
    }
  }, [navigation, savedProject]);

  const onClickPrevious = () => navigation('/composition/input-lyrics');
  const onClickNext = async () => {
    const validationRes = validateLyricsYomi(lyricsYomi, minNBar, maxNBar);
    if (validationRes.isOK) {
      setValidationErrorModalOpen(false);
      setValidationErrorMsg('');
      // 各種パラメータリストの取得
      await (async () => {
        await dispatch(projectThunk.onGetModelParameters());
        await dispatch(projectThunk.onGetAccompPatterns());
        await dispatch(projectThunk.onGetDrumPatterns());
      })();
      dispatch(projectSlice.actions.updateLyricsYomi(lyricsYomi));
      if (compositionType === 'designed') {
        navigation('/composition/design');
      } else if (compositionType === 'auto') {
        setInputTitleModalOpen(true);
      }
    } else {
      setValidationErrorModalOpen(true);
      setValidationErrorMsg(validationRes.message);
    }
  };

  useNavigateBlocker({
    allowPaths: [
      '/composition/input-lyrics',
      '/composition/design',
      `/composition/select-favorite?gid=${savedProject.id}&project_key=${savedProject.key}`,
    ],
  });

  // 仮プロジェクト保存を待つ間のローディング表示
  if (isSavingProject) {
    return <OldLoading />;
  }

  return (
    <>
      <Box>
        <CVHeadline size="h1">{getHeadlineTextByCompType(compositionType)}</CVHeadline>
      </Box>
      <CVStep activeStep={1} />
      <Box sx={{ backgroundColor: 'base.tertiary', padding: 2, marginTop: 11 }}>
        {determinedLyrics.split(/\n/).map((value) => (
          <CVTypography size="b2">{value}</CVTypography>
        ))}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'start',
          marginTop: 9.25,
        }}
      >
        <CVHeadline size="h2">読みがなを確認・編集する</CVHeadline>
        <Box onClick={() => setHelpModalOpen(true)}>
          <CVTypography size="c1" sx={{ color: 'primary.main', marginLeft: 2.5, cursor: 'pointer' }}>
            操作のヒント
          </CVTypography>
        </Box>
      </Box>
      <Box
        sx={{
          marginLeft: { md: 2.5, xs: 0 },
          marginLight: { md: 2.5, xs: 0 },
          marginTop: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'start',
            marginTop: 2,
          }}
        >
          <BarCount count={nBar} />
          <CVTypography size="c1" sx={{ color: 'base.secondary' }}>
            （{minNBar}小節〜{maxNBar}小節まで）
          </CVTypography>
        </Box>
        <Box sx={{ marginTop: 2.5 }}>
          <TextField
            rows={5}
            multiline
            value={lyricsYomi}
            onChange={onChangeLyrics}
            sx={{ width: '100%' }}
            variant="outlined"
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: 17,
          marginBottom: 30,
          marginLeft: '15%',
          marginRight: '15%',
        }}
      >
        <CVButton
          text="戻る"
          onClick={onClickPrevious}
          size="l"
          buttonColor="primary.light"
          sx={{ marginTop: 2, marginX: 'auto' }}
        />
        <CVButton
          text="次へ"
          onClick={onClickNext}
          size="l"
          buttonColor="primary.main"
          sx={{ marginTop: 2, marginX: 'auto' }}
        />
      </Box>
      {/* 以下は仮プロジェクトのみに関係する */}
      <InputTitle
        open={isInputTitleModalOpen && !userState.isDialogOpen}
        setOpen={setInputTitleModalOpen}
        defaultTitle={defaultTitle}
        setTitle={setTitle}
        onSave={onSaveTmpProjects}
      />
      <ValidationError
        open={isValidationErrorModalOpen}
        setOpen={setValidationErrorModalOpen}
        errorMsg={validationErrorMsg}
      />
      <YomiHelp open={isHelpModalOpen} setOpen={setHelpModalOpen} />
    </>
  );
};
