import { RootState } from '../store';

export const userSelector = (state: RootState) => state.user;

export const userInfoSelector = (state: RootState) => state.user.userInfo;

export const userIsLoggedInSelector = (state: RootState) => state.user.isLoggedIn;

export const userIdTokenSelector = (state: RootState) => state.user.userInfo.idToken;

export const userErrorSelector = (state: RootState) => state.user.errorMessage;
