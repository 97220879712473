import { TmpProjects } from '../models';
import { UserInfo } from '../slices/userSlice';

import getCreevoClient from './CreevoClient';

export interface TmpProjectResponse {
  tmpProject: TmpProjects;
  audioPaths?: Array<string>;
  isValidKey: boolean;
  status: 'generating' | 'available' | 'archived';
}

export interface PatchTmpProjectResponse {
  isSuccessful: boolean;
  projectId: number;
  projectKey: string;
}

export interface TmpProjectPostResponse {
  isSuccessful: boolean,
  tmpProjectGroupId: number,
  tmpProjectGroupKey: string,
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SaveTmpProjectResponse extends TmpProjectPostResponse { }

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RecreateTmpProjectResponse extends TmpProjectPostResponse { }

export const saveTmpProjects = async (title: string, username: string, lyrics: string, lyricsYomi: string) => {
  const client = await getCreevoClient();
  return client.post<SaveTmpProjectResponse>('/tmp-projects/', {
    title,
    username,
    lyrics,
    lyricsYomi,
  });
};

export const getTmpProject = async (tmpProjectGroupId: number, tmpProjectGroupKey: string) => {
  const client = await getCreevoClient();
  return client.get<TmpProjectResponse>(`/tmp-projects/${tmpProjectGroupId}/?tmpProjectGroupKey=${tmpProjectGroupKey}`);
};

export const selectTmpProjects = async (
  tmpProjectGroupId: number,
  tmpProjectGroupKey: string,
  selected: number,
  userInfo: UserInfo
) => {
  const client = await getCreevoClient();
  return client.patch<PatchTmpProjectResponse>(
    `/tmp-projects/${tmpProjectGroupId}/`,
    {
      tmpProjectGroupKey,
      selected,
    },
    userInfo.idToken !== '' ? { headers: { 'X-Authorization': `Bearer ${userInfo.idToken}` } } : undefined
  );
};

export const recreateTmpProjects = async (
  tmpProjectGroupId: number,
  tmpProjectGroupKey: string
) => {
  const client = await getCreevoClient();
  return client.post<RecreateTmpProjectResponse>(
    '/recreated-tmp-projects/',
    { tmpProjectGroupId, tmpProjectGroupKey }
  );
};
