import ListAltIcon from '@mui/icons-material/ListAlt';
import LogoutIcon from '@mui/icons-material/Logout';
import { Divider, ListItemText, Menu, MenuItem } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';

interface Props {
  onLogOut: () => void;
  onNavigate: () => void;
  iconMenuAnchorEl: HTMLElement | null;
  setIconMenuAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}

export const IconMenu = (props: Props) => {
  const open = Boolean(props.iconMenuAnchorEl);

  const handleClose = () => {
    props.setIconMenuAnchorEl(null);
  };

  const onClickLogOut = () => {
    props.onLogOut();
    handleClose();
  };

  const onClickUserProjects = () => {
    props.onNavigate();
    handleClose();
  };

  return (
    <Menu
      id="basic-menu"
      anchorEl={props.iconMenuAnchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem onClick={onClickUserProjects}>
        <ListItemIcon>
          <ListAltIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>自分の作品一覧</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem onClick={onClickLogOut}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>ログアウト</ListItemText>
      </MenuItem>
    </Menu>
  );
};
