import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { Layout } from './components/layouts/Layout';
import { Contact } from './pages/Contact';
import { Error } from './pages/Error';
import { Home } from './pages/Home';
import { TermsAndConditions } from './pages/TermsAndConditions';
import { About } from './pages/about/About';
import { AuthProvider } from './pages/auth/AuthProvider';
import { Login } from './pages/auth/Login';
import { Completed } from './pages/composition/Completed';
import { Design } from './pages/composition/Design';
import { InputLyrics } from './pages/composition/InputLyrics';
import { InputLyricsYomi } from './pages/composition/InputLyricsYomi';
import { SelectFavorite } from './pages/composition/SelectFavorite';
import { SelectType } from './pages/composition/SelectType';
import { Project } from './pages/projects/Project';
import { ProjectList } from './pages/projects/ProjectList';
import { Usage } from './pages/usage/Usage';
import { UserProject } from './pages/user-projects/UserProject';
import { UserProjectList } from './pages/user-projects/UserProjectList';
import { store } from './store';
import { theme } from './styles/theme';

import './App.css';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      { path: '', element: <Home /> },
      { path: '/about/', element: <About /> },
      { path: '/contact/', element: <Contact /> },
      { path: '/usage/', element: <Usage /> },
      { path: '/projects/', element: <ProjectList /> },
      { path: '/projects/:pid', element: <Project /> },
      { path: '/user-projects/', element: <UserProjectList /> },
      { path: '/user-projects/:pid', element: <UserProject /> },
      { path: '/auth/login/', element: <Login /> },
      { path: '/terms-and-conditions/', element: <TermsAndConditions /> },
      { path: '/composition/select-type/', element: <SelectType /> },
      { path: '/composition/input-lyrics/', element: <InputLyrics /> },
      { path: '/composition/input-lyrics-yomi/', element: <InputLyricsYomi /> },
      { path: '/composition/design', element: <Design /> },
      { path: '/composition/select-favorite', element: <SelectFavorite /> },
      { path: '/composition/completed', element: <Completed /> },
      {
        path: '*',
        element: <Error errorMsg="指定されたページが見つかりませんでした" errorCode={404} />,
      },
    ],
  },
]);

const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </ThemeProvider>
  </Provider>
);

export default App;
