import { Box, Button, Modal, Typography } from '@mui/material';
import * as React from 'react';

import { CVTypography } from '../components/ui-elements/CVTypography';
import LyricsYomiHintImgOld from '../images/lyrics_yomi_hint_old.gif';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const YomiHelp = (props: Props) => (
  <Modal open={props.open} onClose={() => props.setOpen(false)}>
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: { xs: '10%', md: '50%' },
        transform: {
          xs: 'translate(-5%, -50%)',
          md: 'translate(-50%, -50%)',
        },
        backgroundColor: 'bg.primary',
        padding: 4,
        borderRadius: 4,
        maxWidth: 600,
        maxHeight: '80vh',
        overflowY: 'scroll',
      }}
    >
      <CVTypography size="h">歌詞の読みがなを設定する</CVTypography>
      <Typography component="p" sx={{ marginY: 2 }}>
        歌詞の読みがなを
        <Typography sx={{ fontWeight: 600 }} component="span">
          ひらがなで
        </Typography>
        入力してください。
        <br />
        スペースが小節区切りに対応し、区切りを手動で変更することもできます。
        <br />
        英語やかな変換、小節区切りの誤りは、手動で修正してください。
        <br />
        読みがなが決まったら、「次へ」を押してください。
        <br />
      </Typography>
      <Box
        component="img"
        src={LyricsYomiHintImgOld}
        width={1810}
        height={540}
        sx={{
          maxWidth: '100%',
          height: 'auto',
        }}
      />
      <Typography component="p">上記の例では、自動生成された小節区切りを修正しています。</Typography>
      <Typography component="p" sx={{ marginY: 2 }}>
        基本として、平仮名1文字が1音符に対応します。
        <br />
        カッコを使えば、複数文字を1音符にまとめられます。
        <br />
        1小節には16音符までの歌詞を入れられます。
        <br />
        記号"ー"は音の延長、"・"は休符を表すものとして使えます。これらは自動かな変換によって付くことがあります。
        <br />
        記号"^"と"_"は、アクセントの上下を表します。メロディー生成の際にメロディー音高の上下に反映されます。
        <br />
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button onClick={() => props.setOpen(false)}>閉じる</Button>
      </Box>
    </Box>
  </Modal>
);
