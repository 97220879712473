import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';
import { Box, Grid, Button, IconButton } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Candidate, Sample, Chord, Note } from '../../../models';
import { projectSlice } from '../../../slices/projectSlice';
import { assertIsDefined } from '../../../utils/Assert';
import { CVTypography } from '../../ui-elements/CVTypography';

import { MelodyType, MelodyView } from './MelodyView';

interface SelectMelodyHolderProps {
  candidate: Candidate;
  playedNote: number;
}

interface SelectedMelodyProps {
  melody: Note[][][];
  chordSequence: Chord[][][];
  playedNote: number;
  mousePhraseIndex: number;
  mouseBarIndex: number;
  showMelody: boolean;
}

interface CandidateMelodyProps {
  candidate: Candidate;
  chordSequence: Chord[][][];
  playedNote: number;
  onMouseEnterBar: (phraseIndex: number, barIndex: number) => void;
  onMouseLeaveBar: () => void;
}

interface CandidateProps {
  candidate: Candidate;
  sample: Sample;
  sampleIndex: number;
  chordSequence: Chord[][][];
  playedNote: number;
  onMouseEnterBar: (phraseIndex: number, barIndex: number) => void;
  onMouseLeaveBar: () => void;
}

const SelectedMelodyView = (props: SelectedMelodyProps) => {
  const SelectedMelodyViewWithAssert = (props: SelectedMelodyProps) => {
    assertIsDefined(props.chordSequence);
    const { chordSequence, ...newProps } = props;
    return <MelodyView melodyType={MelodyType.Main} chordSequence={chordSequence} {...newProps} />;
  };
  return (
    <>
      <CVTypography size="b1" sx={{ marginBottom: 1.5 }}>
        選択メロディー
      </CVTypography>
      <Box
        sx={{
          height: 700,
          backgroundColor: 'bg.form',
          overflow: 'scroll',
          width: { sm: 640, xs: '100%' },
        }}
      >
        {props.showMelody && <SelectedMelodyViewWithAssert {...props} />}
      </Box>
    </>
  );
};

const CandidateView = (props: CandidateProps) => {
  const dispatch = useDispatch();
  const UnselectAllButton = () => (
    <Button
      variant="outlined"
      sx={{ width: 80 }}
      onClick={() =>
        dispatch(
          projectSlice.actions.setAllBarIsFixed({
            sampleIndex: props.sampleIndex,
            isFixed: false,
          })
        )
      }
    >
      全解除
    </Button>
  );
  const SelectAllButton = () => (
    <Button
      variant="outlined"
      sx={{ width: 80 }}
      onClick={() =>
        dispatch(
          projectSlice.actions.setAllBarIsFixed({
            sampleIndex: props.sampleIndex,
            isFixed: true,
          })
        )
      }
    >
      全選択
    </Button>
  );
  return (
    <Grid
      container
      sx={{
        backgroundColor: 'none',
        flexWrap: 'nowrap',
        width: 'min-content',
      }}
    >
      <Grid
        item
        sx={{
          marginTop: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0.625,
        }}
      >
        {props.candidate.isAnyBarFixed(props.sampleIndex) ? <UnselectAllButton /> : <SelectAllButton />}
      </Grid>
      <Grid item>
        <MelodyView
          key={props.sampleIndex}
          melody={props.sample.melodies}
          chordSequence={props.chordSequence}
          playedNote={props.playedNote}
          barsList={props.sample.bars}
          melodyType={MelodyType.Candidate}
          onClickBar={(phraseIndex: number, barIndex: number) =>
            dispatch(
              projectSlice.actions.toggleBarIsFixed({
                sampleIndex: props.sampleIndex,
                phraseIndex,
                barIndex,
              })
            )
          }
          onMouseEnterBar={props.onMouseEnterBar}
          onMouseLeaveBar={props.onMouseLeaveBar}
        />
      </Grid>
      <Grid
        item
        sx={{
          marginTop: 0,
          marginBottom: 0,
          marginLeft: 0.625,
          marginRight: 0,
        }}
      >
        <IconButton
          onClick={() =>
            dispatch(
              projectSlice.actions.removeSample({
                sampleIndex: props.sampleIndex,
              })
            )
          }
        >
          <BackspaceOutlinedIcon color="error" />
        </IconButton>
      </Grid>
    </Grid>
  );
};

const CandidateMelodyView = (props: CandidateMelodyProps) => (
  <>
    <CVTypography size="b1" sx={{ marginBottom: 1.5 }}>
      生成サンプル
    </CVTypography>
    <Box
      sx={{
        backgroundColor: 'bg.form',
        display: 'inline-block',
        overflow: 'scroll',
        height: 250,
        width: { sm: 780, xs: '100%' },
      }}
    >
      {props.candidate.samples.map((sample, index) => {
        assertIsDefined(props.chordSequence);
        const { chordSequence, ...newProps } = props;
        return (
          <CandidateView
            key={sample.sampleId}
            sample={sample}
            sampleIndex={index}
            chordSequence={chordSequence}
            {...newProps}
          />
        );
      })}
    </Box>
  </>
);

export const SelectMelodyHolderView = (props: SelectMelodyHolderProps) => {
  const [mousePhraseIndex, setMousePhraseIndex] = useState<number>(-1);
  const [mouseBarIndex, setMouseBarIndex] = useState<number>(-1);

  const onMouseEnterCandidateBar = (phraseIndex: number, barIndex: number) => {
    setMousePhraseIndex(phraseIndex);
    setMouseBarIndex(barIndex);
  };
  const onMouseLeaveCandidateBar = () => {
    setMousePhraseIndex(-1);
    setMouseBarIndex(-1);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm="auto">
        <CandidateMelodyView
          {...props}
          chordSequence={props.candidate.chordSequence}
          onMouseEnterBar={onMouseEnterCandidateBar}
          onMouseLeaveBar={onMouseLeaveCandidateBar}
        />
      </Grid>
      <Grid item xs={12} sm="auto">
        <SelectedMelodyView
          melody={props.candidate.getMelody()}
          chordSequence={props.candidate.chordSequence}
          playedNote={props.playedNote}
          mousePhraseIndex={mousePhraseIndex}
          mouseBarIndex={mouseBarIndex}
          showMelody={!props.candidate.isEmpty()}
        />
      </Grid>
    </Grid>
  );
};
