import { Box } from '@mui/material';
import * as React from 'react';
import { Bars } from 'react-loader-spinner';

export const OldLoading: React.FC = React.memo(() => (
  <Box p={3}>
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Bars color="#008B3A" height={150} width={150} />
    </Box>
  </Box>
));
