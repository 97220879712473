import { CompositionType } from '../models';

export const getHeadlineTextByCompType = (type: CompositionType) => {
  switch (type) {
    case 'auto': {
      return 'おまかせで作曲する';
    }
    case 'designed': {
      return 'デザインして作曲する';
    }
    default: {
      return '作曲する';
    }
  }
};
