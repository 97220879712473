import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    base: {
      primary: string;
      secondary: string;
      disabled: string;
    };
    bg: {
      primary: string;
      secondary: string;
      disabled: string;
    };
  }
  interface PaletteOptions {
    base?: {
      primary?: string;
      secondary?: string;
      tertiary?: string;
      disabled?: string;
      error?: string;
      footer?: string;
    };
    bg?: {
      primary?: string;
      secondary?: string;
      disabled?: string;
      form?: string;
    };
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#008B3A',
      light: '#D0FDE2',
    },
    secondary: {
      main: '#FFD646',
      light: '#FDF4D5',
    },
    base: {
      primary: '#3A3E42',
      secondary: '#747C84',
      tertiary: '#F0F6FB',
      disabled: '#CAD2DA',
      error: '#EC4545',
      footer: 'rgb(0, 139, 58, 0.1)',
    },
    bg: {
      primary: '#FFFFFB',
      secondary: '#F4FBF4',
      disabled: '#AFAFAF',
      form: '#FCFCFC',
    },
  },
  typography: {
    fontFamily: ['"M PLUS Rounded 1c"', '"Helvetica"', 'Arial', 'sans-serif'].join(','),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 420,
      md: 950,
      lg: 1440,
      xl: 1536,
    },
  },
});
