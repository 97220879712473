import { Stepper, Step, StepLabel } from '@mui/material';
import { useSelector } from 'react-redux';

import { compositionTypeSelector } from '../../selectors/projectSelector';
import { getCVStepTextByCompType } from '../../utils/getCVStepTextByCompType';
import { CVStepConnector } from '../ui-elements/CVStepConnector';
import { CVStepIcon } from '../ui-elements/CVStepIcon';
import { CVTypography } from '../ui-elements/CVTypography';

interface Props {
  activeStep: 0 | 1 | 2 | 3;
}

export const CVStep = (props: Props) => {
  const compositionType = useSelector(compositionTypeSelector);
  const label = ['歌詞入力', '読みがな確認', getCVStepTextByCompType(compositionType), '完了'];
  return (
    <Stepper alternativeLabel activeStep={props.activeStep} connector={<CVStepConnector />}>
      {label.map((value) => (
        <Step key={value}>
          <StepLabel StepIconComponent={CVStepIcon}>
            <CVTypography size="c2">{value}</CVTypography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
