import abcjs from 'abcjs';

import { BACK_MIDI_NUMBERS, VOLUME_MULTIPLIER } from '../constants';

const mySynth = new abcjs.synth.CreateSynth();
let timingCallbacks: abcjs.TimingCallbacks | null = null;

export const playMidi = async (
  abcString: string,
  onInitialize?: () => void,
  onPlayingEvent?: () => void,
  onStop?: () => void
) => {
  const visualObj = abcjs.renderAbc('*', abcString)[0];
  await initializeMidi(visualObj, onInitialize, onPlayingEvent, onStop);
  timingCallbacks?.start();
  mySynth.start();
};

const initializeMidi = async (
  visualObj: abcjs.TuneObject,
  onInitialize?: () => void,
  onPlayingEvent?: (e: abcjs.TimingEvent) => void,
  onStop?: () => void
) => {
  const myContext = new AudioContext();
  const sequenceCallback = (sequences: Array<abcjs.NoteMapTrack>) =>
    sequences.map((sequence) =>
      sequence.map((note) => {
        const newNote = { ...note };
        newNote.volume *= VOLUME_MULTIPLIER;
        return newNote;
      })
    );
  const eventCallback = (e: abcjs.TimingEvent) => {
    // if playing midi finished
    if (e === null) {
      stopMidi(onStop);
      return;
    }
    // if the event is not a melody note event, then do nothing
    if (e.midiPitches.length === 0 || BACK_MIDI_NUMBERS.includes(parseInt(e.midiPitches[0].instrument, 10))) {
      return;
    }

    onPlayingEvent?.(e);
  };

  await mySynth.init({
    audioContext: myContext,
    visualObj,
    options: {
      sequenceCallback,
    },
  });
  await mySynth.prime();
  timingCallbacks = new abcjs.TimingCallbacks(visualObj, {
    eventCallback,
  });
  onInitialize?.();
};

export const stopMidi = (onStop?: () => void) => {
  timingCallbacks?.stop();
  mySynth.stop();
  onStop?.();
};
