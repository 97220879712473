import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';

import { INSTRUMENT_ITEMS } from '../../../constants';
import { modelParametersSelector } from '../../../selectors/projectSelector';
import { CVTypography } from '../../ui-elements/CVTypography';
import { CVHeadline } from '../CVHeadline';

import { BpmSlider } from './BpmSlider';
import { AlphaSlider } from './melody-generation-configs/AlphaSlider';
import { BetaSlider } from './melody-generation-configs/BetaSlider';
import { PitchSlider } from './melody-generation-configs/PitchSlider';

interface Props {
  modelParameterId: number;
  setModelParameterId: (newState: number) => void;
  setBpm: (newState: number) => void;
  instrumentValue: number;
  setInstrumentValue: (newState: number) => void;
  pitchRange: { minPitch: number; maxPitch: number };
  betaIndex: number;
  alphaIndex: number;
  // anacrusis: number;
  onChangePitchRange: (minPitch: number, maxPitch: number) => void;
  onChangeBetaIndex: (index: number) => void;
  onChangeAlphaIndex: (index: number) => void;
  // onChangeAnacrusis: (anacrusis: number) => void;
}

export const MelodyStyleSection = (props: Props) => {
  const modelParameters = useSelector(modelParametersSelector);
  const onChangeModelParameter = (e: SelectChangeEvent<number>) => {
    props.setModelParameterId(e.target.value as number);
  };
  const onChangeInstrument = (e: SelectChangeEvent<number>) => {
    props.setInstrumentValue(e.target.value as number);
  };

  return (
    <Box sx={{ marginTop: 8 }}>
      <CVHeadline size="h3">メロディーのスタイル</CVHeadline>
      <CVTypography size="c1" sx={{ color: 'base.secondary', marginTop: 2.75 }}>
        メロディーの雰囲気が決められます。
      </CVTypography>
      <Box sx={{ backgroundColor: 'bg.secondary', marginTop: 3 }}>
        <Box
          sx={{
            paddingTop: 4,
            paddingLeft: { xs: 2, md: 5 },
            paddingRight: { xs: 2, md: 5 },
            paddingBottom: 2.5,
          }}
        >
          <Select
            value={props.modelParameterId}
            onChange={onChangeModelParameter}
            sx={{
              backgroundColor: 'bg.form',
              marginBottom: 2.5,
              width: { md: '50%', xs: '100%' },
            }}
            native={isMobile}
          >
            {modelParameters.map((modelParameter) =>
              isMobile ? (
                <option key={modelParameter.id} value={modelParameter.id}>
                  {modelParameter.name}
                </option>
              ) : (
                <MenuItem key={modelParameter.id} value={modelParameter.id}>
                  {modelParameter.name}
                </MenuItem>
              )
            )}
          </Select>
          <Box>
            <CVTypography size="c1" sx={{ color: 'base.secondary', marginTop: 3 }}>
              テンポBPM
            </CVTypography>
            <BpmSlider onChange={(newBpm: number) => props.setBpm(newBpm)} />
          </Box>
          <CVTypography size="c1" sx={{ color: 'base.secondary', marginBottom: 2 }}>
            メロディー楽器
          </CVTypography>
          <Select
            value={props.instrumentValue}
            onChange={onChangeInstrument}
            sx={{
              backgroundColor: 'bg.form',
              width: { md: '50%', xs: '100%' },
            }}
            native={isMobile}
          >
            {INSTRUMENT_ITEMS.map((instrument) =>
              isMobile ? (
                <option key={instrument.value} value={instrument.value}>
                  {instrument.label}
                </option>
              ) : (
                <MenuItem key={instrument.value} value={instrument.value}>
                  {instrument.label}
                </MenuItem>
              )
            )}
          </Select>
          <Accordion sx={{ marginTop: 1.5, boxShadow: 'none' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <CVTypography size="b2">メロディー生成の設定</CVTypography>
            </AccordionSummary>
            <AccordionDetails>
              <PitchSlider
                maxPitch={props.pitchRange.maxPitch}
                minPitch={props.pitchRange.minPitch}
                onChange={props.onChangePitchRange}
              />
              <BetaSlider betaIndex={props.betaIndex} onChange={props.onChangeBetaIndex} />
              <AlphaSlider alphaIndex={props.alphaIndex} onChange={props.onChangeAlphaIndex} />
              {/* 当面実装予定のない設定項目のため、コメントアウト */}
              {/* <AnacrusisSlider */}
              {/*  anacrusis={props.anacrusis} */}
              {/*  onChange={props.onChangeAnacrusis} */}
              {/* /> */}
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </Box>
  );
};
