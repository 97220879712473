import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getNews } from '../api/FirebaseRemoteConfigApi';

import type { News } from '../api/FirebaseRemoteConfigApi';

type MaintenanceState = {
  isNewsLoading: boolean;
  news: Array<News>;
};

const initialState: MaintenanceState = {
  isNewsLoading: false,
  news: [
    {
      date: '2022-12-20',
      message: 'CREEVOの利用者数と作曲数がどちらも10万を超えました。',
    },
  ],
};

export const onFetchNews = createAsyncThunk('news/onFetchNews', async () => {
  const news = await getNews();
  return { news };
});

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(onFetchNews.pending, (state) => ({
      ...state,
      isNewsLoading: true,
    }));
    builder.addCase(onFetchNews.fulfilled, (state, action) => ({
      ...state,
      isNewsLoading: false,
      news: action.payload.news,
    }));
    builder.addCase(onFetchNews.rejected, (state) => ({
      ...state,
      isNewsLoading: false,
    }));
  },
});

export const newsReducer = newsSlice.reducer;
