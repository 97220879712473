import { Box, Theme, Typography } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { ReactNode } from 'react';

type TypographySize = 't1' | 't2' | 't3' | 'h' | 'b1' | 'b2' | 'c1' | 'c2' | 'c3' | 'o1' | 'o2';

interface Props {
  size: TypographySize;
  children: ReactNode;
  sx?: SystemStyleObject<Theme>;
  addDot?: boolean;
}

type TypographyStyleObj = {
  fontSize: { xs: number; md: number };
  fontWeight: 400 | 500;
  lineHeight: number;
};

type TypographyStyle = {
  t1: TypographyStyleObj;
  t2: TypographyStyleObj;
  t3: TypographyStyleObj;
  h: TypographyStyleObj;
  b1: TypographyStyleObj;
  b2: TypographyStyleObj;
  c1: TypographyStyleObj;
  c2: TypographyStyleObj;
  c3: TypographyStyleObj;
  o1: TypographyStyleObj;
  o2: TypographyStyleObj;
};

export const CVTypography = (props: Props) => {
  const typographyStyle: TypographyStyle = {
    t1: {
      fontSize: { xs: 36, md: 36 },
      fontWeight: 500,
      lineHeight: 1.25,
    },
    t2: {
      fontSize: { xs: 22, md: 28 },
      fontWeight: 500,
      lineHeight: 1.25,
    },
    t3: {
      fontSize: { xs: 18, md: 24 },
      fontWeight: 400,
      lineHeight: 1.25,
    },
    h: {
      fontSize: { xs: 20, md: 20 },
      fontWeight: 500,
      lineHeight: 1.1,
    },
    b1: {
      fontSize: { xs: 14, md: 20 },
      fontWeight: 400,
      lineHeight: 2,
    },
    b2: {
      fontSize: { xs: 16, md: 16 },
      fontWeight: 500,
      lineHeight: 2,
    },
    c1: {
      fontSize: { xs: 14, md: 16 },
      fontWeight: 500,
      lineHeight: 1.75,
    },
    c2: {
      fontSize: { xs: 14, md: 14 },
      fontWeight: 400,
      lineHeight: 1.71,
    },
    c3: {
      fontSize: { xs: 12, md: 12 },
      fontWeight: 500,
      lineHeight: 1.08,
    },
    o1: {
      fontSize: { xs: 10, md: 10 },
      fontWeight: 500,
      lineHeight: 1.6,
    },
    o2: {
      fontSize: { xs: 10, md: 10 },
      fontWeight: 400,
      lineHeight: 1.6,
    },
  };
  return props.addDot ? (
    <Box
      sx={[
        {
          display: 'flex',
          alignItems: 'center',
        },
        props.sx !== undefined && props.sx,
      ]}
    >
      <Box
        sx={{
          width: 10,
          height: 10,
          borderRadius: '50%',
          backgroundColor: 'primary.main',
          flexShrink: 0,
        }}
      />
      <Typography
        sx={{
          fontSize: typographyStyle[props.size].fontSize,
          fontWeight: typographyStyle[props.size].fontWeight,
          lineHeight: typographyStyle[props.size].lineHeight,
          marginLeft: 2,
        }}
      >
        {props.children}
      </Typography>
    </Box>
  ) : (
    <Typography
      sx={[
        {
          fontSize: typographyStyle[props.size].fontSize,
          fontWeight: typographyStyle[props.size].fontWeight,
          lineHeight: typographyStyle[props.size].lineHeight,
        },
        props.sx !== undefined && props.sx,
      ]}
    >
      {props.children}
    </Typography>
  );
};
