import { Slider, Box } from '@mui/material';

import { DEFAULT_DESIGN_CONFIG_VALUE, DESIGN_CONFIG } from '../../../../constants';
import { CVTypography } from '../../../ui-elements/CVTypography';

/*
  和音の当てはまり度（configではalpha）
    範囲：1~10 、整数
    デフォルト値：5
    （できれば）変更時の説明：吹き出しで（「メロディーの和音への当てはまり度を変更できます。1：和音への当てはまりを無視、10：和音への当てはまりを最も強く」）
    対応：1から順に：alpha = 0 , 0.5 , 1.0 , 1.3 , 1.5 (default) , 1.7 , 1.9 , 2.1 , 2.3 , 2.5
*/

interface Props {
  alphaIndex: number;
  onChange: (newAlphaIndex: number) => void;
}

export const AlphaSlider = (props: Props) => {
  const L: number = DESIGN_CONFIG.alpha.length;
  const marks = [];
  const onChange = (_: Event, index: number | number[]) => {
    if (typeof index === 'number') {
      props.onChange(index);
    }
  };
  for (let i = 0; i < L; i++) {
    const mark = (i + 1).toString();
    const type = i === 0 ? '最弱' : i === L - 1 ? '最強' : '';
    marks.push({
      value: i,
      label: (
        <Box style={{ textAlign: 'center' }}>
          {mark}
          <br />
          {type}
        </Box>
      ),
    });
  }

  return (
    <Box sx={{ marginLeft: 4, marginRight: 4, marginTop: 6 }}>
      <CVTypography size="c1" sx={{ color: 'base.secondary' }}>
        和音の当てはまり度
      </CVTypography>
      <Slider
        min={0}
        max={DESIGN_CONFIG.alpha.length - 1}
        onChange={onChange}
        marks={marks}
        value={props.alphaIndex}
        defaultValue={DEFAULT_DESIGN_CONFIG_VALUE.alphaIndex}
        color="primary"
      />
    </Box>
  );
};
