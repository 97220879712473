import { Box } from '@mui/material';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CVTypography } from '../../components/ui-elements/CVTypography';
import { CVHeadline } from '../../components/ui-parts/CVHeadline';
import { SignIn } from '../../components/ui-parts/SignIn';
import { userSelector } from '../../selectors/userSelector';

export const Login = () => {
  const [shouldRedirect, setRedirect] = useState(false);
  const userState = useSelector(userSelector);
  const navigation = useNavigate();
  useEffect(() => {
    if (userState.isLoggedIn) {
      setRedirect(true);
    }
  }, [userState]);

  if (shouldRedirect) {
    // TODO: 遷移元に転送する
    navigation('/');
  }

  return (
    <>
      <Box sx={{ marginTop: 4.25 }}>
        <CVHeadline size="h1">ログイン</CVHeadline>
      </Box>
      <CVTypography size="c1">ログインすることで、自分が作曲した曲をいつでも聴くことができます。</CVTypography>
      <SignIn />
      <CVTypography size="c1" sx={{ color: 'gray' }}>
        CREEVOではFirebase
        Authenticationをログインサービスとして利用しており、一切のユーザー情報をサーバー上に保管していません。
      </CVTypography>
    </>
  );
};
