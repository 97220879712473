import { Button, Theme, Box } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { ReactElement } from 'react';

import { CVTypography } from '../ui-elements/CVTypography';

interface Props {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  buttonColor?: 'primary.main' | 'primary.light' | 'base.disabled' | 'base.error';
  Icon: ReactElement;
  sx?: SystemStyleObject<Theme>;
}

export const CVIconButton = (props: Props) => {
  let fontColor: string;
  switch (props.buttonColor) {
    case 'primary.main': {
      fontColor = '#FFFFFF';
      break;
    }
    case 'primary.light': {
      fontColor = 'primary.main';
      break;
    }
    case 'base.disabled': {
      fontColor = 'base.secondary';
      break;
    }
    default: {
      fontColor = '#FFFFFF';
      break;
    }
  }
  return (
    <Button
      variant="contained"
      onClick={props.onClick}
      disabled={props.disabled}
      sx={[
        {
          backgroundColor: props.buttonColor !== undefined ? props.buttonColor : 'primary.main',
          borderRadius: 9999,
          paddingTop: 0.5,
          paddingLeft: 2,
          paddingRight: 2,
          paddingBottom: 0.5,
          '&:hover': {
            backgroundColor: props.buttonColor !== undefined ? props.buttonColor : 'primary.main',
          },
        },
        props.sx !== undefined && props.sx,
      ]}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {props.Icon}
        <CVTypography
          size="b2"
          sx={{
            color: fontColor,
            marginLeft: 1,
          }}
        >
          {props.text}
        </CVTypography>
      </Box>
    </Button>
  );
};
