export function range($1: number): Generator<number>;
export function* range($1: number, $2?: number): Generator<number> {
  if ($2 === undefined) {
    for (let i = 0; i < $1; i++) {
      yield i;
    }
  } else {
    for (let i = $1; i < $2; i++) {
      yield i;
    }
  }
}
