import { Box } from '@mui/material';
import { StepIconProps } from '@mui/material/StepIcon';

import flag from '../../images/flag.svg';

import { CVTypography } from './CVTypography';

export const CVStepIcon = (props: StepIconProps) => (
  <Box sx={{ width: 40 }}>
    {props.icon === 4 ? (
      <img src={flag} alt="" style={{ width: 35, marginLeft: 11 }} />
    ) : (
      <Box sx={{ height: 51.25, width: 40 }} />
    )}
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 40,
        height: 40,
        marginTop: props.icon === 4 ? 2.719 : 1.625,
        marginRight: 0,
        borderRadius: 20,
        backgroundColor: props.active || props.completed ? 'primary.main' : 'base.disabled',
        color: props.active || props.completed ? 'primary.light' : 'base.secondary',
      }}
    >
      <CVTypography size="h">{props.icon}</CVTypography>
    </Box>
  </Box>
);
