import { Bar } from './Bar';
import { Note } from './Note';

export class Sample {
  readonly melodies: Note[][][];

  readonly bars: Bar[][];

  readonly configId: number;

  readonly sampleId: number;

  constructor(melodies: Note[][][], configId: number, sampleId: number) {
    this.melodies = melodies;
    this.bars = melodies.map((melody) =>
      melody.map(() => ({
        isFixed: false,
      }))
    );
    this.configId = configId;
    this.sampleId = sampleId;
  }

  toggleBarIsFixed = (phraseIndex: number, barIndex: number) => {
    this.bars[phraseIndex][barIndex].isFixed = !this.bars[phraseIndex][barIndex].isFixed;
    return this.bars[phraseIndex][barIndex].isFixed;
  };
}
