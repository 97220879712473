import { RootState } from '../store';

export const rankingIsProjectLoadingSelector = (state: RootState) => state.ranking.isProjectLoading;

export const rankingReferencesSelector = (state: RootState) => state.ranking.references;

export const rankingIndexSelector = (state: RootState) => state.ranking.referenceIndex;

export const rankingCurrentReferenceSelector = (state: RootState) =>
  state.ranking.references[state.ranking.referenceIndex];

export const rankingProjectDetailSelector = (state: RootState) => state.ranking.projectDetail;

export const rankingErrorSelector = (state: RootState) => state.ranking.errorMessage;
