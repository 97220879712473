import { CVTypography } from '../ui-elements/CVTypography';

interface Props {
  count: number;
}

export const WordCount = (props: Props) => {
  let color: string;
  if (props.count <= 0 || props.count >= 101) {
    color = 'base.error';
  } else if ((props.count >= 1 && props.count <= 9) || (props.count >= 51 && props.count <= 100)) {
    color = 'rgb(250,148,5)';
  } else {
    color = 'primary.main';
  }
  return (
    <CVTypography size="c1" sx={{ color }}>
      現在 {props.count} 文字
    </CVTypography>
  );
};
