import { Box, MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';

import { ACCOMP_INSTRUMENT_ITEMS } from '../../../constants';
import { accompPatternsSelector, drumPatternsSelector } from '../../../selectors/projectSelector';
import { CVTypography } from '../../ui-elements/CVTypography';
import { CVHeadline } from '../CVHeadline';

interface Props {
  accompInstrumentValue1: number;
  setAccompInstrumentValue1: (newState: number) => void;
  accompPatternValue1: number;
  setAccompPatternValue1: (newState: number) => void;
  accompInstrumentValue2: number;
  setAccompInstrumentValue2: (newState: number) => void;
  accompPatternValue2: number;
  setAccompPatternValue2: (newState: number) => void;
  drumPatternValue: number;
  setDrumPatternValue: (newState: number) => void;
}

export const AccompStyleSection = (props: Props) => {
  const accompPatterns = useSelector(accompPatternsSelector);
  const drumPatterns = useSelector(drumPatternsSelector);

  const onChangeAccompInstrument1 = (e: SelectChangeEvent<number>) =>
    props.setAccompInstrumentValue1(e.target.value as number);
  const onChangeAccompPattern1 = (e: SelectChangeEvent<number>) =>
    props.setAccompPatternValue1(e.target.value as number);
  const onChangeAccompInstrument2 = (e: SelectChangeEvent<number>) =>
    props.setAccompInstrumentValue2(e.target.value as number);
  const onChangeAccompPattern2 = (e: SelectChangeEvent<number>) =>
    props.setAccompPatternValue2(e.target.value as number);
  const onChangeDrumPattern = (e: SelectChangeEvent<number>) => props.setDrumPatternValue(e.target.value as number);

  return (
    <Box sx={{ marginTop: 8 }}>
      <CVHeadline size="h3">伴奏のスタイル</CVHeadline>
      <CVTypography size="c1" sx={{ color: 'base.secondary', marginTop: 2.75 }}>
        伴奏のパターンや楽器が選択できます。
      </CVTypography>
      <Box sx={{ backgroundColor: 'bg.secondary', marginTop: 3 }}>
        <Box
          sx={{
            paddingTop: 4,
            paddingLeft: 5,
            paddingRight: 5,
            paddingBottom: 2.5,
          }}
        >
          <CVTypography size="b1">伴奏パターン１</CVTypography>
          <Select
            value={props.accompPatternValue1}
            onChange={onChangeAccompPattern1}
            sx={{
              backgroundColor: 'bg.form',
              marginTop: 2,
              marginBottom: 2.5,
              width: { md: '50%', xs: '100%' },
            }}
            native={isMobile}
          >
            {accompPatterns.map((accompPattern) =>
              isMobile ? (
                <option key={accompPattern.id} value={accompPattern.id}>
                  {accompPattern.description}
                </option>
              ) : (
                <MenuItem key={accompPattern.id} value={accompPattern.id}>
                  {accompPattern.description}
                </MenuItem>
              )
            )}
          </Select>
          <CVTypography size="c1" sx={{ color: 'base.secondary', marginBottom: 2 }}>
            伴奏楽器
          </CVTypography>
          <Select
            value={props.accompInstrumentValue1}
            onChange={onChangeAccompInstrument1}
            sx={{
              backgroundColor: 'bg.form',
              width: { md: '50%', xs: '100%' },
              marginBottom: 3,
            }}
            native={isMobile}
          >
            {ACCOMP_INSTRUMENT_ITEMS.map((item) =>
              isMobile ? (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ) : (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              )
            )}
          </Select>
          <CVTypography size="b1">伴奏パターン２</CVTypography>
          <Select
            value={props.accompPatternValue2}
            onChange={onChangeAccompPattern2}
            sx={{
              backgroundColor: 'bg.form',
              marginTop: 2,
              marginBottom: 2.5,
              width: { md: '50%', xs: '100%' },
            }}
            native={isMobile}
          >
            {accompPatterns.map((accompPattern) =>
              isMobile ? (
                <option key={accompPattern.id} value={accompPattern.id}>
                  {accompPattern.description}
                </option>
              ) : (
                <MenuItem key={accompPattern.id} value={accompPattern.id}>
                  {accompPattern.description}
                </MenuItem>
              )
            )}
          </Select>
          <CVTypography size="c1" sx={{ color: 'base.secondary', marginBottom: 2 }}>
            伴奏楽器
          </CVTypography>
          <Select
            value={props.accompInstrumentValue2}
            onChange={onChangeAccompInstrument2}
            sx={{
              backgroundColor: 'bg.form',
              width: { md: '50%', xs: '100%' },
              marginBottom: 3,
            }}
            native={isMobile}
          >
            {ACCOMP_INSTRUMENT_ITEMS.map((item) =>
              isMobile ? (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ) : (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              )
            )}
          </Select>
          <CVTypography size="b1">ドラムパターン</CVTypography>
          <Select
            value={props.drumPatternValue}
            onChange={onChangeDrumPattern}
            sx={{
              backgroundColor: 'bg.form',
              marginTop: 2,
              marginBottom: 2.5,
              width: { md: '50%', xs: '100%' },
            }}
            native={isMobile}
          >
            {drumPatterns.map((drumPattern) =>
              isMobile ? (
                <option key={drumPattern.id} value={drumPattern.id}>
                  {drumPattern.description}
                </option>
              ) : (
                <MenuItem key={drumPattern.id} value={drumPattern.id}>
                  {drumPattern.description}
                </MenuItem>
              )
            )}
          </Select>
        </Box>
      </Box>
    </Box>
  );
};
