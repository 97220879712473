import { Box } from '@mui/material';

import footerImage from '../../images/footer_image.svg';
import footerImageForTop from '../../images/footer_image_for_top.svg';
import { CVTypography } from '../ui-elements/CVTypography';

interface Props {
  isTopPage?: boolean;
}

export const Footer = (props: Props) => {
  const footerSvg = props.isTopPage ? footerImageForTop : footerImage;
  return (
    <>
      <Box
        component="img"
        src={footerSvg}
        alt=""
        sx={{
          width: '100%',
          left: 0,
          zIndex: -100,
          position: 'absolute',
          bottom: 40,
        }}
      />
      <Box
        sx={{
          color: '#FFFFFF',
          backgroundColor: 'primary.main',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 40,
          width: '100%',
        }}
      >
        <CVTypography size="c3">CREEVO © ︎2020-2023</CVTypography>
      </Box>
    </>
  );
};
