import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { unstable_useBlocker as useBlocker, useNavigate, resolvePath } from 'react-router-dom';

import { projectSlice } from '../slices/projectSlice';
import { AppDispatch } from '../store';

import type { unstable_Blocker as Blocker, Path, To } from 'react-router-dom';

export type NavigateBlocker = {
  allowPaths: Array<To>;
  allowBack?: boolean;
};

export const useNavigateBlocker = (props: NavigateBlocker): void => {
  const { allowPaths, allowBack = true } = props;
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const allowLocations: Array<Path> = allowPaths.map((path) => resolvePath(path));
  const blocker: Blocker = useBlocker(({ nextLocation, historyAction }) => {
    if (allowBack && historyAction === 'POP') return false; // 戻るボタンは許容する
    return allowLocations.map((l) => l.pathname).indexOf(nextLocation.pathname) === -1;
  });
  useEffect(() => {
    if (blocker.state === 'unblocked') return;
    if (blocker.state === 'proceeding') {
      navigate(blocker.location.pathname);
    }
    if (blocker.state === 'blocked') {
      const canProceed = window.confirm('ページを離れると作曲途中のデータは失われます。よろしいですか？');
      if (canProceed) {
        // 作曲途中のデータを削除する
        dispatch(projectSlice.actions.clearSavedProject());
        if (allowBack) {
          dispatch(projectSlice.actions.clearProject());
        }
        blocker.proceed();
      } else {
        blocker.reset();
      }
    }
  }, [blocker.state]);
};
