import { Box, MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';

import { KEY_ITEMS } from '../../../constants';
import { chordProgressionsSelector, nBarSelector } from '../../../selectors/projectSelector';
import { CVTypography } from '../../ui-elements/CVTypography';
import { CVHeadline } from '../CVHeadline';

interface Props {
  chordProgressionId: number;
  setChordProgressionId: (newState: number) => void;
  keyValue: number;
  setKeyValue: (newState: number) => void;
}

export const ChordProgressionSection = (props: Props) => {
  const chordProgressions = useSelector(chordProgressionsSelector);
  const nBar = useSelector(nBarSelector);
  const usableChordProgressions = chordProgressions.filter((chordProgression) => chordProgression.nBar === nBar);
  const onChangeChordProgression = (e: SelectChangeEvent<number>) => {
    props.setChordProgressionId(e.target.value as number);
  };
  const onChangeKeyValue = (e: SelectChangeEvent<number>) => {
    props.setKeyValue(e.target.value as number);
  };
  return (
    <Box sx={{ marginTop: 8 }}>
      <CVHeadline size="h3">コード進行</CVHeadline>
      <CVTypography size="c1" sx={{ color: 'base.secondary', marginTop: 2.75 }}>
        楽曲の全体的な特徴が決められます。
      </CVTypography>
      <Box sx={{ backgroundColor: 'bg.secondary', marginTop: 3 }}>
        <Box
          sx={{
            paddingTop: 4,
            paddingLeft: 5,
            paddingRight: 5,
            paddingBottom: 2.5,
          }}
        >
          <Select
            value={props.chordProgressionId}
            onChange={onChangeChordProgression}
            placeholder="コード進行を選択"
            sx={{
              backgroundColor: 'bg.form',
              marginBottom: 2.5,
              width: { md: '50%', xs: '100%' },
            }}
            native={isMobile}
          >
            {usableChordProgressions.map((chordProgression) =>
              isMobile ? (
                <option key={chordProgression.id} value={chordProgression.id}>
                  {chordProgression.description}
                </option>
              ) : (
                <MenuItem key={chordProgression.id} value={chordProgression.id}>
                  {chordProgression.description}
                </MenuItem>
              )
            )}
          </Select>
          <CVTypography size="c1" sx={{ color: 'base.secondary', marginBottom: 2 }}>
            調
          </CVTypography>
          <Select
            value={props.keyValue}
            onChange={onChangeKeyValue}
            sx={{
              backgroundColor: 'bg.form',
              width: { md: '50%', xs: '100%' },
            }}
            native={isMobile}
          >
            {KEY_ITEMS.map((key) =>
              isMobile ? (
                <option key={key.value} value={key.value}>
                  {key.label}
                </option>
              ) : (
                <MenuItem key={key.value} value={key.value}>
                  {key.label}
                </MenuItem>
              )
            )}
          </Select>
        </Box>
      </Box>
    </Box>
  );
};
