import { CompositionType } from '../models';

export const getCVStepTextByCompType = (type: CompositionType) => {
  switch (type) {
    case 'auto': {
      return '作曲データを選択';
    }
    case 'designed': {
      return '曲調のデザイン';
    }
    default: {
      return '選択';
    }
  }
};
