import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams, useParams } from 'react-router-dom';

import { CVHeadline } from '../../components/ui-parts/CVHeadline';
import { ProjectDetail } from '../../components/ui-parts/ProjectDetail';
import { rankingProjectDetailSelector } from '../../selectors/rankingSelector';
import { onFetchProjectDetail, onUpdateSelfRating } from '../../slices/rankingSlice';
import { AppDispatch } from '../../store';

export const Project = () => {
  const [searchParams, _] = useSearchParams();
  const [selfRating, setSelfRating] = useState<number | null>(0);
  const { pid } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const projectDetail = useSelector(rankingProjectDetailSelector);

  useEffect(() => {
    if (pid) {
      void dispatch(
        onFetchProjectDetail({
          projectId: Number(pid),
          projectKey: searchParams.get('project_key'),
        })
      );
    }
  }, [pid]);

  useEffect(() => {
    const projectKey = searchParams.get('project_key');
    if (selfRating !== null && projectKey !== null) {
      void dispatch(
        onUpdateSelfRating({
          projectId: Number(pid),
          projectKey,
          newRating: selfRating,
        })
      );
    }
  }, [selfRating]);

  return (
    <>
      <Box>
        <CVHeadline size="h1">みんなの作品</CVHeadline>
      </Box>
      {projectDetail !== null && (
        <ProjectDetail
          limited={!searchParams.has('project_key')}
          projectDetail={projectDetail}
          projectKey={null}
          selfRating={projectDetail.project.selfRating}
          updateRating={setSelfRating}
          setConfirmProjectDeletionModalOpen={undefined}
        />
      )}
    </>
  );
};
