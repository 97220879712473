import { configureStore } from '@reduxjs/toolkit';

import { maintenanceReducer } from './slices/maintenanceSlice';
import { myListReducer } from './slices/myListSlice';
import { newsReducer } from './slices/newsSlice';
import { projectReducer } from './slices/projectSlice';
import { rankingReducer } from './slices/rankingSlice';
import { userReducer } from './slices/userSlice';

export const store = configureStore({
  reducer: {
    ranking: rankingReducer,
    myList: myListReducer,
    user: userReducer,
    project: projectReducer,
    maintenance: maintenanceReducer,
    news: newsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
