import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CVHeadline } from '../../components/ui-parts/CVHeadline';
import designImage from '../../images/select_comp_type_design.png';
import omakaseImage from '../../images/select_comp_type_omakase.png';
import { projectSlice } from '../../slices/projectSlice';
import { AppDispatch } from '../../store';

export const SelectType = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const onClickAutoMode = () => {
    dispatch(projectSlice.actions.selectCompositionType('auto'));
    navigation('../composition/input-lyrics/');
  };
  const onClickDesignMode = () => {
    dispatch(projectSlice.actions.selectCompositionType('designed'));
    navigation('../composition/input-lyrics/');
  };
  return (
    <>
      <Box>
        <CVHeadline size="h1">曲を作る</CVHeadline>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: { md: 17, xs: 5 },
        }}
      >
        <Box
          component="img"
          src={omakaseImage}
          sx={{
            width: { md: '48%', xs: '100%' },
            marginBottom: 5,
            cursor: 'pointer',
          }}
          onClick={onClickAutoMode}
        />
        <Box
          component="img"
          src={designImage}
          sx={{
            width: { md: '48%', xs: '100%' },
            marginBottom: 5,
            cursor: 'pointer',
          }}
          onClick={onClickDesignMode}
        />
      </Box>
    </>
  );
};
