import axios from 'axios';

const client = axios.create({
  timeout: 10000, // 10 seconds
});

/**
 * https://ipapi.co/api/
 * @return {Promise<string>} 国コード、取得できなかった場合は空文字
 */
const getFromIpapiCo = async (): Promise<string> => {
  try {
    const response = await client.get('https://ipapi.co/json/');
    if (response.status !== 200) {
      return '';
    }
    return response.data.country_code;
  } catch (error) {
    return '';
  }
};

/**
 * http://ip-api.com/docs/
 * @return {Promise<string>} 国コード、取得できなかった場合は空文字
 */
const getFromIpApiCom = async (): Promise<string> => {
  try {
    const response = await client.get('http://ip-api.com/json');
    if (response.status !== 200) {
      return '';
    }
    return response.data.countryCode;
  } catch (error) {
    return '';
  }
};

// 以下の関数はすべて ISO 3166-1 alpha-2 形式の国コードを返す
// 1つのサービスが利用不可能のときのためのフォールバック
const getIpFunctions = [getFromIpapiCo, getFromIpApiCom];

const getCountryCodeFromApi = async (): Promise<string> => {
  for (const func of getIpFunctions) {
    const countryCode = await func();
    if (countryCode) {
      return countryCode;
    }
  }
  return '';
};

/**
 * 国コードを取得する
 * ローカルストレージに1日間キャッシュし、キャッシュされているものがあればそれを返す
 * @return {Promise<string>} 国コード、取得できなかった場合は空文字
 */
export const getCountryCode = async (): Promise<string> => {
  //   const cachedCountryCode = localStorage.getItem("countryCode");
  //   const expiredAt = localStorage.getItem("countryCodeExpiredAt");
  //   if (cachedCountryCode && expiredAt && Date.now() < parseInt(expiredAt)) {
  //     return cachedCountryCode;
  //   }

  const countryCode = await getCountryCodeFromApi();
  if (countryCode) {
    localStorage.setItem('countryCode', countryCode);

    // cache country code for 1 day
    const NewExpiredAt = Date.now() + 1000 * 60 * 60 * 24;
    localStorage.setItem('countryCodeExpiredAt', NewExpiredAt.toString());
  }

  return countryCode;
};
