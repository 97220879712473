import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getMaintenanceMode } from '../api/FirebaseRemoteConfigApi';

import type { MaintenanceMode } from '../api/FirebaseRemoteConfigApi';

type MaintenanceState = {
  isMaintenanceLoading: boolean;
  maintenanceMode: MaintenanceMode;
};

const initialState: MaintenanceState = {
  isMaintenanceLoading: false,
  maintenanceMode: {
    isMaintenanced: false,
    message:
      '申し訳ございません。現在メンテナンスのためシステムが休止中です。1時間以内の復旧を予定しておりますので、またご利用ください。',
  },
};

export const onFetchMaintenance = createAsyncThunk('maintenance/onFetchMaintenance', async () => {
  const maintenanceMode = await getMaintenanceMode();
  return { maintenanceMode };
});

export const maintenanceSlice = createSlice({
  name: 'maintenance',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(onFetchMaintenance.pending, (state) => ({
      ...state,
      isMaintenanceLoading: true,
    }));
    builder.addCase(onFetchMaintenance.fulfilled, (state, action) => ({
      ...state,
      isMaintenanceLoading: false,
      maintenanceMode: action.payload.maintenanceMode,
    }));
    builder.addCase(onFetchMaintenance.rejected, (state) => ({
      ...state,
      isMaintenanceLoading: false,
    }));
  },
});

export const maintenanceReducer = maintenanceSlice.reducer;
