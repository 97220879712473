import { Box, Button, Modal, Typography } from '@mui/material';
import * as React from 'react';

import { CVTypography } from '../components/ui-elements/CVTypography';
import LyricsHintImgOld from '../images/lyrics_hint_old.gif';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LyricsHelp = (props: Props) => (
  <Modal open={props.open} onClose={() => props.setOpen(false)}>
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: { xs: '10%', md: '50%' },
        transform: {
          xs: 'translate(-5%, -50%)',
          md: 'translate(-50%, -50%)',
        },
        backgroundColor: 'bg.primary',
        padding: 4,
        borderRadius: 4,
        maxWidth: 600,
        maxHeight: '80vh',
        overflowY: 'scroll',
      }}
    >
      <CVTypography size="h">歌詞を設定する</CVTypography>
      <Box
        component="img"
        src={LyricsHintImgOld}
        width={1810}
        height={540}
        sx={{
          maxWidth: '100%',
          height: 'auto',
          marginY: 2,
        }}
      />
      <Typography component="p">
        改行やスペースは、小節の区切りとして認識されます。
        <br />
        曲の長さは最大12小節ですので、あまりに長い歌詞は入れられません。
        <br />
        歌詞が決まったら、「次へ」進みましょう。
        <br />
        <br />
        誹謗中傷を含む内容や公序良俗に反する歌詞は使えません。
        <br />
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button onClick={() => props.setOpen(false)}>閉じる</Button>
      </Box>
    </Box>
  </Modal>
);
