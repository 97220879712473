import { Box, FormControl, Slider } from '@mui/material';
import { useState, useEffect } from 'react';

import { ACCEPTABLE_BPM_VALUES } from '../../../constants';
/*
  BPM (configでもBPM)
    範囲：50~200、ACCEPTABLE_BPM_VALUESで宣言された整数のみに制限
    デフォルト値：100
    これはconfigにも反映させるが、視聴時のBPMにも反映
    以下で0~100の値により処理されるが、実際のBPMはこれをbpmConverterで変換したものである
*/

export const defaultValue = 150;

interface Props {
  onChange: (newBpm: number) => void;
}

export const BpmSlider = (props: Props) => {
  const { onChange } = props;
  const [bpmPower, setBpmPower] = useState(defaultValue);

  const bpmConverter = (power: number) => Math.floor(25 * 2 ** (power / 150 + 1));

  useEffect(() => {
    onChange(bpmConverter(bpmPower));
  }, [bpmPower]);

  const valueOfMarks: number[] = [0];
  for (let i = 1; i <= 300; i++) {
    const convertedBpm = bpmConverter(i);
    if (ACCEPTABLE_BPM_VALUES.indexOf(convertedBpm) !== -1 && bpmConverter(i - 1) !== convertedBpm) {
      valueOfMarks.push(i);
    }
  }
  const marks = valueOfMarks.map((value) => {
    let label = '';
    switch (value) {
      case 0: {
        label = 'とてもおそい';
        break;
      }
      case 150: {
        label = 'ふつう';
        break;
      }
      case 300: {
        label = 'とてもはやい';
        break;
      }
      default: {
        break;
      }
    }
    return {
      value,
      label,
    };
  });

  return (
    <FormControl
      sx={{
        width: { xs: '100%', md: 600 },
        marginBottom: '.5em',
      }}
    >
      <Box py={2} px={5}>
        <Slider
          value={bpmPower}
          defaultValue={defaultValue}
          min={0}
          max={300}
          scale={(x) => bpmConverter(x)}
          step={null}
          marks={marks}
          valueLabelDisplay="auto"
          color="primary"
          onChange={(_: Event, newBpmPower: number | number[]) => {
            if (typeof newBpmPower === 'number') {
              setBpmPower(newBpmPower);
            }
          }}
        />
      </Box>
    </FormControl>
  );
};
