import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Box, Rating } from '@mui/material';
import { useState } from 'react';

import { CVTypography } from '../ui-elements/CVTypography';

const labels: { [key: number]: string } = {
  0: '未評価',
  1: 'ひどすぎる',
  2: 'とても悪い',
  3: '悪い',
  4: '少し悪い',
  5: 'まずまず',
  6: '少し良い',
  7: '良い',
  8: 'とても良い',
  9: '素晴らしい',
  10: '芸術的',
};

export const ProjectRating = ({
  rating,
  setRating,
  enableRating,
}: {
  rating: number | null;
  setRating: (rating: number | null) => void;
  enableRating: boolean;
}) => {
  const [hover, setHover] = useState(-1);

  const onChangeRating = (newRating: number | null) => {
    if (newRating) {
      setRating(newRating);
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 6 }}>
      <Box
        sx={{
          backgroundColor: 'bg.form',
          borderColor: 'secondary.light',
          borderWidth: 8,
          width: 546,
          borderStyle: 'solid',
        }}
      >
        <Box sx={{ width: 546 }}>
          {rating !== null && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 3.5,
              }}
            >
              <CVTypography size="h" sx={{ color: 'base.secondary' }}>
                {labels[hover !== -1 ? hover : rating]}
              </CVTypography>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 4.5,
              marginBottom: 4,
            }}
          >
            <Rating
              name="self-rating"
              max={10}
              value={rating}
              onChange={(_event, newValue) => {
                onChangeRating(newValue);
              }}
              onChangeActive={(_event, newHover) => {
                setHover(newHover);
              }}
              icon={<StarIcon fontSize="inherit" sx={{ width: 40, height: 40 }} />}
              emptyIcon={
                enableRating ? (
                  <StarIcon fontSize="inherit" color="action" sx={{ width: 40, height: 40 }} />
                ) : (
                  <StarBorderIcon fontSize="inherit" style={{ color: 'white' }} />
                )
              }
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
