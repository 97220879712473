import { Button, Theme, Box } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { ReactElement } from 'react';

import { CVTypography } from '../ui-elements/CVTypography';

interface Props {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  buttonColor?: 'primary.main' | 'primary.light' | 'base.disabled' | 'base.error';
  size: 'll' | 'l' | 'm' | 's';
  Icon?: ReactElement;
  sx?: SystemStyleObject<Theme>;
}

type TextStyleObj = {
  textSize: 't3' | 'b2' | 'o1';
  textWidth: number;
  textMarginHorizontal: number;
  textMarginVertical: number;
};

type TextStyle = {
  ll: TextStyleObj;
  l: TextStyleObj;
  m: TextStyleObj;
  s: TextStyleObj;
};

export const CVButton = (props: Props) => {
  const textStyle: TextStyle = {
    ll: {
      textSize: 't3',
      textWidth: 374,
      textMarginVertical: 2,
      textMarginHorizontal: 2.5,
    },
    l: {
      textSize: 't3',
      textWidth: 200,
      textMarginVertical: 1.8125,
      textMarginHorizontal: 2,
    },
    m: {
      textSize: 'b2',
      textWidth: 120,
      textMarginVertical: 0.8125,
      textMarginHorizontal: 2,
    },
    s: {
      textSize: 'o1',
      textWidth: 40,
      textMarginVertical: 0.5,
      textMarginHorizontal: 1,
    },
  };

  let fontColor: string;
  switch (props.buttonColor) {
    case 'primary.main': {
      fontColor = '#FFFFFF';
      break;
    }
    case 'primary.light': {
      fontColor = 'primary.main';
      break;
    }
    case 'base.disabled': {
      fontColor = 'base.secondary';
      break;
    }
    default: {
      fontColor = '#FFFFFF';
      break;
    }
  }
  return (
    <Button
      variant="contained"
      onClick={props.onClick}
      disabled={props.disabled}
      sx={[
        {
          backgroundColor: props.buttonColor !== undefined ? props.buttonColor : 'primary.main',
          padding: props.Icon ? 0 : 0,
          borderRadius: 9999,
          '&:hover': {
            backgroundColor: props.buttonColor !== undefined ? props.buttonColor : 'primary.main',
          },
        },
        props.sx !== undefined && props.sx,
      ]}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {props.Icon !== undefined && props.Icon}
        <CVTypography
          size={textStyle[props.size].textSize}
          sx={{
            width: textStyle[props.size].textWidth,
            marginTop: textStyle[props.size].textMarginVertical,
            marginLeft: props.Icon ? 0 : textStyle[props.size].textMarginHorizontal,
            marginRight: textStyle[props.size].textMarginHorizontal,
            marginBottom: textStyle[props.size].textMarginVertical,
            color: fontColor,
          }}
        >
          {props.text}
        </CVTypography>
      </Box>
    </Button>
  );
};
