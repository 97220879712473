import { LYRICS_SAMPLE, DEFAULT_LYRICS_N_BAR } from '../constants';

export const getDefaultLyrics = () => {
  const lyricsList = LYRICS_SAMPLE.slice(0, DEFAULT_LYRICS_N_BAR);
  const nLine = Math.floor((DEFAULT_LYRICS_N_BAR + 1) / 2);
  let result = '';
  for (let i = 0; i < nLine; i++) {
    result += lyricsList.slice(i * 2, i * 2 + 2).join('　');
    if (i + 1 < nLine) {
      result += '　\n';
    }
  }
  return result;
};
