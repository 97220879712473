import { getRemoteConfig, getValue, fetchAndActivate } from 'firebase/remote-config';

import { firebaseApp } from '../firebase';

const remoteConfig = getRemoteConfig(firebaseApp);
remoteConfig.defaultConfig = {
  isMaintenanced: false,
  maintenanceMessage: `申し訳ございません。現在メンテナンスのためシステムが休止中です。1時間以内の復旧を予定しておりますので、またご利用ください。`,
  news: `[{"date": "2022-03-31", "message": "GoogleやTwitterのアカウントでログインして作曲すると、自分の作品がいつでも聴けるようになりました。"}]`,
};

// 最小フェッチ時間
remoteConfig.settings.minimumFetchIntervalMillis = 1000 * 60;

export type MaintenanceMode = {
  isMaintenanced: boolean;
  message: string;
};

export const getMaintenanceMode = async (): Promise<MaintenanceMode> => {
  // Firebase Remote Config からメンテナンス情報を取得する
  // isMaintenanced: bool メンテナンス中ならtrue, 正常時はfalse
  // message: string メンテナンス中に表示するメッセージ
  await fetchAndActivate(remoteConfig);
  const isMaintenanced = getValue(remoteConfig, 'isMaintenanced');
  const message = getValue(remoteConfig, 'maintenanceMessage');
  return {
    isMaintenanced: isMaintenanced.asBoolean(),
    message: message.asString(),
  };
};

export type News = {
  date: string;
  message: string;
};

export const getNews = async (): Promise<Array<News>> => {
  await fetchAndActivate(remoteConfig);
  const newsText = getValue(remoteConfig, 'news');
  const news: News[] = JSON.parse(newsText.asString()) as News[];
  return news;
};
