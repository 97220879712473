import { Slider, Box } from '@mui/material';

import { CVTypography } from '../../../ui-elements/CVTypography';

/*
  音域（configではMIN_PITCHとMAX_PICH）
    最低音と最高音を半音単位で選択できるように
    音域幅は最低1オクターブになるように（真ん中のドと高いドが選択中に、低い音をさらに上げたら高い音も自動で上がるようにしておく）
    音域幅は最大3オクターブになるように（低いドと高い高いドが選択中に、低い音をさらに下げたら高い音も自動で下がるようにしておく）
    表示方法：MIDIノートナンバーで、（できれば）変更時に吹き出しで「高いド#/レ♭」とか表示されるとよい。
    デフォルト値：57-74
*/

export const defaultValue = {
  min: 57,
  max: 74,
};
const lowerbound = 36;
const upperbound = 96;

interface Props {
  minPitch: number;
  maxPitch: number;
  onChange: (newMinPitch: number, newMaxPitch: number) => void;
}

const pitchToSitch = (value: number): string => {
  const pc: number = value % 12;
  const oct: number = Math.floor(value / 12) - 1;
  return `${['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'][pc]}${oct}`;
};

const pitchMarks = [
  {
    value: 36,
    label: '低い低いド',
  },
  {
    value: 48,
    label: '低いド',
  },
  {
    value: 60,
    label: '真ん中のド',
  },
  {
    value: 72,
    label: '高いド',
  },
  {
    value: 84,
    label: '高い高いド',
  },
  {
    value: 96,
    label: '超高いド',
  },
];

const IntegerStep = (props: Props) => {
  const onPitchChange = (_: Event, value: number | number[]) => {
    if (typeof value !== 'number') {
      let newMinPitch = value[0];
      let newMaxPitch = value[1];
      if (newMinPitch !== props.minPitch) {
        newMaxPitch = Math.min(upperbound, Math.max(newMaxPitch, newMinPitch + 12));
        newMaxPitch = Math.min(upperbound, Math.min(newMaxPitch, newMinPitch + 36));
        newMinPitch = Math.min(upperbound - 12, newMinPitch);
      } else {
        newMinPitch = Math.max(lowerbound, Math.min(newMinPitch, newMaxPitch - 12));
        newMinPitch = Math.max(lowerbound, Math.max(newMinPitch, newMaxPitch - 36));
        newMaxPitch = Math.max(lowerbound + 12, newMaxPitch);
      }

      props.onChange(newMinPitch, newMaxPitch);
    }
  };
  return (
    <Slider
      min={lowerbound}
      max={upperbound}
      onChange={onPitchChange}
      value={[props.minPitch, props.maxPitch]}
      marks={pitchMarks}
      step={1}
      color="primary"
      valueLabelFormat={pitchToSitch}
      valueLabelDisplay="auto"
      defaultValue={[defaultValue.min, defaultValue.max]}
    />
  );
};

export const PitchSlider = (props: Props) => (
  <Box sx={{ marginLeft: 4, marginRight: 4 }}>
    <CVTypography size="c1" sx={{ color: 'base.secondary' }}>
      音域
    </CVTypography>
    <IntegerStep {...props} />
  </Box>
);
