import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { firebaseApp } from '../../firebase';
import { onLogin } from '../../slices/userSlice';
import { AppDispatch } from '../../store';

interface Props {
  children: JSX.Element;
}

export const AuthProvider = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    const auth = getAuth(firebaseApp);
    const unregisterAuthObserver = onAuthStateChanged(auth, (user) => {
      if (user !== null) {
        void dispatch(onLogin(user));
      }
    });

    return () => unregisterAuthObserver();
  }, [dispatch]);
  return props.children;
};
