import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import * as api from '../api/MyListApi';
import { ProjectDetail } from '../api/MyListApi';
import { WORKLIST_PROJECTS_PER_PAGE } from '../constants';
import { Project } from '../models';
import { ApiError } from '../models/ApiError';
import { handleError } from '../utils/handleError';

import { UserInfo } from './userSlice';

export interface ProjectList {
  projects: Project[];
  hasNext: boolean;
}

export interface Reference extends ProjectList {
  sinceId: number | null;
}

export interface MyListState {
  isProjectLoading: boolean;
  isProjectDetailLoading: boolean;
  references: Reference[];
  projectDetail: ProjectDetail | null;
  index: number;
  errorMessage: ApiError | null;
}

const initialState: MyListState = {
  isProjectLoading: false,
  isProjectDetailLoading: false,
  references: [],
  projectDetail: null,
  index: -1,
  errorMessage: null,
};

export const onFetchUserProjects = createAsyncThunk<
  ProjectList,
  { sinceId: number | null; uid: string; idToken: string },
  { rejectValue: ApiError }
>('myList/onFetchUserProjects', async (params, { rejectWithValue }) => {
  try {
    const response = await api.getProjects(WORKLIST_PROJECTS_PER_PAGE, params.sinceId, params.uid, params.idToken);
    return response.data;
  } catch (e) {
    return rejectWithValue(handleError(e));
  }
});

export const onFetchUserProjectDetail = createAsyncThunk<
  ProjectDetail,
  { projectId: number; userInfo: UserInfo },
  { rejectValue: ApiError }
>('ranking/onFetchProjectDetail', async (params, { rejectWithValue }) => {
  try {
    const response = await api.getProjectDetail(params.projectId, params.userInfo);
    return response.data;
  } catch (e) {
    return rejectWithValue(handleError(e));
  }
});

export const onUpdateUserSelfRating = createAsyncThunk<
  { newRating: number | null },
  { projectId: number; newRating: number | null; userInfo: UserInfo },
  { rejectValue: ApiError }
>('ranking/onUpdateSelfRating', async (params, { rejectWithValue }) => {
  if (params.newRating) {
    try {
      await api.putSelfRating(params.projectId, params.newRating, params.userInfo);
    } catch (e) {
      return rejectWithValue(handleError(e));
    }
  }
  return { newRating: params.newRating };
});

export const onDeleteUserProject = createAsyncThunk<
  void,
  { projectId: number; userInfo: UserInfo },
  { rejectValue: ApiError }
>('user/onDeleteUserProject', async (params, { rejectWithValue }) => {
  try {
    await api.deleteProject(params.projectId, params.userInfo);
  } catch (e) {
    return rejectWithValue(handleError(e));
  }
});

export const onPutUserPublicationAgreement = createAsyncThunk<
  { publicationAgreed: boolean },
  { projectId: number; publicationAgreed: boolean; userInfo: UserInfo },
  { rejectValue: ApiError }
>('ranking/onPutPublicationAgreement', async (params, { rejectWithValue }) => {
  try {
    await api.putPublicationAgreement(params.projectId, params.publicationAgreed, params.userInfo);
  } catch (e) {
    return rejectWithValue(handleError(e));
  }
  return { publicationAgreed: params.publicationAgreed };
});

export const myListSlice = createSlice({
  name: 'myList',
  initialState,
  reducers: {
    incrementIndex: (state) => ({
      ...state,
      index: state.index + 1,
    }),
    decrementIndex: (state) => ({
      ...state,
      index: state.index - 1,
    }),
    cleanAll: (state) => ({
      ...state,
      isProjectLoading: initialState.isProjectLoading,
      isProjectDetailLoading: initialState.isProjectDetailLoading,
      index: initialState.index,
      references: initialState.references,
      projectDetail: initialState.projectDetail,
      errorMessage: initialState.errorMessage,
    }),
    clearErrorMessage: (state) => ({
      ...state,
      errorMessage: initialState.errorMessage,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(onFetchUserProjects.pending, (state) => ({
      ...state,
      isProjectLoading: true,
    }));
    builder.addCase(onFetchUserProjects.fulfilled, (state, action) => ({
      ...state,
      references: state.references.concat({
        ...action.payload,
        sinceId: action.payload.projects.slice(-1)[0]?.projectId,
      }),
      index: state.index + 1,
      isProjectLoading: false,
    }));
    builder.addCase(onFetchUserProjects.rejected, (state, action) => ({
      ...state,
      isProjectLoading: false,
      errorMessage: action.payload ?? null,
    }));
    builder.addCase(onFetchUserProjectDetail.pending, (state) => ({
      ...state,
      isProjectDetailLoading: true,
    }));
    builder.addCase(onFetchUserProjectDetail.fulfilled, (state, action) => ({
      ...state,
      projectDetail: action.payload,
      isProjectDetailLoading: false,
    }));
    builder.addCase(onFetchUserProjectDetail.rejected, (state, action) => ({
      ...state,
      isProjectDetailLoading: false,
      errorMessage: action.payload ?? null,
    }));
    builder.addCase(onUpdateUserSelfRating.pending, (state) => ({
      ...state,
      isProjectDetailLoading: true,
    }));
    builder.addCase(onUpdateUserSelfRating.fulfilled, (state, action) => {
      if (action.payload.newRating !== null && state.projectDetail !== null) {
        return {
          ...state,
          isProjectDetailLoading: false,
          projectDetail: {
            ...state.projectDetail,
            project: {
              ...state.projectDetail.project,
              selfRating: action.payload.newRating,
            },
          },
        };
      }
      return {
        ...state,
        isProjectDetailLoading: false,
      };
    });
    builder.addCase(onUpdateUserSelfRating.rejected, (state, action) => ({
      ...state,
      isProjectDetailLoading: false,
      errorMessage: action.payload ?? null,
    }));
    builder.addCase(onPutUserPublicationAgreement.pending, (state) => ({
      ...state,
      isProjectDetailLoading: true,
    }));
    builder.addCase(onDeleteUserProject.pending, (state) => ({
      ...state,
      isProjectDetailLoading: true,
    }));
    builder.addCase(onDeleteUserProject.fulfilled, (state) => ({
      ...state,
      isProjectDetailLoading: false,
    }));
    builder.addCase(onDeleteUserProject.rejected, (state, action) => ({
      ...state,
      isProjectDetailLoading: false,
      errorMessage: action.payload ?? null,
    }));
    builder.addCase(onPutUserPublicationAgreement.fulfilled, (state, action) => {
      if (state.projectDetail !== null) {
        return {
          ...state,
          isProjectDetailLoading: false,
          projectDetail: {
            ...state.projectDetail,
            project: {
              ...state.projectDetail.project,
              publicationAgreed: action.payload.publicationAgreed,
            },
          },
        };
      }
      return {
        ...state,
        isProjectDetailLoading: false,
      };
    });
    builder.addCase(onPutUserPublicationAgreement.rejected, (state, action) => ({
      ...state,
      isProjectDetailLoading: false,
      errorMessage: action.payload ?? null,
    }));
  },
});

export const myListReducer = myListSlice.reducer;
