import { Box, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useNavigationType } from 'react-router-dom';

import { CVTypography } from '../../components/ui-elements/CVTypography';
import { CVButton } from '../../components/ui-parts/CVButton';
import { CVHeadline } from '../../components/ui-parts/CVHeadline';
import { CVStep } from '../../components/ui-parts/CVStep';
import { WordCount } from '../../components/ui-parts/WordCount';
import { useNavigateBlocker } from '../../hooks/useNavigateBlocker';
import { usePromptWhenReload } from '../../hooks/usePromptWhenReload';
import { LyricsHelp } from '../../modals/LyricsHelp';
import { compositionTypeSelector, lyricsSelector } from '../../selectors/projectSelector';
import * as projectThunk from '../../slices/projectSlice';
import { AppDispatch } from '../../store';
import { getDefaultLyrics } from '../../utils/getDefaultLyrics';
import { getHeadlineTextByCompType } from '../../utils/getHeadlineTextByCompType';

export const InputLyrics = () => {
  usePromptWhenReload();
  const navigation = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const navigationType = useNavigationType();
  const lyricsState = useSelector(lyricsSelector);
  const initialLyrics = navigationType === 'POP' ? lyricsState : '';
  const [lyrics, setLyrics] = useState<string>(initialLyrics);
  const [isHelpModalOpen, setHelpModalOpen] = useState<boolean>(false);
  const [wordCount, setWordCount] = useState<number>(initialLyrics.replace(/\s+/g, '').length);

  const compositionType = useSelector(compositionTypeSelector);
  const onChangeLyrics = (e: React.ChangeEvent<HTMLInputElement>) => setLyrics(e.target.value);

  useEffect(() => {
    setWordCount(lyrics.replace(/\s+/g, '').length);
  }, [lyrics]);

  useEffect(() => {
    // `ChordProgressions`はよみがな編集時に表示する小節数の
    // 最小と最大の計算に必要なので、
    // InputLyricsYomiに遷移する前に読み込んでおく
    void dispatch(projectThunk.onGetChordProgressions());
  }, []);

  const onClickPrevious = () => navigation('/composition/select-type');
  const onClickNext = async () => {
    await dispatch(
      projectThunk.onConvertLyrics({
        lyrics: lyrics === '' ? getDefaultLyrics() : lyrics,
      })
    );
    navigation('/composition/input-lyrics-yomi');
  };
  useNavigateBlocker({
    allowPaths: ['/composition/input-lyrics-yomi', '/composition/select-type'],
  });
  return (
    <>
      <Box>
        <CVHeadline size="h1">{getHeadlineTextByCompType(compositionType)}</CVHeadline>
      </Box>
      <CVStep activeStep={0} />
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'start',
          marginTop: 6.5,
        }}
      >
        <CVHeadline size="h2">歌詞を入力</CVHeadline>
        <Box onClick={() => setHelpModalOpen(true)}>
          <CVTypography size="c1" sx={{ color: 'primary.main', marginLeft: 2.5, cursor: 'pointer' }}>
            操作のヒント
          </CVTypography>
        </Box>
      </Box>
      <Box
        sx={{
          marginLeft: { md: 2.5, xs: 0 },
          marginLight: { md: 2.5, xs: 0 },
          marginTop: 5,
        }}
      >
        <CVTypography size="c1" sx={{ color: 'base.secondary' }}>
          スペースで区切ると小節が分かれます。
        </CVTypography>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'start',
            marginTop: 2,
          }}
        >
          <WordCount count={wordCount} />
          <CVTypography size="c1" sx={{ color: 'base.secondary' }}>
            （10文字から50文字が目安）
          </CVTypography>
        </Box>
        <Box sx={{ marginTop: 2.5 }}>
          <TextField
            rows={5}
            multiline
            value={lyrics}
            placeholder={getDefaultLyrics()}
            onChange={onChangeLyrics}
            sx={{ width: '100%' }}
            variant="outlined"
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: 17,
          marginBottom: 30,
          marginLeft: '15%',
          marginRight: '15%',
        }}
      >
        <CVButton
          text="戻る"
          onClick={onClickPrevious}
          size="l"
          buttonColor="primary.light"
          sx={{ marginTop: 2, marginX: 'auto' }}
        />
        <CVButton
          text="次へ"
          onClick={onClickNext}
          size="l"
          buttonColor="primary.main"
          sx={{ marginTop: 2, marginX: 'auto' }}
        />
      </Box>
      <LyricsHelp open={isHelpModalOpen} setOpen={setHelpModalOpen} />
    </>
  );
};
