export const DESIGN_CONFIG = {
  alpha: [0, 0.5, 1.0, 1.3, 1.5, 1.7, 1.9, 2.1, 2.3, 2.5],
  beta: [2.5, 2.0, 1.7, 1.5, 1.3, 1.15, 1.0, 0.8, 0.5, 0.3],
};

export const DEFAULT_DESIGN_CONFIG_VALUE = {
  pitch: {
    min: 57,
    max: 74,
  },
  alphaIndex: 8,
  betaIndex: 1,
  anacrusis: 0,
};

export let BASE_URL: string;
export let API_BASE_URL: string;

// artのfirebase config
export let FIREBASE_CONFIG = {
  apiKey: 'AIzaSyCYM50S9KBvI5vJhwQeGNvLT8JgO-mQrsk',
  authDomain: 'creevo-art.firebaseapp.com',
  projectId: 'creevo-art',
  storageBucket: 'creevo-art.appspot.com',
  messagingSenderId: '62609190724',
  appId: '1:62609190724:web:e6e62f7e5a84185cb87111',
};

switch (process.env.REACT_APP_ENV) {
  case 'PRD':
    BASE_URL = 'https://creevo-music.com';
    API_BASE_URL = `${BASE_URL}/api/`;
    FIREBASE_CONFIG = {
      apiKey: 'AIzaSyCRllPqxGT7MG4A6xBrPhpAT7nnZA3dEKk',
      authDomain: 'creevo-music.firebaseapp.com',
      projectId: 'creevo-music',
      storageBucket: 'creevo-music.appspot.com',
      messagingSenderId: '472999047855',
      appId: '1:472999047855:web:19839501802ce3c2fa5a5a',
    };
    break;
  case 'STG':
    BASE_URL = 'https://creevo-art.com';
    API_BASE_URL = `${BASE_URL}/api/`;
    break;
  default:
    BASE_URL = 'http://localhost:8000';
    API_BASE_URL = BASE_URL;
}

export const MELODY_UNIT = 48;
export const BACK_MIDI_NUMBERS = [25, 39];
export const PHRASE_SIZE = 2;
export const NUMBER_SAMPLE = 5;
export const VOLUME_MULTIPLIER = 0.5;

export const LYRICS_SAMPLE = [
  '歌詞はここに',
  '入力します',
  '下のボタンで',
  '平仮名にします',
  'スペースで　区切ると',
  '小節が分かれます',
  //  "他人の歌詞は",
  //  "使えません",
  //  "歌詞はここに",
  //  "歌詞はここに",
  //  "歌詞はここに",
  //  "歌詞はここに",
  //  "歌詞はここに",
  //  "歌詞はここに",
  //  "歌詞はここに",
  //  "歌詞はここに",
];
export const LYRICS_ROW = 5;
export const DEFAULT_LYRICS_N_BAR = 6;

export const TITLE_MAX_LENGTH = 113;

export const DEFAULT_ACCOMP_PATTERN1 = 8;
export const DEFAULT_ACCOMP_INSTRUMENT1 = 25;
export const DEFAULT_ACCOMP_PATTERN2 = 10000005;
export const DEFAULT_ACCOMP_INSTRUMENT2 = 33;
export const DEFAULT_DRUM_PATTERN = 15;

export const ACCEPTABLE_BPM_VALUES = [
  50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78,
  79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 90, 91, 92, 93, 95, 96, 98, 100, 102, 104, 105, 106, 108, 109, 110, 112, 114,
  115, 116, 118, 120, 122, 124, 125, 127, 130, 132, 134, 135, 137, 140, 142, 145, 147, 150, 152, 155, 157, 160, 162,
  165, 167, 170, 172, 175, 177, 180, 182, 185, 187, 190, 192, 195, 200,
];

export const KEY_ITEMS: { value: number; label: string }[] = [
  { value: 5, label: '（♯５つ）Bメジャー/G#マイナー' },
  { value: 4, label: '（♯４つ）Eメジャー/C#マイナー' },
  { value: 3, label: '（♯３つ）Aメジャー/F#マイナー' },
  { value: 2, label: '（♯２つ）Dメジャー/Bマイナー' },
  { value: 1, label: '（♯１つ）Gメジャー/Eマイナー' },
  { value: 0, label: 'Cメジャー/Aマイナー（♯・♭なし）' },
  { value: -1, label: '（♭１つ）Fメジャー/Dマイナー' },
  { value: -2, label: '（♭２つ）B♭メジャー/Gマイナー' },
  { value: -3, label: '（♭３つ）E♭メジャー/Cマイナー' },
  { value: -4, label: '（♭４つ）A♭メジャー/Fマイナー' },
  { value: -5, label: '（♭５つ）D♭メジャー/B♭マイナー' },
  { value: -6, label: '（♭６つ）G♭メジャー/E♭マイナー' },
];

export const INSTRUMENT_ITEMS: { value: number; label: string }[] = [
  { value: 52, label: 'コーラス' },
  { value: 65, label: 'サックス' },
  { value: 68, label: 'オーボエ' },
  { value: 71, label: 'クラリネット' },
  { value: 73, label: 'フルート' },
  { value: 40, label: 'バイオリン' },
  { value: 48, label: 'ストリングス' },
  { value: 0, label: 'ピアノ' },
  { value: 20, label: 'オルガン' },
  { value: 21, label: 'アコーディオン' },
  { value: 22, label: 'ハーモニカ' },
  { value: 25, label: 'ギター' },
  { value: 46, label: 'ハープ' },
  { value: 56, label: 'トランペット' },
  { value: 60, label: 'ホルン' },
  { value: 114, label: 'スチールドラム' },
  { value: 12, label: 'マリンバ' },
  { value: 10, label: 'オルゴール' },
  { value: 79, label: 'オカリナ' },
  { value: 4, label: 'エレクトリックピアノ' },
  { value: 54, label: 'シンセヴォイス' },
  { value: 62, label: 'シンセブラス' },
  { value: 80, label: '正弦波' },
  { value: 81, label: 'ノコギリ波' },
];

export const ACCOMP_INSTRUMENT_ITEMS: { value: number; label: string }[] = [
  { value: 25, label: 'アコースティックギター' },
  { value: 26, label: 'ジャズギター' },
  { value: 29, label: 'オーバードライブギター' },
  { value: 30, label: 'ディストオーションギター' },
  { value: 32, label: 'アコースティックベース' },
  { value: 33, label: 'フィンガーベース' },
  { value: 34, label: 'ピックベース' },
  { value: 38, label: 'シンセベース' },
  { value: 0, label: 'ピアノ' },
  { value: 2, label: 'エレクトリックピアノ1' },
  { value: 4, label: 'エレクトリックピアノ2' },
  { value: 6, label: 'ハープシコード' },
  { value: 8, label: 'チェレスタ' },
  { value: 10, label: 'オルゴール' },
  { value: 18, label: 'ロックオルガン' },
  { value: 19, label: 'チャーチオルガン' },
  { value: 20, label: 'リードオルガン' },
  { value: 21, label: 'アコーディオン' },
  { value: 22, label: 'ハーモニカ' },
  { value: 48, label: 'ストリングス' },
  { value: 50, label: 'シンセストリングス' },
  { value: 61, label: 'ブラスセクション' },
  { value: 62, label: 'シンセブラス' },
  { value: 52, label: '声「アー」' },
  { value: 44, label: 'トレモロ' },
  { value: 45, label: 'ピッチカート' },
  { value: 46, label: 'ハープ' },
  { value: 11, label: 'ビブラフォン' },
  { value: 12, label: 'マリンバ' },
  { value: 14, label: 'チューブラーベル' },
  { value: 47, label: 'ティンパニ' },
  { value: 114, label: 'スチールドラム' },
  { value: 40, label: 'バイオリン' },
  { value: 42, label: 'チェロ' },
  { value: 43, label: 'コントラバス' },
  { value: 73, label: 'フルート' },
  { value: 68, label: 'オーボエ' },
  { value: 71, label: 'クラリネット' },
  { value: 70, label: 'ファゴット' },
  { value: 64, label: 'ソプラノサックス' },
  { value: 66, label: 'テナーサックス' },
  { value: 67, label: 'バリトンサックス' },
  { value: 56, label: 'トランペット' },
  { value: 57, label: 'トロンボーン' },
  { value: 60, label: 'ホルン' },
  { value: 58, label: 'チューバ' },
  { value: 78, label: '口笛' },
  { value: 80, label: '正方波' },
  { value: 81, label: 'ノコギリ波' },
];

export const WORKLIST_PROJECTS_PER_PAGE = 50;
export const PROJECT_AVAILABLE_MINUTES = 20;

export const ERROR_CODE_WHEN_API_IS_NOT_AVAILABLE = 999;

export const KNOWLEDGE_LIST = [
  {
    title: '自動作曲の歴史',
    content:
      'コンピューターを使った世界初の自動作曲は、1957年に作られた「イリアック組曲」だと言われています。米国イリノイ大学の研究者だったヒラーとアイザックソンによって開発されたプログラムにより生成されました。',
  },
  {
    title: 'コード進行',
    content:
      'ド・ミ・ソ（Cコード）やラ・ド・ミ（Aマイナーコード）などの音の組み合わせをコード（和音）といい、コードが連なったものをコード進行といいます。コード進行は伴奏の仕方を指定するもので、曲の特徴を決める重要な要素の一つです。「カノン進行」は最もよく使われるコード進行の一つで、17世紀のドイツの作曲家パッヘルベルの曲が名前の由来になっています。',
  },
  {
    title: '作曲理論',
    content:
      '多くの人が美しいと感じる音楽にはパターンがあり、このパターンを体系化したものが作曲理論です。オーストリアの作曲家フックスが1725年に出版した対位法の教本など、200年以上読み継がれている作曲理論の本もあります。現代では、統計データ解析により音楽に共通するパターンが調べられていて、自動作曲にも応用されています。',
  },
  {
    title: 'テンポの値',
    content:
      '音楽の速さであるテンポを表す値として、BPM値がよく使われます。BPM値は1分間でのビートの回数を表し、大きいほど速いテンポになります。ポピュラー音楽で使われるテンポの値は時代により変化します。ある調査では、日本のポピュラー音楽の平均BPM値は、1950年代には107でしたが、1980年代には126へと上昇し、2010年代は127でした。',
  },
  {
    title: '音階と調',
    content:
      '作曲に使う音のパレットを音階といい、長音階（ドレミファソラシ）や五音音階（ドレミソラ）などがあります。CREEVOでは作曲モデルごとに、データ分析によって得られた異なる音階が使われています。1オクターブの中には12個の半音があり、「調」はドに対応する音をどの半音から始めるかを表します。どの調を使うかにより、曲の色彩感や演奏のしやすさが変化します。',
  },
  {
    title: '日本語のアクセントとメロディー',
    content:
      '日本語には「^あ_め（雨）」と「あ^め（飴）」のような高低アクセントがあります。作曲家の山田耕筰は、日本語歌詞のこうしたアクセントやリズムに合わせたメロディーを用いる作曲法を提案しました。日本語の歌には、歌詞の高低アクセントとメロディーの高低パターンが一致しているものが多くあります。',
  },
  {
    title: 'AIってなに？',
    content:
      'AIは英語で「人工知能」を意味する Artificial Intelligence の略です。人間の知能を模倣するシステムのことを一般に意味します。近年の多くのAIは、データの分類や予測などをする計算法を自動的に構築する技術である「機械学習」を用いて実現されています。',
  },
];
