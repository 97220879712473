import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { CVHeadline } from '../../components/ui-parts/CVHeadline';
import { OldLoading } from '../../components/ui-parts/OldLoading';
import { ProjectDetail } from '../../components/ui-parts/ProjectDetail';
import { ConfirmProjectDeletion } from '../../modals/ConfirmProjectDeletion';
import { myListProjectDetailSelector } from '../../selectors/myListSelector';
import { userSelector } from '../../selectors/userSelector';
import { onFetchUserProjectDetail, onUpdateUserSelfRating, onDeleteUserProject } from '../../slices/myListSlice';
import { AppDispatch } from '../../store';

export const UserProject = () => {
  const navigation = useNavigate();
  const [selfRating, setSelfRating] = useState<number | null>(0);
  const [isConfirmProjectDeletionModalOpen, setConfirmProjectDeletionModalOpen] = useState(false);
  const { pid } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const projectDetail = useSelector(myListProjectDetailSelector);
  const userState = useSelector(userSelector);
  const { userInfo } = userState;
  const { isLoggedIn } = userState;
  const { isLoggedInLoading } = userState;

  const onDeleteProject = () => {
    void dispatch(
      onDeleteUserProject({
        projectId: Number(pid),
        userInfo,
      })
    );
    navigation('/user-projects/');
  };

  useEffect(() => {
    if (pid && !isLoggedInLoading && isLoggedIn) {
      void dispatch(
        onFetchUserProjectDetail({
          projectId: Number(pid),
          userInfo,
        })
      );
    }
  }, [pid, isLoggedInLoading, isLoggedIn]);

  useEffect(() => {
    if (selfRating !== null && userState.isLoggedIn) {
      void dispatch(
        onUpdateUserSelfRating({
          projectId: Number(pid),
          newRating: selfRating,
          userInfo,
        })
      );
    }
  }, [selfRating, userState]);

  useEffect(() => {
    if (!isLoggedInLoading && !userState.isLoggedIn) {
      navigation('/auth/login');
    }
  }, [isLoggedInLoading, isLoggedIn]);

  if (isLoggedInLoading) {
    return <OldLoading />;
  }

  return (
    <>
      <Box sx={{ marginTop: 4.25 }}>
        <CVHeadline size="h1">自分の作品</CVHeadline>
      </Box>
      {projectDetail !== null && (
        <>
          <ProjectDetail
            limited={false}
            projectDetail={projectDetail}
            selfRating={projectDetail.project.selfRating}
            updateRating={setSelfRating}
            projectKey={null}
            setConfirmProjectDeletionModalOpen={setConfirmProjectDeletionModalOpen}
          />
          <ConfirmProjectDeletion
            title={projectDetail.project.title}
            open={isConfirmProjectDeletionModalOpen}
            handleOpen={setConfirmProjectDeletionModalOpen}
            handleProjectDeletion={onDeleteProject}
          />
        </>
      )}
    </>
  );
};
