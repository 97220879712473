import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';

import { CVTypography } from '../components/ui-elements/CVTypography';
import { CVButton } from '../components/ui-parts/CVButton';
import { CVHeadline } from '../components/ui-parts/CVHeadline';
import { News } from '../components/ui-parts/News';
import { TwitterTimeline } from '../components/ui-parts/TwitterTimeline';
import homeDescAutoGenerate from '../images/home_desc_auto_generate.png';
import homeDescEnterLyrics from '../images/home_desc_enter_lyrics.png';
import homeDescSelectFavorite from '../images/home_desc_select_favorite.png';
import logoGreen from '../images/logo_green.png';
import { newsSelector } from '../selectors/newsSelector';
import { onFetchNews } from '../slices/newsSlice';
import { AppDispatch } from '../store';

export const Home = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const navigateToComposition = () => navigation('/composition/select-type');
  const { isNewsLoading, news } = useSelector(newsSelector);
  useEffect(() => {
    void dispatch(onFetchNews());
  }, []);
  return (
    <>
      <Box sx={{ marginTop: { xs: 0, sm: 15 } }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: { xs: 0, sm: 15 },
          }}
        >
          <Box component="img" src={logoGreen} width={428} height={1752} sx={{ maxWidth: '100%', height: 'auto' }} />
        </Box>
        <CVTypography size="h" sx={{ textAlign: 'center', color: 'primary.main' }}>
          進化する自動作曲システム
        </CVTypography>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 8.25 }}>
          <CVButton text="CREEVOで作曲する" onClick={navigateToComposition} size="ll" />
        </Box>
      </Box>
      <Box sx={{ marginTop: 15 }}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
          <CVHeadline size="h2">CREEVOの使い方</CVHeadline>
          <CVTypography size="c2" sx={{ marginLeft: 4.25, color: 'base.secondary' }}>
            使い方についての詳しい説明は<Link to="/usage/">こちら</Link>
            をご覧ください
          </CVTypography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: { xs: 'center', md: 'space-between' },
            marginTop: 8,
            marginLeft: '5%',
            marginRight: '5%',
          }}
        >
          <Box component="img" src={homeDescEnterLyrics} style={{ width: 200 }} />
          <Box component="img" src={homeDescAutoGenerate} style={{ width: 200 }} />
          <Box component="img" src={homeDescSelectFavorite} style={{ width: 200 }} />
        </Box>
      </Box>
      <Box sx={{ marginTop: 10 }}>
        <CVHeadline size="h2">作曲例</CVHeadline>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 4.5,
            marginLeft: '5%',
            marginRight: '5%',
          }}
        >
          <Box
            component="video"
            controls
            sx={{
              width: { lg: '48%', xs: '100%' },
              height: 'auto',
              marginBottom: 5,
            }}
            src="https://creevomusic.github.io/exp/example/CREEVO_ex10.mp4"
          />
          <Box
            component="video"
            controls
            sx={{
              width: { lg: '48%', xs: '100%' },
              height: 'auto',
              marginBottom: 5,
            }}
            src="https://creevomusic.github.io/exp/example/CREEVO_ex11.mp4"
          />
        </Box>
        <CVTypography size="c2" sx={{ marginLeft: 4.25, color: 'base.secondary' }}>
          歌声合成には
          <a href="https://sinsy.jp/" target="_blank" rel="noreferrer noopener nofollow">
            Sinsy
          </a>
          を使っています。
        </CVTypography>
      </Box>
      <Box sx={{ marginTop: 3.5 }}>
        <CVHeadline size="h2">CREEVO実験プロジェクト</CVHeadline>
        <Box sx={{ marginTop: 4.5 }}>
          <CVTypography size="h">
            CREEVO（クリーボ）は京都大学で行っている文化進化の研究のために、一般参加協力型の科学実験の場として運営しています。多くの利用者の評価をもとに新しい自動作曲（作曲AI）モデルが生まれ続ける実験です。無料で利用できますので、ご協力のほどよろしくお願いいたします。
          </CVTypography>
          <br />
          <CVTypography size="h">京都大学 白眉センター 中村栄太</CVTypography>
        </Box>
      </Box>
      <Box sx={{ marginTop: 3.5, marginBottom: 30 }}>
        <CVHeadline size="h2">お知らせ</CVHeadline>
        {!isNewsLoading && (
          <Box sx={{ marginTop: 4.5 }}>
            <News items={news} />
          </Box>
        )}
        <Box sx={{ marginTop: 6.25 }}>
          <TwitterTimeline />
        </Box>
      </Box>
    </>
  );
};
