import { CVTypography } from '../ui-elements/CVTypography';

interface Props {
  count: number;
}

export const BarCount = (props: Props) => {
  let color: string;
  if (props.count <= 1 || props.count >= 13) {
    color = 'base.error';
  } else {
    color = 'primary.main';
  }
  return (
    <CVTypography size="c1" sx={{ color }}>
      現在 {props.count} 小節
    </CVTypography>
  );
};
