import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import StarIcon from '@mui/icons-material/Star';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Rating } from '@mui/material';

import { Reference as UserReference } from '../../slices/myListSlice';
import { Reference as GeneralReference } from '../../slices/rankingSlice';
import { CVTableRow } from '../ui-elements/CVTableRow';
import { CVTypography } from '../ui-elements/CVTypography';

interface HeadCell {
  key: string;
  align: 'left' | 'right';
  disablePadding: boolean;
  label: string;
}

type Reference = GeneralReference | UserReference;

interface Props {
  type: 'general' | 'user';
  reference: Reference;
  setSelectedProject: (newState: number) => void;
}

export const ProjectTable = (props: Props) => {
  let headCells: readonly HeadCell[];
  if (props.type === 'general') {
    headCells = [
      {
        key: 'pid',
        align: 'left',
        disablePadding: false,
        label: 'PID',
      },
      {
        key: 'timeCreated',
        align: 'left',
        disablePadding: false,
        label: '作成時刻',
      },
      {
        key: 'rightArrow',
        align: 'right',
        disablePadding: false,
        label: '再生画面へ　',
      },
    ];
  } else {
    headCells = [
      {
        key: 'pid',
        align: 'left',
        disablePadding: false,
        label: 'PID',
      },
      {
        key: 'title',
        align: 'left',
        disablePadding: false,
        label: '曲名',
      },
      {
        key: 'rating',
        align: 'left',
        disablePadding: false,
        label: '評価',
      },
      {
        key: 'timeCreated',
        align: 'left',
        disablePadding: false,
        label: '作成日',
      },
      {
        key: 'rightArrow',
        align: 'right',
        disablePadding: false,
        label: '再生画面へ　',
      },
    ];
  }

  if (props.reference === undefined || props.reference.projects.length === 0) {
    return null;
  }

  return (
    <Paper>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.key}
                  align={headCell.align}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  sx={{ backgroundColor: 'base.footer' }}
                >
                  <CVTypography size="h" sx={{ color: 'primary.main' }}>
                    {headCell.label}
                  </CVTypography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.reference.projects.map((project) => (
              <CVTableRow
                onClick={() => {
                  props.setSelectedProject(project.projectId);
                }}
                hover
                key={project.projectId}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell>
                  <CVTypography size="h">{project.projectId}</CVTypography>
                </TableCell>
                {props.type === 'general' && (
                  <TableCell>
                    <CVTypography size="h">{project.timeCreated.replace('-', '　')}</CVTypography>
                  </TableCell>
                )}
                {props.type === 'user' && (
                  <>
                    <TableCell>
                      <CVTypography size="h">{'title' in project ? project.title : ''}</CVTypography>
                    </TableCell>
                    <TableCell align="center">
                      <Rating
                        name="self-rating"
                        max={10}
                        size="small"
                        value={'selfRating' in project ? project.selfRating : undefined}
                        readOnly
                        emptyIcon={<StarIcon fontSize="inherit" color="action" />}
                      />
                    </TableCell>
                    <TableCell>
                      <CVTypography size="h">{project.timeCreated.split('-')[0]}</CVTypography>
                    </TableCell>
                  </>
                )}
                <TableCell align="right">
                  <ArrowForwardIosIcon sx={{ width: 22 }} />
                </TableCell>
              </CVTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
