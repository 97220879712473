import { Box } from '@mui/material';
import { memo } from 'react';

import { KNOWLEDGE_LIST } from '../../constants';
import { useNavigateBlocker } from '../../hooks/useNavigateBlocker';
import { usePromptWhenReload } from '../../hooks/usePromptWhenReload';
import hugaLoading from '../../images/huga_loading.webp';
import { CVTypography } from '../ui-elements/CVTypography';

export const Loading = memo(() => {
  usePromptWhenReload();
  useNavigateBlocker({
    allowPaths: [],
    allowBack: true, // react-router-dom＠6.9.0 では戻る動作が不安定で正常にブロックできない。
  });
  const knowledge = KNOWLEDGE_LIST[Math.floor(Math.random() * KNOWLEDGE_LIST.length)];
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4.5 }}>
        <Box component="img" src={hugaLoading} sx={{ width: '25%' }} />
      </Box>
      <Box sx={{ textAlign: 'center', marginTop: 6 }}>
        <CVTypography size="b2">ファイルの生成中です…30秒から1分程度お待ちください</CVTypography>
        <CVTypography size="b2">数分経ってもページが切り替わらない場合は、</CVTypography>
        <CVTypography size="b2">恐れ入りますが、設定を変更して作曲し直してください</CVTypography>
      </Box>
      <Box
        sx={{
          backgroundColor: 'primary.light',
          marginTop: 4,
          paddingLeft: 2,
          paddingRight: 2,
          paddingTop: 1.25,
          paddingBottom: 1.25,
        }}
      >
        <CVTypography size="t3" sx={{ color: 'primary.main' }}>
          豆知識コーナー
        </CVTypography>
      </Box>
      <Box sx={{ marginTop: 3.5, marginLeft: 2, marginRight: 2, marginBottom: 50 }}>
        <CVTypography size="h">{knowledge.title}</CVTypography>
        <CVTypography size="b2" sx={{ marginTop: 1 }}>
          {knowledge.content}
        </CVTypography>
      </Box>
    </>
  );
});
