import { RootState } from '../store';

export const myListIsProjectLoadingSelector = (state: RootState) => state.myList.isProjectLoading;

export const myListReferencesSelector = (state: RootState) => state.myList.references;

export const myListIndexSelector = (state: RootState) => state.myList.index;

export const myListCurrentReferenceSelector = (state: RootState) => state.myList.references[state.myList.index];

export const myListProjectDetailSelector = (state: RootState) => state.myList.projectDetail;

export const myListErrorSelector = (state: RootState) => state.myList.errorMessage;
