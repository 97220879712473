import { Box } from '@mui/material';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { CVTypography } from '../../components/ui-elements/CVTypography';
import { CVHeadline } from '../../components/ui-parts/CVHeadline';
import { CVStep } from '../../components/ui-parts/CVStep';
import { Loading } from '../../components/ui-parts/Loading';
import { OldLoading } from '../../components/ui-parts/OldLoading';
import { ProjectDetail } from '../../components/ui-parts/ProjectDetail';
import { myListProjectDetailSelector } from '../../selectors/myListSelector';
import { compositionTypeSelector } from '../../selectors/projectSelector';
import { userSelector } from '../../selectors/userSelector';
import { onFetchUserProjectDetail, onUpdateUserSelfRating } from '../../slices/myListSlice';
import { projectSlice } from '../../slices/projectSlice';
import { onFetchProjectDetail, onUpdateSelfRating } from '../../slices/rankingSlice';
import { AppDispatch } from '../../store';
import { getHeadlineTextByCompType } from '../../utils/getHeadlineTextByCompType';

export const Completed = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams, _] = useSearchParams();
  const [selfRating, setSelfRating] = useState<number | null>(0);
  const userState = useSelector(userSelector);
  const projectDetail = useSelector(myListProjectDetailSelector);
  const compositionType = useSelector(compositionTypeSelector);
  const pid = searchParams.get('pid');
  const key = searchParams.get('project-key');

  const getProjectDetail = () => {
    if (userState.isLoggedInLoading) return;
    if (userState.isLoggedIn) {
      void dispatch(
        onFetchUserProjectDetail({
          projectId: Number(pid),
          userInfo: userState.userInfo,
        })
      );
    } else {
      void dispatch(
        onFetchProjectDetail({
          projectId: Number(pid),
          projectKey: key || '',
        })
      );
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      switch (projectDetail?.status) {
        case 'available':
          clearInterval(interval);
          break;
        case 'archived':
          clearInterval(interval);
          break;
        case 'generating':
          getProjectDetail();
          break;
        default:
          getProjectDetail();
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [projectDetail]);

  useEffect(() => {
    if (selfRating !== null) {
      if (userState.isLoggedIn) {
        void dispatch(
          onUpdateUserSelfRating({
            projectId: Number(pid),
            userInfo: userState.userInfo,
            newRating: selfRating,
          })
        );
      } else {
        void dispatch(
          onUpdateSelfRating({
            projectId: Number(pid),
            projectKey: key !== null ? key : '',
            newRating: selfRating,
          })
        );
      }
    }
  }, [selfRating]);

  useEffect(() => {
    if (projectDetail?.project.projectId !== Number(pid)) {
      getProjectDetail();
    }
    dispatch(projectSlice.actions.clearSavedProject());
  }, []);

  // リロード時にログイン処理の後にプロジェクトを取得する
  useEffect(() => {
    getProjectDetail();
  }, [userState]);

  if (userState.isLoggedInLoading) {
    return <OldLoading />;
  }
  if (!projectDetail || projectDetail.status === 'generating') {
    return <Loading />;
  }

  return (
    <>
      <Box>
        <CVHeadline size="h1">{getHeadlineTextByCompType(compositionType)}</CVHeadline>
      </Box>
      <CVStep activeStep={3} />
      {!userState.isLoggedIn && (
        <Box sx={{ marginTop: 5 }}>
          <CVTypography size="b1" sx={{ fontWeight: 500 }}>
            本ページのデータは、曲の作成時から20分後に閲覧できなくなります。
          </CVTypography>
          <CVTypography size="b1">
            データを保存する場合は、お早めにお手元のPCやスマホなどにダウンロードしてください。
          </CVTypography>
        </Box>
      )}
      <ProjectDetail
        limited={projectDetail.status === 'archived'}
        projectDetail={projectDetail}
        selfRating={projectDetail.project.selfRating}
        projectKey={key}
        updateRating={setSelfRating}
        setConfirmProjectDeletionModalOpen={undefined}
      />
    </>
  );
};
