import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { CVTypography } from '../components/ui-elements/CVTypography';
import { CVButton } from '../components/ui-parts/CVButton';
import hugaError from '../images/huga_error.png';

interface Props {
  errorCode: number;
  errorMsg: string;
}

export const Error = (props: Props) => {
  const navigation = useNavigate();
  const navigateToTop = () => navigation('/');
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 19.25 }}>
        <Box component="img" src={hugaError} sx={{ width: 130 }} />
      </Box>
      <Box>
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: 40,
            fontWeight: 500,
          }}
        >
          {props.errorCode}
        </Typography>
        <CVTypography
          size="t3"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          {props.errorMsg}
        </CVTypography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 7 }}>
        <CVButton text="TOPページへ" onClick={navigateToTop} size="ll" />
      </Box>
    </>
  );
};
