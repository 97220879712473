import { AxiosError } from 'axios';

import { ERROR_CODE_WHEN_API_IS_NOT_AVAILABLE } from '../constants';
import { UserInfo } from '../slices/userSlice';

import getCreevoClient from './CreevoClient';

export const generateMelody = async (
  title: string,
  username: string,
  chordProgressionId: number,
  modelParameterId: number,
  accompPatternId1: number,
  accompInstrumentId1: number,
  accompPatternId2: number,
  accompInstrumentId2: number,
  drumPatternId: number,
  lyrics: string,
  lyricsYomi: string,
  minPitch: number,
  maxPitch: number,
  key: number,
  beta: number,
  alpha: number,
  instrument: number,
  bpm: number,
  anacrusis: number,
  fixBar: string,
  numberSample: number
) => {
  const client = await getCreevoClient();
  return client.post('/project/generate-melody/', {
    title,
    username,
    chordProgressionId,
    modelParameterId,
    accompPatternId1,
    accompInstrumentId1,
    accompPatternId2,
    accompInstrumentId2,
    drumPatternId,
    lyrics,
    lyricsYomi,
    minPitch,
    maxPitch,
    key,
    beta,
    alpha,
    instrument,
    bpm,
    anacrusis,
    fixBar,
    numberSample,
  });
};

export const convertSelectedMelodyToAbcString = async (
  title: string,
  username: string,
  chordProgressionId: number,
  modelParameterId: number,
  accompPatternId1: number,
  accompInstrumentId1: number,
  accompPatternId2: number,
  accompInstrumentId2: number,
  drumPatternId: number,
  lyrics: string,
  lyricsYomi: string,
  key: number,
  instrument: number,
  bpm: number,
  fixBar: string
) => {
  const client = await getCreevoClient();
  return client.post('/project/play/', {
    title,
    username,
    chordProgressionId,
    modelParameterId,
    accompPatternId1,
    accompInstrumentId1,
    accompPatternId2,
    accompInstrumentId2,
    drumPatternId,
    lyrics,
    lyricsYomi,
    key,
    instrument,
    bpm,
    fixBar,
  });
};

export const convertLyrics = async (lyrics: string) => {
  const client = await getCreevoClient();
  return client.get('/project/convert-lyrics/', {
    params: {
      lyrics,
    },
  });
};

export const getChordProgressions = async () => {
  const client = await getCreevoClient();
  return client.get('/project/chord-progressions/');
};

export const getModelParameters = async () => {
  const client = await getCreevoClient();
  return client.get('/project/model-parameters/');
};

export const getAccompPatterns = async () => {
  const client = await getCreevoClient();
  return client.get('/project/accomp-patterns/');
};

export const getDrumPatterns = async () => {
  const client = await getCreevoClient();
  return client.get('/project/drum-patterns/');
};

export const saveProject = async (
  title: string,
  username: string,
  chordProgressionId: number,
  modelParameterId: number,
  accompPatternId1: number,
  accompInstrumentId1: number,
  accompPatternId2: number,
  accompInstrumentId2: number,
  drumPatternId: number,
  lyrics: string,
  lyricsYomi: string,
  key: number,
  instrument: number,
  bpm: number,
  fixBar: string,
  userInfo: UserInfo
) => {
  const client = await getCreevoClient();
  return client.post(
    '/project/save/',
    {
      title,
      username,
      chordProgressionId,
      modelParameterId,
      accompPatternId1,
      accompInstrumentId1,
      accompPatternId2,
      accompInstrumentId2,
      drumPatternId,
      lyrics,
      lyricsYomi,
      key,
      instrument,
      bpm,
      fixBar,
    },
    {
      headers:
        userInfo.idToken !== ''
          ? {
              'X-Authorization': `Bearer ${userInfo.idToken}`,
            }
          : undefined,
    }
  );
};

export function axiosErrorHandler(error: AxiosError) {
  if (error.response) {
    return error.response.status;
  }
  return ERROR_CODE_WHEN_API_IS_NOT_AVAILABLE;
}
