import { Box } from '@mui/material';

import { CVTypography } from '../ui-elements/CVTypography';

interface Props {
  isSelected: boolean;
  title: string;
  onClick: () => void;
}

export const HeaderMenu = (props: Props) => (
  <Box
    sx={{
      borderBottomColor: props.isSelected ? 'primary.main' : '#FFFFFF',
      borderBottomWidth: 2,
      borderBottomStyle: 'solid',
      width: 216,
      textAlign: 'center',
      paddingTop: 2,
      paddingBottom: 2,
      fontFamily: 'a-otf-ud-shin-maru-go-pr6n',
      fontStyle: 'normal',
      fontWeight: 300,
      cursor: 'pointer',
    }}
    onClick={props.onClick}
  >
    <CVTypography size="b2">{props.title}</CVTypography>
  </Box>
);
