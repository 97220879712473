import { Box, RadioGroup, FormControl } from '@mui/material';
import { useState, useEffect, useMemo } from 'react';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getTmpProject, TmpProjectResponse, selectTmpProjects } from '../../api/TmpProjectsApi';
import { CVTypography } from '../../components/ui-elements/CVTypography';
import { CVButton } from '../../components/ui-parts/CVButton';
import { CVHeadline } from '../../components/ui-parts/CVHeadline';
import { CVStep } from '../../components/ui-parts/CVStep';
import { Loading } from '../../components/ui-parts/Loading';
import { TmpProjCandidateCard } from '../../components/ui-parts/TmpProjCandidateCard';
import { useNavigateBlocker } from '../../hooks/useNavigateBlocker';
import { usePromptWhenReload } from '../../hooks/usePromptWhenReload';
import recraeteImage from '../../images/recreate.png';
import { lyricsSelector, savedProjectSelector } from '../../selectors/projectSelector';
import { userSelector } from '../../selectors/userSelector';
import { projectSlice, onRecreateTmpProjects } from '../../slices/projectSlice';
import { AppDispatch } from '../../store';

export const SelectFavorite = () => {
  usePromptWhenReload();
  const navigation = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [selectedCandidate, setSelectedCandidate] = useState<string>('0');
  const [tmpProjectsResponse, setTmpProjectsResponse] = useState<TmpProjectResponse | null>();
  const determinedLyrics = useSelector(lyricsSelector);
  const savedProject = useSelector(savedProjectSelector);
  const userState = useSelector(userSelector);
  const tmpProjectGroupId = savedProject.id !== null ? savedProject.id : 0;
  const tmpProjectGroupKey = savedProject.key !== null ? savedProject.key : '';

  const onChangeSelectedCandidate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCandidate(e.target.value);
  };

  const onClickPrevious = () => {
    dispatch(projectSlice.actions.clearSavedProject());
    navigation('/composition/input-lyrics-yomi');
  };

  const onClickRecreate = () => {
    void dispatch(
      onRecreateTmpProjects({
        tmpProjectGroupId,
        tmpProjectGroupKey,
      })
    );
    setSelectedCandidate('0'); // 選択肢も初期値に戻す
  };

  const onClickDetermine = () => {
    void (async (tmpProjectNumber: number) => {
      if (tmpProjectGroupKey) {
        const res = await selectTmpProjects(
          tmpProjectGroupId,
          tmpProjectGroupKey,
          tmpProjectNumber,
          userState.userInfo
        );
        const { projectId } = res.data;
        const { projectKey } = res.data;
        if (userState.isLoggedIn) {
          navigation(`/composition/completed?pid=${projectId}`);
        } else {
          navigation(`/composition/completed?pid=${projectId}&project-key=${projectKey}`);
        }
      }
    })(Number(selectedCandidate) - 1);
  };

  // ランダムにHugaの画像を変更するために、0から5までのランダムな数字を取得
  const randomImageTagProvider = useMemo(() => {
    const randomValue = Math.floor(Math.random() * 6);
    switch (randomValue) {
      case 0: {
        return ['p', 'g', 'y'];
      }
      case 1: {
        return ['g', 'p', 'y'];
      }
      case 2: {
        return ['y', 'g', 'p'];
      }
      case 3: {
        return ['p', 'y', 'g'];
      }
      case 4: {
        return ['y', 'p', 'g'];
      }
      case 5: {
        return ['g', 'y', 'p'];
      }
      default: {
        return ['p', 'g', 'y'];
      }
    }
  }, []);

  useEffect(() => {
    getTmpProject(tmpProjectGroupId, tmpProjectGroupKey)
      .then((response) => {
        setTmpProjectsResponse(response.data);
      })
      .catch((_err) => {
        setTmpProjectsResponse(null);
      });
  }, [tmpProjectGroupId, tmpProjectGroupKey]);

  useEffect(() => {
    // 曲の生成が終わるまで5秒ごとにリクエストを送って確認する
    if (tmpProjectsResponse?.status === 'generating') {
      getTmpProject(tmpProjectGroupId, tmpProjectGroupKey).then((response) => {
        setTimeout(() => setTmpProjectsResponse(response.data), 5 * 1000);
      }, null);
    }
  }, [tmpProjectsResponse, tmpProjectGroupId, tmpProjectGroupKey]);

  useNavigateBlocker({
    allowPaths: ['/composition/completed', '/composition/input-lyrics-yomi'],
  });

  if (tmpProjectsResponse?.status === 'generating' || !tmpProjectsResponse) {
    return <Loading />;
  }
  return (
    <>
      <Box>
        <CVHeadline size="h1">おまかせで作曲する</CVHeadline>
      </Box>
      <CVStep activeStep={2} />
      <Box sx={{ marginTop: 8 }}>
        <CVHeadline size="h2">作曲サンプルを選択する</CVHeadline>
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <CVTypography size="b2">
          <span style={{ textTransform: 'uppercase' }}>creevo</span>は利用者の選択により進化しますので、
          <b>候補の選択と確定ボタンのクリック</b>をぜひお願いいたします！
          <br />
          確定して進むと、<b>楽譜、動画などの全てのデータ</b>が表示され、<b>音声ファイルのダウンロード</b>もできます。
        </CVTypography>
      </Box>
      <Box
        sx={{
          backgroundColor: 'base.tertiary',
          padding: 2,
          marginTop: 5.5,
          marginLeft: 5,
          marginRight: 5,
          marginBottom: 4.5,
        }}
      >
        {determinedLyrics.split(/\n/).map((value) => (
          <CVTypography size="b2">{value}</CVTypography>
        ))}
      </Box>
      <Box>
        <CVTypography size="t3">候補の中から好きなものを一つ選択してください</CVTypography>
      </Box>
      <Box>
        <FormControl sx={{ width: '100%' }}>
          <RadioGroup
            value={selectedCandidate}
            onChange={onChangeSelectedCandidate}
            sx={{
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                flexWrap: 'wrap',
                width: '100%',
                marginY: 2,
              }}
            >
              {tmpProjectsResponse?.audioPaths?.map((path, index) => (
                <TmpProjCandidateCard audioPath={path} index={index} imageTag={randomImageTagProvider} />
              ))}
              <Box
                onClick={onClickRecreate}
                sx={{
                  position: 'relative',
                  aspectRatio: 1,
                  cursor: 'pointer',
                  marginBottom: '42px'
                }}
              >
                <Box component="img" src={recraeteImage} width={274} height={274} alt='他の候補を生成する' />
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: '0',
                    left: '0',
                    textAlign: 'center',
                    paddingBottom: "10%"
                  }}
                  width={274} height="auto"
                >
                  <CVTypography size="b2">他の候補を生成する</CVTypography>
                  <CVTypography size="c2">いまの候補には戻れません</CVTypography>
                </Box>
              </Box>
            </Box>
          </RadioGroup>
        </FormControl>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: 17,
          marginBottom: 30,
          marginLeft: '15%',
          marginRight: '15%',
        }}
      >
        <CVButton
          text="戻る"
          onClick={onClickPrevious}
          size="l"
          buttonColor="primary.light"
          sx={{ marginTop: 2, marginX: 'auto' }}
        />
        <CVButton
          text="確定する"
          onClick={onClickDetermine}
          size="l"
          buttonColor="primary.main"
          sx={{ marginTop: 2, marginX: 'auto' }}
          disabled={selectedCandidate === '0'}
        />
      </Box>
    </>
  );
};
