import { Box, Dialog, TextField, DialogContent } from '@mui/material';
import { useState } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { CVTypography } from '../components/ui-elements/CVTypography';
import { CVButton } from '../components/ui-parts/CVButton';
import { TITLE_MAX_LENGTH } from '../constants';
import { userIsLoggedInSelector } from '../selectors/userSelector';
import { userSlice } from '../slices/userSlice';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  defaultTitle: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  onSave: () => void;
}

const checkIfTitleIsValid = (title: string) => title.length > 0 && title.length <= TITLE_MAX_LENGTH;

const getHelperText = (title: string) =>
  title.length === 0
    ? 'タイトルが空です'
    : title.length > TITLE_MAX_LENGTH
    ? `タイトルは最大${TITLE_MAX_LENGTH}文字です`
    : null;

export const InputTitle = (props: Props) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(userIsLoggedInSelector);
  const [isSaving, setIsSaving] = useState(false);
  const [isTitleValid, setIsTitleValid] = useState(checkIfTitleIsValid(props.defaultTitle));
  const [titleHelperText, setTitleHelperText] = useState(getHelperText(props.defaultTitle));
  const onChangeTitle = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const title = e.target.value;
    props.setTitle(title);
    setIsTitleValid(checkIfTitleIsValid(title));
    setTitleHelperText(getHelperText(title));
  };
  const onClickSaveButton = () => {
    setIsSaving(true);
    props.onSave();
    props.setOpen(false);
    setIsSaving(false);
  };
  return (
    <Dialog open={props.open} onClose={() => props.setOpen(false)} maxWidth="md" fullWidth>
      <DialogContent>
        <CVTypography size="t2" sx={{ textAlign: 'center', marginBottom: 2.5 }}>
          タイトルを入力してください
        </CVTypography>
        <Box mt={0} mb={2}>
          <TextField
            variant="outlined"
            onChange={onChangeTitle}
            error={!isTitleValid}
            helperText={titleHelperText}
            defaultValue={props.defaultTitle}
            placeholder="入力してください"
            fullWidth
          />
        </Box>
        <Box>
          {!isLoggedIn && (
            <Box>
              <CVTypography size="b2" sx={{ textAlign: 'center' }}>
                GoogleやTwitterアカウントで
                <a onClick={() => dispatch(userSlice.actions.onClickLogIn())} style={{ cursor: 'pointer' }}>
                  ログイン
                </a>
                して作曲すると、
              </CVTypography>
              <CVTypography size="b2" sx={{ textAlign: 'center' }}>
                自分の作品をいつでも聴くことができます。
              </CVTypography>
            </Box>
          )}
          <CVTypography size="b2" sx={{ textAlign: 'center' }}>
            歌詞や歌声音声などは、作者自身のみに表示されます。
          </CVTypography>
          <CVTypography size="b2" sx={{ textAlign: 'center' }}>
            作品は著作権なしのデータとして扱われます。
          </CVTypography>
          <CVTypography size="b2" sx={{ textAlign: 'center' }}>
            <Link to="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
              利用規約を確認
            </Link>
          </CVTypography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 2,
            marginBottom: 4,
            marginLeft: '15%',
            marginRight: '15%',
          }}
        >
          <CVButton
            text="編集に戻る"
            onClick={() => props.setOpen(false)}
            size="l"
            buttonColor="primary.light"
            sx={{ marginTop: 2, marginX: 'auto' }}
          />
          <CVButton
            text="確定する"
            onClick={onClickSaveButton}
            size="l"
            buttonColor="primary.main"
            disabled={isSaving || !isTitleValid}
            sx={{ marginTop: 2, marginX: 'auto' }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
