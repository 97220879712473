import { assertIsDefined } from '../utils/Assert';

import { Note, Chord, Sample } from './index';

const UNSELECTED_INDEX = -1;

export class Candidate {
  readonly samples: Sample[];

  readonly chordSequence: Chord[][][];

  readonly fixBarIndices: number[][];

  constructor(melodies: Note[][][][], chordSequence: Chord[][][], sampleId: number);

  constructor(melodies: Note[][][][], chordSequence: Chord[][][], sampleId: number, candidate: Candidate);

  constructor(candidate: Candidate);

  constructor();

  constructor(
    arg: Note[][][][] | Candidate = [],
    chordSequence?: Chord[][][],
    sampleId?: number,
    candidate?: Candidate
  ) {
    if (arg instanceof Candidate) {
      this.samples = arg.samples;
      this.chordSequence = arg.chordSequence;
      this.fixBarIndices = arg.fixBarIndices;
    } else if (chordSequence === undefined) {
      this.samples = [];
      this.chordSequence = [];
      this.fixBarIndices = [];
    } else if (candidate !== undefined && !candidate.isEmpty()) {
      assertIsDefined(sampleId);
      this.samples = candidate.samples.concat(arg.map((melody, index) => new Sample(melody, sampleId, index)));
      this.chordSequence = candidate.chordSequence;
      this.fixBarIndices = candidate.fixBarIndices;
    } else {
      assertIsDefined(sampleId);
      this.samples = arg.map((melody, index) => new Sample(melody, sampleId, index));
      this.chordSequence = chordSequence;
      this.fixBarIndices = arg.length === 0 ? [] : arg[0].map((melody) => melody.map(() => UNSELECTED_INDEX));
      this.initializeFixBarIndices();
    }
  }

  isEmpty = () => this.samples.length === 0;

  isAnyBarFixed = (sampleIndex: number) =>
    this.fixBarIndices.some((phrase) => phrase.some((fixBarIndex) => fixBarIndex === sampleIndex));

  isAllBarFixed = () =>
    !this.isEmpty() &&
    this.fixBarIndices.every((phrase) => phrase.every((fixBarIndex) => fixBarIndex !== UNSELECTED_INDEX));

  getMelody = () =>
    this.fixBarIndices.map((phrase, phraseIndex) =>
      phrase.map((fixBarIndex, barIndex) =>
        fixBarIndex !== UNSELECTED_INDEX ? this.samples[fixBarIndex].melodies[phraseIndex][barIndex] : []
      )
    );

  getFixedIds = () =>
    this.fixBarIndices.map((phrase) =>
      phrase.map((fixedIndex) => {
        if (fixedIndex === UNSELECTED_INDEX) {
          return null;
        }
        const sample = this.samples[fixedIndex];
        return { configId: sample.configId, sampleId: sample.sampleId };
      })
    );

  getFixBarString = (padding = '-') =>
    this.getFixedIds()
      .flat()
      .map((ids) => (ids !== null ? `${ids.configId}_${ids.sampleId}` : padding))
      .join('\n');

  initializeFixBarIndices = () => {
    if (!this.fixBarIndices.every((phrase) => phrase.every((fixBarIndex) => fixBarIndex === UNSELECTED_INDEX))) {
      throw new Error('This instance has already modified');
    }
    this.fixBarIndices.forEach((phrase, phraseIndex) =>
      phrase.forEach((_, barIndex) => {
        this.toggleBarIsFixed(0, phraseIndex, barIndex);
      })
    );
  };

  toggleBarIsFixed = (sampleIndex: number, phraseIndex: number, barIndex: number) => {
    const isFixed = this.samples[sampleIndex].toggleBarIsFixed(phraseIndex, barIndex);
    if (isFixed) {
      const fixedSampleIndex = this.fixBarIndices[phraseIndex][barIndex];
      if (fixedSampleIndex !== UNSELECTED_INDEX) {
        this.samples[fixedSampleIndex].toggleBarIsFixed(phraseIndex, barIndex);
      }
      this.fixBarIndices[phraseIndex][barIndex] = sampleIndex;
    } else {
      this.fixBarIndices[phraseIndex][barIndex] = UNSELECTED_INDEX;
    }
  };

  setBarIsFixed = (sampleIndex: number, isFixed: boolean, phraseIndex?: number, barIndex?: number) => {
    if (barIndex !== undefined && phraseIndex === undefined) {
      throw new Error('Specify phraseIndex');
    }
    if (phraseIndex !== undefined) {
      if (barIndex !== undefined) {
        const fixedSampleIndex = this.fixBarIndices[phraseIndex][barIndex];
        if (fixedSampleIndex === sampleIndex && !isFixed) {
          this.samples[sampleIndex].toggleBarIsFixed(phraseIndex, barIndex);
          this.fixBarIndices[phraseIndex][barIndex] = UNSELECTED_INDEX;
        } else if (fixedSampleIndex !== sampleIndex && isFixed) {
          if (fixedSampleIndex !== UNSELECTED_INDEX) {
            this.samples[fixedSampleIndex].toggleBarIsFixed(phraseIndex, barIndex);
          }
          this.samples[sampleIndex].toggleBarIsFixed(phraseIndex, barIndex);
          this.fixBarIndices[phraseIndex][barIndex] = sampleIndex;
        }
      } else {
        for (let barIndex = 0; barIndex < this.fixBarIndices[phraseIndex].length; barIndex++) {
          this.setBarIsFixed(sampleIndex, isFixed, phraseIndex, barIndex);
        }
      }
    } else {
      for (let phraseIndex = 0; phraseIndex < this.fixBarIndices.length; phraseIndex++) {
        this.setBarIsFixed(sampleIndex, isFixed, phraseIndex);
      }
    }
  };

  removeSample = (sampleIndex: number) => {
    this.samples.splice(sampleIndex, 1);
    for (let phraseIndex = 0; phraseIndex < this.fixBarIndices.length; phraseIndex++) {
      for (let barIndex = 0; barIndex < this.fixBarIndices[phraseIndex].length; barIndex++) {
        const fixedIndex = this.fixBarIndices[phraseIndex][barIndex];
        if (fixedIndex === sampleIndex) {
          this.fixBarIndices[phraseIndex][barIndex] = UNSELECTED_INDEX;
        } else if (fixedIndex > sampleIndex) {
          this.fixBarIndices[phraseIndex][barIndex] = fixedIndex - 1;
        }
      }
    }
  };

  copy = () => new Candidate(this);
}
