import { RootState } from '../store';

export const projectSelector = (state: RootState) => state.project;

export const compositionTypeSelector = (state: RootState) => state.project.compositionType;

export const lyricsSelector = (state: RootState) => state.project.lyrics;

export const lyricsYomiSelector = (state: RootState) => state.project.lyricsYomi;

export const chordProgressionsSelector = (state: RootState) => state.project.chordProgressions;

export const modelParametersSelector = (state: RootState) => state.project.modelParameters;

export const accompPatternsSelector = (state: RootState) => state.project.accompPatterns;

export const drumPatternsSelector = (state: RootState) => state.project.drumPatterns;

export const nBarSelector = (state: RootState) => state.project.nBar;

export const candidateSelector = (state: RootState) => state.project.candidate;

export const savedProjectSelector = (state: RootState) => state.project.savedProject;

export const isGeneratingSelector = (state: RootState) => state.project.isGeneratingMelody;

export const abcStringSelector = (state: RootState) => state.project.abcStringForAudition;

export const isConvertingMelodyToAbcStringSelector = (state: RootState) => state.project.isConvertingMelodyToAbcString;

export const errorSelector = (state: RootState) => state.project.errorMessage;

export const isSavingProjectSelector = (state: RootState) => state.project.isSavingProject;
