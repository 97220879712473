import { Box, FormControlLabel, Radio } from '@mui/material';
import { useRef, useState } from 'react';
import ReactAudioPlayer from 'react-audio-player';

import audioPause from '../../images/audio_pause.svg';
import audioStart from '../../images/audio_start.svg';
import hugaDancingGreen from '../../images/huga_dancing_green.gif';
import hugaDancingPink from '../../images/huga_dancing_pink.gif';
import hugaDancingYellow from '../../images/huga_dancing_yellow.gif';
import hugaStoppingGreen from '../../images/huga_stopping_green.png';
import hugaStoppingPink from '../../images/huga_stopping_pink.png';
import hugaStoppingYellow from '../../images/huga_stopping_yellow.png';

interface Props {
  audioPath: string;
  index: number;
  imageTag: string[];
}

export const TmpProjCandidateCard = (props: Props) => {
  const [isPlaying, setPlaying] = useState(false);
  const playerElement = useRef<ReactAudioPlayer>(null);
  const onStartPlaying = () => {
    void playerElement.current?.audioEl.current?.play();
  };

  const onPausePlaying = () => {
    playerElement.current?.audioEl.current?.pause();
  };

  const onClickImage = () => {
    if (isPlaying) {
      onPausePlaying();
    } else {
      onStartPlaying();
    }
  };

  const imageProvider = () => {
    switch (props.imageTag[props.index]) {
      case 'p': {
        return isPlaying ? hugaDancingPink : hugaStoppingPink;
      }
      case 'g': {
        return isPlaying ? hugaDancingGreen : hugaStoppingGreen;
      }
      case 'y': {
        return isPlaying ? hugaDancingYellow : hugaStoppingYellow;
      }
      default: {
        return undefined;
      }
    }
  };

  return (
    <Box>
      <ReactAudioPlayer
        controls={false}
        src={props.audioPath}
        ref={playerElement}
        onPlay={() => setPlaying(true)}
        onPause={() => setPlaying(false)}
        onEnded={() => setPlaying(false)}
      />
      {isPlaying ? (
        // 再生中の表示 : ポーズボタンを表示
        <Box
          onClick={onClickImage}
          sx={{
            position: 'relative',
            aspectRatio: 1,
            cursor: 'pointer',
          }}
        >
          <Box component="img" src={imageProvider()} width={274} height={274} />
          <Box
            component="img"
            src={audioPause}
            width={44}
            height={61}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        </Box>
      ) : (
        // 再生停止中の表示 : 再生▷ボタンを表示
        <Box
          onClick={onClickImage}
          className="clickimage"
          sx={{
            position: 'relative',
            aspectRatio: 1,
            cursor: 'pointer',
          }}
        >
          <Box component="img" src={imageProvider()} width={274} height={274} />
          <Box
            component="img"
            src={audioStart}
            width={56}
            height={77}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: 'calc(100% / 274 * 56)',
              Height: 'calc(100% / 274 * 77)',
              transform: 'translate(-50%, -50%)',
            }}
          />
        </Box>
      )}
      <FormControlLabel
        value={(props.index + 1).toString()}
        control={<Radio color="primary" />}
        sx={{ display: 'flex', justifyContent: 'center' }}
        label={`候補${(props.index + 1).toString()}`}
      />
    </Box>
  );
};
