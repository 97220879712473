import { Project, ProjectMetaData } from '../models';

import getCreevoClient from './CreevoClient';

export interface ProjectDetail {
  project: Project;
  scoreImagePath: string;
  scorePdfPath: string;
  scoreXmlPath: string;
  scoreMsczPath: string;
  audioPath: string;
  audioInstrPath: string;
  videoPath: string;
  zipPath: string;
  isValidKey: boolean;
  status: 'generating' | 'available' | 'archived';
}

export interface ProjectList {
  projects: ProjectMetaData[];
  hasNext: boolean;
}

export const getProjects = async (perPage: number, sinceId: number | null) => {
  const client = await getCreevoClient();
  return client.get<ProjectList>(
    sinceId === null
      ? `/ranking/projects/?perPage=${perPage}`
      : `/ranking/projects/?perPage=${perPage}&sinceId=${sinceId}`
  );
};

export const getProjectDetail = async (projectId: number, projectKey: string | null) => {
  const client = await getCreevoClient();
  return client.get<ProjectDetail>(
    projectKey ? `/ranking/projects/${projectId}/?projectKey=${projectKey}` : `/ranking/projects/${projectId}/`
  );
};

export const putSelfRating = async (projectId: number, selfRating: number, projectKey: string) => {
  const data = {
    projectId,
    projectKey,
    selfRating,
  };
  const client = await getCreevoClient();
  return client.put<null>(`/ranking/projects/self-rating/`, data);
};

export const putPublicationAgreement = async (projectId: number, publicationAgreed: boolean, projectKey: string) => {
  const data = {
    projectId,
    projectKey,
    publicationAgreed,
  };
  const client = await getCreevoClient();
  return client.put<null>(`/ranking/projects/publication-agreement/`, data);
};
