import {
  AppBar,
  Avatar,
  Toolbar,
  Box,
  Button,
  IconButton,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Link,
} from '@mui/material';
import { useState, MouseEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';

import logoMonoImage from '../../images/logo_mono.png';
import menuCross from '../../images/menu_cross.svg';
import menuHamburger from '../../images/menu_hamburger.svg';
import { userSelector } from '../../selectors/userSelector';
import { onLogOut } from '../../slices/userSlice';
import { AppDispatch } from '../../store';
import { CVTypography } from '../ui-elements/CVTypography';
import { IconMenu } from '../ui-elements/IconMenu';

import { HeaderMenu } from './HeaderMenu';

export const Header = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const userState = useSelector(userSelector);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [selectedPage, _] = useState<{
    title: string;
    link: string;
  }>({ title: '', link: '/' });
  const [iconMenuAnchorEl, setIconMenuAnchorEl] = useState<null | HTMLElement>(null);

  const onClickHeaderMenu = (newPage: { title: string; link: string }) => {
    navigation(newPage.link);
  };

  const toggleIsMenuOpen = () => {
    setIsMenuOpen((isMenuOpen) => !isMenuOpen);
  };

  const onClickIcon = (event: MouseEvent<HTMLButtonElement>) => {
    setIconMenuAnchorEl(event.currentTarget);
  };

  const onClickLogOut = () => {
    void dispatch(onLogOut());
  };

  const pages = [
    {
      title: '曲を作る',
      link: '/composition/select-type',
    },
    {
      title: 'みんなの作品',
      link: '/projects',
    },
    {
      title: '自分の作品',
      link: '/user-projects',
    },
    {
      title: 'CREEVOについて',
      link: '/about',
    },
    {
      title: '使いかた',
      link: '/usage',
    },
  ];
  return (
    <>
      <AppBar position="static" color="primary">
        <Toolbar sx={{ justifyContent: 'space-between', paddingX: { xs: 1, sm: 2 } }}>
          <Box
            component="img"
            src={logoMonoImage}
            alt="CREEVO"
            width={136.28}
            height={48.234}
            sx={{ width: 163.84, height: 'auto', cursor: 'pointer' }}
            onClick={() => navigation('/')}
          />
          {!userState.isLoggedIn ? (
            <Link
              component={RouterLink}
              to="/auth/login"
              sx={{
                textDecoration: 'none',
                backgroundColor: 'primary.light',
                cursor: 'pointer',
                paddingTop: 0.625,
                paddingBottom: 0.625,
                paddingLeft: 1.25,
                paddingRight: 1.25,
                borderRadius: 1,
                transform: { xs: 'scale(0.75)', sm: 'none' },
              }}
            >
              <CVTypography size="h" sx={{ color: 'primary.main' }}>
                ログインする
              </CVTypography>
            </Link>
          ) : (
            <>
              <Button onClick={onClickIcon}>
                <Avatar src={userState.userInfo.avatar} sx={{ marginRight: 9, cursor: 'pointer' }} />
              </Button>
              <IconMenu
                onLogOut={onClickLogOut}
                onNavigate={() => navigation('/user-projects')}
                iconMenuAnchorEl={iconMenuAnchorEl}
                setIconMenuAnchorEl={setIconMenuAnchorEl}
              />
            </>
          )}
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          display: { xs: 'none', sm: 'flex' },
          flexDirection: 'row',
          justifyContent: 'center',
          width: '100%',
          marginBottom: 4.25,
        }}
      >
        {pages.map((value) => (
          <HeaderMenu
            key={value.title}
            isSelected={location.pathname.split('/')[1] === value.link.split('/')[1]}
            title={value.title}
            onClick={() => onClickHeaderMenu(value)}
          />
        ))}
      </Box>
      <Box
        sx={{
          display: { xs: 'flex', sm: 'none' },
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginX: 1,
          marginTop: 1,
          marginBottom: 2,
        }}
      >
        <IconButton
          aria-label="open-menu"
          onClick={toggleIsMenuOpen}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '5px',
            padding: 1,
          }}
        >
          <img src={menuHamburger} alt="" style={{ width: 21, height: 14 }} />
          <CVTypography size="c3">menu</CVTypography>
        </IconButton>
      </Box>

      <Drawer anchor="right" open={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
        <Box
          sx={{
            width: 240,
            cursor: 'pointer',
          }}
          onClick={() => setIsMenuOpen(false)}
        >
          <img src={menuCross} alt="" style={{ width: 16, height: 16, padding: 24 }} />
        </Box>
        <List
          sx={{
            width: 240,
          }}
        >
          {pages.map((page) => (
            <ListItemButton key={page.title} selected={selectedPage.link === page.link}>
              <ListItemText
                primary={page.title}
                onClick={() => {
                  onClickHeaderMenu(page);
                  setIsMenuOpen(false);
                }}
              />
            </ListItemButton>
          ))}
        </List>
      </Drawer>
    </>
  );
};
