import { MELODY_UNIT } from '../../../constants';
import { Note, Chord, Bar } from '../../../models';
import { range } from '../../../utils';
import { assertIsDefined } from '../../../utils/Assert';
import '../../../utils/array.extensions';

const chordOffset = 6;
const chordMargin = 1;
const melodyBlockHeight = 15;
const melodyBlockWidth = 80;
const melodyLyricsHeight = 4;
const noteWidthUnit = melodyBlockWidth / MELODY_UNIT;

export const MelodyType = {
  Main: {
    heightScale: 4,
    widthScale: 4,
    fontScale: 3,
  },
  Candidate: {
    heightScale: 1.5,
    widthScale: 1,
    fontScale: 1,
  },
} as const;
type TMelodyType = typeof MelodyType[keyof typeof MelodyType];

type MelodyViewProps = {
  melody: Note[][][];
  chordSequence: Chord[][][];
  playedNote: number;
  barsList?: Bar[][];
  melodyType: TMelodyType;
  onClickBar?: (phraseIndex: number, barIndex: number) => void;
  onMouseEnterBar?: (phraseIndex: number, barIndex: number) => void;
  onMouseLeaveBar?: (phraseIndex: number, barIndex: number) => void;
  mousePhraseIndex?: number;
  mouseBarIndex?: number;
};

type NoteProps = {
  note: Note;
  maxPitch: number;
  noteHeight: number;
  isPlayed: boolean;
  melodyType: TMelodyType;
};

type ChordsProps = {
  chords: Chord[];
};

type PhraseProps = {
  phrase: Note[][];
  chordSequence: Chord[][];
  bars: Bar[];
  playedNote: number;
  globalNoteIndexOffset: number;
  maxPitch: number;
  noteHeight: number;
  melodyType: TMelodyType;
  lastNote: Note | null;
  onClickBar: (barIndex: number) => void;
  onMouseEnterBar: (barIndex: number) => void;
  onMouseLeaveBar: (barIndex: number) => void;
  isHoveredBar: (barIndex: number) => boolean;
};

type BarProps = {
  bar: Bar;
  notes: Note[];
  chords: Chord[];
  melodyType: TMelodyType;
  playedNote: number;
  globalNoteIndexOffset: number;
  lastNote: Note | null;
  previousBarIsEmpty: boolean;
  maxPitch: number;
  noteHeight: number;
  isHovered: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
};

type NotesProps = {
  notes: Note[];
  playedNote: number;
  globalNoteIndexOffset: number;
  previousBarIsEmpty: boolean;
  maxPitch: number;
  noteHeight: number;
  melodyType: TMelodyType;
  lastNote: Note | null;
};

class PitchInfo {
  constructor(public min: number, public max: number) {}

  get range(): number {
    return this.max - this.min + 1;
  }
}

const getPitchInfo = (melody: Note[][][]) => {
  const reduce = (func: (...values: number[]) => number) =>
    func(
      ...melody.map((phrase: Note[][]) =>
        func(...phrase.map((bar: Note[]) => func(...bar.map((note: Note) => note.pitch))))
      )
    );
  return new PitchInfo(reduce(Math.min), reduce(Math.max));
};

const NoteView = (props: NoteProps) => {
  const { note } = props;
  const x = note.onset * noteWidthUnit * props.melodyType.widthScale;
  const color = props.melodyType === MelodyType.Main && props.isPlayed ? '#1890FF' : 'black';
  const noteViewStyle = {
    x,
    y: (chordOffset - (note.pitch - props.maxPitch) * props.noteHeight) * props.melodyType.heightScale,
    width: (note.offset - note.onset) * noteWidthUnit * props.melodyType.widthScale,
    height: props.noteHeight * props.melodyType.heightScale,
  };
  const lyricsViewStyle = {
    x,
    y: (chordOffset + melodyBlockHeight + melodyLyricsHeight) * props.melodyType.heightScale,
    fontSize: melodyLyricsHeight * props.melodyType.fontScale,
  };
  return (
    <g fill={color}>
      <rect {...noteViewStyle} />
      {props.melodyType === MelodyType.Main && <text {...lyricsViewStyle}>{note.lyrics}</text>}
    </g>
  );
};

const NotesView = (props: NotesProps) => {
  let globalNoteIndex = props.globalNoteIndexOffset;
  const hideNote = (note: Note, noteIndex: number) => note.isTied && noteIndex === 0 && props.previousBarIsEmpty;
  return (
    <g>
      {props.notes.map((note, index) => {
        if (hideNote(note, index)) {
          return null;
        }
        if (note.isTied) {
          assertIsDefined(props.lastNote);
          note.pitch = props.lastNote.pitch;
        } else {
          globalNoteIndex++;
        }
        return (
          <NoteView
            key={index}
            note={note}
            maxPitch={props.maxPitch}
            noteHeight={props.noteHeight}
            isPlayed={globalNoteIndex <= props.playedNote}
            melodyType={props.melodyType}
          />
        );
      })}
    </g>
  );
};

const ChordsView = (props: ChordsProps) => (
  <g>
    {props.chords.map((chord, index) => (
      <text
        key={index}
        x={chord.onset * noteWidthUnit * MelodyType.Main.widthScale}
        y={(chordOffset - chordMargin) * MelodyType.Main.heightScale}
        fontSize={chordOffset * MelodyType.Main.fontScale}
        textAnchor="start"
      >
        {chord.chord}
      </text>
    ))}
  </g>
);

const BarView = (props: BarProps) => {
  const barStyle = {
    y: chordOffset * props.melodyType.heightScale,
    height: melodyBlockHeight * props.melodyType.heightScale,
    width: melodyBlockWidth * props.melodyType.widthScale,
    pointerEvents: 'visible',
  };
  const barFrameStyle = {
    y: chordOffset * props.melodyType.heightScale,
    height: melodyBlockHeight * props.melodyType.heightScale,
    width: melodyBlockWidth * props.melodyType.widthScale,
    stroke: 'black',
    strokeWidth: 2,
    fill: 'none',
  };
  return (
    <g>
      {/* Background color */}
      <rect
        key="background"
        fill={props.isHovered ? '#efffba' : props.bar.isFixed ? '#99ceff' : 'none'}
        {...barStyle}
      />
      <rect {...barFrameStyle} />
      <NotesView
        notes={props.notes}
        playedNote={props.playedNote}
        globalNoteIndexOffset={props.globalNoteIndexOffset}
        lastNote={props.lastNote}
        previousBarIsEmpty={props.previousBarIsEmpty}
        maxPitch={props.maxPitch}
        noteHeight={props.noteHeight}
        melodyType={props.melodyType}
      />
      {props.melodyType === MelodyType.Main && <ChordsView chords={props.chords} />}
      {/* Transparent films to capture the click and hover events */}
      <rect
        key="front-transparent-film"
        fill="none"
        onClick={props.onClick}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        {...barStyle}
      />
    </g>
  );
};

const PhraseView = (props: PhraseProps) => {
  const { length } = props.phrase;
  const phraseLayoutStyle = {
    height: (chordOffset + melodyBlockHeight + melodyLyricsHeight + 1.5) * props.melodyType.heightScale,
    style: {
      margin: '0px 0px',
      backgroundColor: '#fff',
    },
  };
  const previousBarIsEmpty = (index: number) =>
    (index === 0 && props.lastNote === null) || (index > 0 && props.phrase[index - 1].isEmpty());
  let { globalNoteIndexOffset } = props;
  return (
    <div>
      <svg width={melodyBlockWidth * length * props.melodyType.widthScale} {...phraseLayoutStyle}>
        {[...range(length)].map((index) => {
          const translateX = index * melodyBlockWidth * props.melodyType.widthScale;
          const notes = props.phrase[index];
          const lastNoteOfPreviousBar = index === 0 ? undefined : props.phrase[index - 1].last();
          const lastNote = index === 0 ? props.lastNote : lastNoteOfPreviousBar || null;
          const bar = (
            <g key={index} transform={`translate(${translateX}, 0)`}>
              <BarView
                bar={props.bars[index]}
                notes={notes}
                chords={props.chordSequence[index]}
                melodyType={props.melodyType}
                playedNote={props.playedNote}
                globalNoteIndexOffset={globalNoteIndexOffset}
                lastNote={lastNote}
                previousBarIsEmpty={previousBarIsEmpty(index)}
                maxPitch={props.maxPitch}
                noteHeight={props.noteHeight}
                isHovered={props.isHoveredBar(index)}
                onClick={() => props.onClickBar(index)}
                onMouseEnter={() => props.onMouseEnterBar(index)}
                onMouseLeave={() => props.onMouseLeaveBar(index)}
              />
            </g>
          );
          globalNoteIndexOffset += notes.length - (notes.length > 0 && notes[0].isTied ? 1 : 0);
          return bar;
        })}
      </svg>
    </div>
  );
};

export const MelodyView = (props: MelodyViewProps) => {
  let { melody, chordSequence, barsList = [] } = props;
  if (barsList.isEmpty()) {
    barsList = melody.map((value) =>
      value.map(() => ({
        isFixed: false,
      }))
    );
  }
  let indexMapping = barsList.map((bars, phraseIndex) =>
    bars.map((_, barIndex) => ({
      phraseIndex,
      barIndex,
    }))
  );
  if (props.melodyType === MelodyType.Candidate) {
    melody = [melody.flat()];
    chordSequence = [chordSequence.flat()];
    barsList = [barsList.flat()];
    indexMapping = [indexMapping.flat()];
  }
  const pitchInfo = getPitchInfo(melody);
  let globalNoteIndexOffset = 0;
  return (
    <div style={{ display: 'inline-block' }}>
      {[...range(melody.length)].map((index) => {
        const lastBarOfPreviousPhrase = index === 0 ? undefined : melody[index - 1].last();
        const lastBarOfPreviousPhraseIsEmpty =
          lastBarOfPreviousPhrase === undefined || lastBarOfPreviousPhrase.isEmpty();
        const map = indexMapping[index];
        const result = (
          <PhraseView
            key={index}
            phrase={melody[index]}
            chordSequence={chordSequence[index]}
            playedNote={props.playedNote}
            bars={barsList[index]}
            globalNoteIndexOffset={globalNoteIndexOffset}
            maxPitch={pitchInfo.max}
            noteHeight={melodyBlockHeight / pitchInfo.range}
            melodyType={props.melodyType}
            lastNote={lastBarOfPreviousPhraseIsEmpty ? null : lastBarOfPreviousPhrase.last() || null}
            onClickBar={(barIndex: number) => {
              props.onClickBar?.(map[barIndex].phraseIndex, map[barIndex].barIndex);
            }}
            onMouseEnterBar={(barIndex: number) => {
              props.onMouseEnterBar?.(map[barIndex].phraseIndex, map[barIndex].barIndex);
            }}
            onMouseLeaveBar={(barIndex: number) => {
              props.onMouseLeaveBar?.(map[barIndex].phraseIndex, map[barIndex].barIndex);
            }}
            isHoveredBar={(barIndex: number) => index === props.mousePhraseIndex && barIndex === props.mouseBarIndex}
          />
        );
        melody[index].forEach((bar) => {
          bar.forEach((note) => {
            if (!note.isTied) {
              globalNoteIndexOffset++;
            }
          });
        });
        return result;
      })}
    </div>
  );
};
