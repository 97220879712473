import ErrorIcon from '@mui/icons-material/Error';
import { Alert, Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { redirect, useLocation, useNavigate } from 'react-router';

import { CVTypography } from '../../components/ui-elements/CVTypography';
import { CVButton } from '../../components/ui-parts/CVButton';
import { CVHeadline } from '../../components/ui-parts/CVHeadline';
import { ProjectTable } from '../../components/ui-parts/ProjectTable';
import {
  myListCurrentReferenceSelector,
  myListIndexSelector,
  myListIsProjectLoadingSelector,
  myListReferencesSelector,
} from '../../selectors/myListSelector';
import { userSelector } from '../../selectors/userSelector';
import { onFetchUserProjects, myListSlice } from '../../slices/myListSlice';
import { onRefreshIdToken } from '../../slices/userSlice';
import { AppDispatch } from '../../store';

const useQuery = () => new URLSearchParams(useLocation().search);

export const UserProjectList = () => {
  const pageStr = useQuery().get('page');
  const [shouldRedirectToLogin, setRedirectToLogin] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigation = useNavigate();
  const references = useSelector(myListReferencesSelector);
  const index = useSelector(myListIndexSelector);
  const isProjectLoading = useSelector(myListIsProjectLoadingSelector);
  const userState = useSelector(userSelector);
  const currentReference = useSelector(myListCurrentReferenceSelector);

  const onClickNext = async () => {
    if (references[index + 1] === undefined) {
      await dispatch(onRefreshIdToken());
      void dispatch(
        onFetchUserProjects({
          sinceId: currentReference.sinceId,
          uid: userState.userInfo.uid,
          idToken: userState.userInfo.idToken,
        })
      );
    } else {
      dispatch(myListSlice.actions.incrementIndex());
    }
    navigation(`/mylist?page=${index + 2}`);
  };

  const onClickPrevious = () => {
    dispatch(myListSlice.actions.decrementIndex());
    navigation(`/mylist?page=${index}`);
  };

  const setSelectedProject = (projectId: number) => {
    navigation(`/user-projects/${projectId}`);
  };

  useEffect(() => {
    void (async () => {
      if (userState.isLoggedInLoading) return;
      if (userState.isLoggedIn) {
        const page = pageStr !== null ? parseInt(pageStr, 10) : null;
        if (page === null || page > references.length) {
          dispatch(myListSlice.actions.cleanAll());
          await dispatch(onRefreshIdToken());
          void dispatch(
            onFetchUserProjects({
              sinceId: null,
              uid: userState.userInfo.uid,
              idToken: userState.userInfo.idToken,
            })
          );
          navigation(`/user-projects`, { replace: true });
        } else {
          dispatch(myListSlice.actions.decrementIndex());
        }
      } else {
        setRedirectToLogin(true);
      }
    })();
  }, [userState.userInfo.uid]);

  if (userState.isLoggedInLoading || isProjectLoading) {
    return null;
  }

  if (shouldRedirectToLogin) {
    redirect('/login');
    // state: { from: location },
  }
  return (
    <>
      <Box sx={{ marginTop: 4.25 }}>
        <CVHeadline size="h1">自分の作品</CVHeadline>
      </Box>
      {userState.isLoggedIn && references[0]?.projects.length > 0 ? (
        <>
          <CVTypography size="c1">クリックで作品の詳細ページへ移動します</CVTypography>
          <br />
          <ProjectTable reference={currentReference} setSelectedProject={setSelectedProject} type="user" />
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 17,
              marginBottom: 30,
              marginLeft: '15%',
              marginRight: '15%',
            }}
          >
            <CVButton
              text="前へ"
              onClick={onClickPrevious}
              size="l"
              buttonColor={index !== 0 ? 'primary.light' : 'base.disabled'}
              sx={{ marginTop: 2, marginX: 'auto' }}
              disabled={index === 0}
            />
            <CVButton
              text="次へ"
              onClick={onClickNext}
              size="l"
              buttonColor={currentReference.hasNext ? 'primary.main' : 'base.disabled'}
              sx={{ marginTop: 2, marginX: 'auto' }}
              disabled={!currentReference.hasNext}
            />
          </Box>
        </>
      ) : (
        <Box>
          <Alert icon={<ErrorIcon fontSize="large" />} variant="outlined" severity="error">
            <Typography variant="subtitle1" style={{ color: '#990000' }}>
              作品は登録されていません。
              <strong>ログイン</strong>
              して作曲すると、いつでも自分の作品を閲覧することができます。
            </Typography>
          </Alert>
        </Box>
      )}
    </>
  );
};
