import { Modal, Box, Paper } from '@mui/material';

import { CVTypography } from '../components/ui-elements/CVTypography';
import { CVButton } from '../components/ui-parts/CVButton';

interface Props {
  title: string;
  open: boolean;
  handleOpen: (newState: boolean) => void;
  handleProjectDeletion: () => void;
}

export const ConfirmProjectDeletion = (props: Props) => {
  const onClose = () => props.handleOpen(false);
  const onConfirmProjectDeletion = () => {
    props.handleOpen(false);
    props.handleProjectDeletion();
  };

  return (
    <Modal open={props.open} onClose={onClose}>
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Box sx={{ margin: 10 }}>
          <CVTypography size="t3">「{props.title}」を本当に削除しますか？</CVTypography>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 9,
              marginLeft: '15%',
              marginRight: '15%',
            }}
          >
            <CVButton
              onClick={onClose}
              text="キャンセル"
              size="m"
              buttonColor="primary.light"
              sx={{ marginTop: 2, marginX: 'auto' }}
            />
            <CVButton
              onClick={onConfirmProjectDeletion}
              text="削除する"
              size="m"
              buttonColor="base.error"
              sx={{ marginTop: 2, marginX: 'auto' }}
            />
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};
