import { Box } from '@mui/material';

import { CVTypography } from '../components/ui-elements/CVTypography';
import { CVHeadline } from '../components/ui-parts/CVHeadline';

export const TermsAndConditions = () => (
  <>
    <Box sx={{ marginTop: 4.375 }}>
      <CVHeadline size="h1">自動作曲システム CREEVO 利用規約</CVHeadline>
    </Box>
    <Box
      sx={{
        backgroundColor: 'bg.secondary',
        marginTop: 9.5,
        marginLeft: 2,
        marginRight: 2,
        padding: 4,
      }}
    >
      <Box>
        <CVTypography size="t2">目次</CVTypography>
      </Box>
      <Box sx={{ marginTop: 3, marginLeft: 2, marginBottom: 1 }}>
        <CVTypography size="t3">
          <a href="#TermsAndConditions">利用規約</a>
        </CVTypography>
        <CVTypography size="t3" sx={{ marginTop: 2.5 }}>
          <a href="#PrivacyPolicy">プライバシーポリシー</a>
        </CVTypography>
        <CVTypography size="t3" sx={{ marginTop: 2.5 }}>
          <a href="#Contact">お問い合わせ先</a>
        </CVTypography>
      </Box>
    </Box>
    <Box id="TermsAndConditions" sx={{ marginTop: 9.5 }}>
      <CVTypography size="t2">利用規約</CVTypography>
      <CVTypography size="b1">
        この利用規約（以下、「本規約」と呼びます）は、CREEVO開発チーム（以下、「開発元」と呼びます）がウェブサイト
        creevo-music.com 上で提供するサービス（以下、「本サービス」と呼びます）の利用条件を定めるものです。
      </CVTypography>
    </Box>
    <Box>
      <CVTypography size="t3" sx={{ marginTop: 4.5 }}>
        [1]（適用）
      </CVTypography>
      <CVTypography size="b2" sx={{ marginTop: 1.5 }}>
        本規約は、本サービスの利用者（以下、「利用者」と呼びます）と開発元との間の本サービスの利用に関わる一切の関係に適用されるものとします。
        開発元は本サービスに関し、本規約のほか、ご利用にあたってのルール等、各種の定め（以下、「個別規定」と呼びます）をすることがあります。
        これら個別規定はその名称のいかんに関わらず、本規約の一部を構成するものとします。
        本規約の規定が個別規定の規定と矛盾する場合には、個別規定において特段の定めなき限り、個別規定の規定が優先されるものとします。
      </CVTypography>
    </Box>
    <Box>
      <CVTypography size="t3" sx={{ marginTop: 4.5 }}>
        [2]（利用者の責任）
      </CVTypography>
      <CVTypography size="b2" sx={{ marginTop: 1.5 }}>
        利用者は、自己の責任において本サービスを利用するものとし、本サービスを利用してなされた一切の行為及びその結果について一切の責任を負います。
        本サービスを利用して利用者が生成した作品に関する責任は、全て利用者に帰属します。
        開発元は利用者が本サービスを利用して生成した作品の内容について、一切責任を負いません。
        利用者が他人の権利を侵害するなど法律に違反する行為を行った場合、当該利用者は自身の責任と費用においてこれを解決しなければならず、開発元は一切の責任を負いません。
      </CVTypography>
    </Box>
    <Box>
      <CVTypography size="t3" sx={{ marginTop: 4.5 }}>
        [3]（生成物に関する権利）
      </CVTypography>
      <CVTypography size="b2" sx={{ marginTop: 1.5 }}>
        本サービスを利用して生成されたメロディーや伴奏は、パブリックドメイン（著作権なし）のデータとして扱われます。
        Sinsyの歌声が入っている音声データを公開・配布する際には「Sinsy」と表示する必要があります。
        本サービスを利用する利用者は、開発元に対し、当該作品が第三者の権利を侵害するものでないこと保証するものとします。
        万一、利用者が創作した作品が第三者の権利を侵害するなど第三者との間で何らかの紛争が発生した場合には、当該利用者の費用と責任において問題を解決するとともに、開発元に何等の損害を与えないものとします。
      </CVTypography>
    </Box>
    <Box>
      <CVTypography size="t3" sx={{ marginTop: 4.5 }}>
        [4]（禁止事項）
      </CVTypography>
      <CVTypography size="b2" sx={{ marginTop: 1.5 }}>
        利用者は、本サービスの利用にあたり、以下の行為をしてはなりません。
      </CVTypography>
      <CVTypography size="b2" addDot>
        法令または公序良俗に違反する行為
      </CVTypography>
      <CVTypography size="b2" addDot>
        犯罪行為に関連する行為
      </CVTypography>
      <CVTypography size="b2" addDot>
        本サービスの内容等、本サービスに含まれる著作権、商標権ほか知的財産権を侵害する行為
      </CVTypography>
      <CVTypography size="b2" addDot>
        開発元、他の利用者、またはその他第三者のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為
      </CVTypography>
      <CVTypography size="b2" addDot>
        開発元のサービスの運営を妨害するおそれのある行為
      </CVTypography>
      <CVTypography size="b2" addDot>
        不正アクセス、またはこれを試みる行為
      </CVTypography>
      <CVTypography size="b2" addDot>
        他の利用者に関する個人情報等を収集または蓄積する行為
      </CVTypography>
      <CVTypography size="b2" addDot>
        不正な目的を持って本サービスを利用する行為
      </CVTypography>
      <CVTypography size="b2" addDot>
        本サービスの他の利用者またはその他の第三者に不利益、損害、不快感を与える行為
      </CVTypography>
      <CVTypography size="b2" addDot>
        開発元が許諾しない本サービス上での宣伝、広告、勧誘、または営業行為
      </CVTypography>
      <CVTypography size="b2" addDot>
        開発元のサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為
      </CVTypography>
      <CVTypography size="b2" addDot>
        その他、開発元が不適切と判断する行為
      </CVTypography>
    </Box>
    <Box>
      <CVTypography size="t3" sx={{ marginTop: 4.5 }}>
        [5]（本サービスの提供の停止等）
      </CVTypography>
      <CVTypography size="b2" sx={{ marginTop: 1.5 }}>
        開発元は、以下のいずれかの事由があると判断した場合、利用者に事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
      </CVTypography>
      <CVTypography size="b2" addDot>
        本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
      </CVTypography>
      <CVTypography size="b2" addDot>
        地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合
      </CVTypography>
      <CVTypography size="b2" addDot>
        本コンピュータまたは通信回線等が事故により停止した場合
      </CVTypography>
      <CVTypography size="b2" addDot>
        その他、開発元が本サービスの提供が困難と判断した場合
      </CVTypography>
      <CVTypography size="b2" addDot>
        開発元は、本サービスの提供の停止または中断により、利用者または第三者が被ったいかなる不利益または損害についても、一切の責任を負わないものとします。
      </CVTypography>
    </Box>
    <Box>
      <CVTypography size="t3" sx={{ marginTop: 4.5 }}>
        [6]（利用制限）
      </CVTypography>
      <CVTypography size="b2" sx={{ marginTop: 1.5 }}>
        開発元は、利用者が以下のいずれかに該当する場合には、事前の通知なく、利用者に対して、本サービスの全部もしくは一部の利用を制限することができるものとします。
      </CVTypography>
      <CVTypography size="b2" addDot>
        禁止事項に該当する行為を行った場合
      </CVTypography>
      <CVTypography size="b2" addDot>
        その他、開発元が本サービスの利用を適当でないと判断した場合
      </CVTypography>
      <CVTypography size="b2" addDot>
        開発元は、本項に基づき開発元が行った行為により利用者に生じた損害について、一切の責任を負いません。
      </CVTypography>
    </Box>
    <Box>
      <CVTypography size="t3" sx={{ marginTop: 4.5 }}>
        [7]（保証の否認および免責事項）
      </CVTypography>
      <CVTypography size="b2" sx={{ marginTop: 1.5 }}>
        開発元は、本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます）がないことを明示的にも黙示的にも保証しておりません。
        開発元は、本サービスに起因して利用者に生じたあらゆる損害について一切の責任を負いません。
        開発元は、本サービスに関して、利用者と他の利用者または第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。
      </CVTypography>
    </Box>
    <Box>
      <CVTypography size="t3" sx={{ marginTop: 4.5 }}>
        [8]（サービス内容の変更等）
      </CVTypography>
      <CVTypography size="b2" sx={{ marginTop: 1.5 }}>
        開発元は、利用者に通知することなく、本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし、これによって利用者に生じた損害について一切の責任を負いません。
      </CVTypography>
    </Box>
    <Box>
      <CVTypography size="t3" sx={{ marginTop: 4.5 }}>
        [9]（利用規約の変更）
      </CVTypography>
      <CVTypography size="b2" sx={{ marginTop: 1.5 }}>
        開発元は、必要と判断した場合には、利用者に通知することなくいつでも本規約を変更することができるものとします。
        なお、本規約の変更後、本サービスの利用を開始した場合には、当該利用者は変更後の規約に同意したものとみなします。
      </CVTypography>
    </Box>
    <Box>
      <CVTypography size="t3" sx={{ marginTop: 4.5 }}>
        [10]（個人情報の取扱い）
      </CVTypography>
      <CVTypography size="b2" sx={{ marginTop: 1.5 }}>
        開発元は、本サービスの利用によって取得する個人情報については、開発元「プライバシーポリシー」に従い適切に取り扱うものとします。
      </CVTypography>
    </Box>
    <Box>
      <CVTypography size="t3" sx={{ marginTop: 4.5 }}>
        [11]（収集データの取扱い）
      </CVTypography>
      <CVTypography size="b2" sx={{ marginTop: 1.5 }}>
        本サービスでは、利用者が入力するタイトルと歌詞、および利用者によるシステム編集操作のデータを開発元のサーバーに保存して、収集します。
        開発元は、利用者に通知することなく、この収集データを学術研究目的で利用できるものとします。
        ただし、開発者は、利用者の同意なく、第三者に対してタイトルと歌詞を公開しないものとします。
      </CVTypography>
    </Box>
    <Box id="PrivacyPolicy" sx={{ marginTop: 9.75 }}>
      <CVTypography size="t2">プライバシーポリシー</CVTypography>
      <CVTypography size="b2" sx={{ marginTop: 0.5 }}>
        CREEVO開発チーム（以下、「開発元」と呼びます）は、ウェブサイト creevo-music.com
        上で提供するサービス（以下、「本サービス」と呼びます）における、利用者の個人情報の取扱いについて、以下の通りプライバシーポリシー（以下、「本ポリシー」と呼びます）を定めます。
      </CVTypography>
    </Box>
    <Box>
      <CVTypography size="t3" sx={{ marginTop: 5 }}>
        [1]（個人情報の収集）
      </CVTypography>
      <CVTypography size="b2">
        本サービスでは、利用者の氏名、生年月日、住所、メールアドレスなど、特定の個人を識別できる情報を開発元のサーバーに収集する行為は行っていません。
        また、単独では特定の個人を識別することができない情報（IPアドレス情報、端末ID、Cookie等）については、本サービスの提供・運営の目的の範囲で取得します。
      </CVTypography>
    </Box>
    <Box>
      <CVTypography size="t3" sx={{ marginTop: 4.5 }}>
        [2] (外部モジュールの利用)
      </CVTypography>
      <CVTypography size="b2">
        本サービスでは、Googleによるアクセス解析ツール「Googleアナリティクス」を使用しています。
        このデータは匿名で収集されており、個人を特定するものではありません。
        この規約に関しての詳細はGoogleアナリティクスサービス利用規約のページやGoogleポリシーと規約ページをご覧ください。
      </CVTypography>
    </Box>
    <Box>
      <CVTypography size="t3" sx={{ marginTop: 4.5 }}>
        [3]（プライバシーポリシーの変更）
      </CVTypography>
      <CVTypography size="b2">
        1.
        本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、利用者に通知することなく、変更することができるものとします。
      </CVTypography>
      <CVTypography size="b2">
        2.
        開発元が別途定める場合を除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。
      </CVTypography>
    </Box>
    <Box id="Contact" sx={{ marginTop: 8.5, marginBottom: 40 }}>
      <CVTypography size="t2">お問い合わせ先</CVTypography>
      <CVTypography size="b1">
        本規約に関するお問い合わせは、CREEVO開発チーム（creevo.music@gmail.com）までお願いいたします。
      </CVTypography>
    </Box>
  </>
);
