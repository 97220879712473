import { Box } from '@mui/material';

import { CVTypography } from '../components/ui-elements/CVTypography';
import { CVHeadline } from '../components/ui-parts/CVHeadline';

export const Contact = () => (
  <>
    <Box sx={{ marginTop: 4.375 }}>
      <CVHeadline size="h1">お問い合わせ</CVHeadline>
    </Box>
    <Box sx={{ marginTop: 5.5 }}>
      <CVHeadline size="h2">CREEVOシステムへのフィードバック</CVHeadline>
      <CVTypography size="b1" sx={{ marginTop: 2.5, marginLeft: 4, marginRight: 4 }}>
        システムに関するフィードバックは
        <a href="https://forms.gle/dcEY6A8m9PyWFfAf8" target="_blank" rel="noreferrer noopener nofollow">
          こちらのフォーム
        </a>
        から投稿をお願いします。 コメントや要望、不具合の報告、質問などを受け付けています。
      </CVTypography>
    </Box>
    <Box sx={{ marginTop: 6.5 }}>
      <CVHeadline size="h2">お問い合わせ先</CVHeadline>
      <CVTypography size="b1" sx={{ marginTop: 2.5, marginLeft: 4, marginRight: 4 }}>
        本システムについての問い合わせは、
        <a href="mailto:creevo.music@gmail.com">creevo.music@gmail.com</a>
        までお願いします。
      </CVTypography>
    </Box>
  </>
);
