import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Error } from '../../pages/Error';
import { maintenanceSelector } from '../../selectors/maintenanceSelector';
import { onFetchMaintenance } from '../../slices/maintenanceSlice';
import { AppDispatch } from '../../store';
import { OldLoading } from '../ui-parts/OldLoading';

type props = {
  children: ReactElement;
};

export const MaintenanceModeProvider = (props: props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { isMaintenanceLoading, maintenanceMode } = useSelector(maintenanceSelector);
  useEffect(() => {
    void dispatch(onFetchMaintenance());
  }, []);
  if (isMaintenanceLoading) {
    return <OldLoading />;
  }
  if (maintenanceMode.isMaintenanced) {
    return <Error errorCode={503} errorMsg={maintenanceMode.message} />;
  }
  return props.children;
};
