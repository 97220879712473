import { useEffect } from 'react';

export const usePromptWhenReload = (): void => {
  let isReloading = true;
  if (localStorage.getItem('reloaded') === 'true') {
    localStorage.removeItem('reloaded');
    isReloading = false;
    window.location.replace('/composition/select-type/');
    // navigationを利用できるのはuseEffectの中。
    // それだと一度画面が描画されてからの遷移になるので
    // idやkeyが取得できないエラーが発生し遷移前に止まってしまう。
    // それを防ぐために、useEffectの前に遷移を入れておく
    // さらに、このhookを入れるコンポーネントは、idやkeyを取得する前の
    // コンポーネントの上部で`usePromptWhenReload()`する必要がある
  }

  const beforeUnloadCallback = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = '';
    return '';
  };

  const unloadCallBack = () => {
    if (isReloading) {
      // isReloading が true のとき => beforeunload プロンプトを出した上で遷移するとき
      // isReloading が false のとき => リロード後、stateが飛んだページを読み込む。その前に作曲最初の画面に飛ばす。
      //                               作曲最初の画面に飛ばした時にも unload は発火する。このとき。
      localStorage.setItem('reloaded', 'true');
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnloadCallback);
    window.addEventListener('unload', unloadCallBack);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadCallback);
      window.removeEventListener('unload', unloadCallBack);
    };
  }, []);
};
