import DeleteIcon from '@mui/icons-material/Delete';
import { Box, FormControl, FormControlLabel, Checkbox } from '@mui/material';
import { memo } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ProjectDetail as ProjectDetailType } from '../../api/RankingApi';
import { userSelector } from '../../selectors/userSelector';
import { onPutUserPublicationAgreement } from '../../slices/myListSlice';
import { onPutPublicationAgreement } from '../../slices/rankingSlice';
import { AppDispatch } from '../../store';
import { CVTypography } from '../ui-elements/CVTypography';

import { CVButton } from './CVButton';
import { CVHeadline } from './CVHeadline';
import { CVIconButton } from './CVIconButton';
import { ProjectRating } from './ProjectRating';

interface Props {
  limited: boolean;
  projectDetail: ProjectDetailType;
  selfRating: number | null;
  projectKey: string | null;
  updateRating: (newState: number | null) => void;
  setConfirmProjectDeletionModalOpen?: (newState: boolean) => void;
}

export const ProjectDetail = memo((props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const userState = useSelector(userSelector);
  const onClickDelete = () => {
    if (props.setConfirmProjectDeletionModalOpen !== undefined) {
      props.setConfirmProjectDeletionModalOpen(true);
    }
  };

  const onClickPublicationAgreementCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (userState.isLoggedIn) {
      void dispatch(
        onPutUserPublicationAgreement({
          projectId: props.projectDetail.project.projectId,
          publicationAgreed: event.target.checked,
          userInfo: userState.userInfo,
        })
      );
    } else {
      void dispatch(
        onPutPublicationAgreement({
          projectId: props.projectDetail.project.projectId,
          publicationAgreed: event.target.checked,
          projectKey: props.projectKey !== null ? props.projectKey : '',
        })
      );
    }
  };
  return (
    <>
      {props.limited ? (
        <>
          <Box>
            <CVTypography size="b2" sx={{ marginLeft: 2, marginTop: 2.625 }}>
              PID：{props.projectDetail.project.projectId}
            </CVTypography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
            <CVTypography size="h" sx={{ color: 'base.secondary' }}>
              インストゥルメンタル版
            </CVTypography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
            <Box component="audio" controls src={props.projectDetail.audioInstrPath} />
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 4,
            }}
          >
            <CVTypography size="t2" sx={{ color: 'base.primary' }}>
              {props.projectDetail.project.title}
            </CVTypography>
            {props.setConfirmProjectDeletionModalOpen !== undefined && (
              <CVIconButton
                text="この作品を削除する"
                onClick={onClickDelete}
                buttonColor="base.error"
                Icon={<DeleteIcon />}
              />
            )}
          </Box>
          <Box sx={{ marginLeft: 2, marginTop: 2.625 }}>
            <CVTypography size="b2">PID：{props.projectDetail.project.projectId}</CVTypography>
            <Box sx={{ marginTop: 1 }}>
              {props.projectDetail.project.lyrics &&
                props.projectDetail.project.lyrics
                  .split(/\n/)
                  .map((value) => <CVTypography size="b1">{value}</CVTypography>)}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ width: { xs: '100%', sm: '50%' } }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
                <CVTypography size="h" sx={{ color: 'base.secondary' }}>
                  合成歌声版
                </CVTypography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                <Box component="audio" controls src={props.projectDetail.audioPath} />
              </Box>
            </Box>
            <Box sx={{ width: { xs: '100%', sm: '50%' } }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
                <CVTypography size="h" sx={{ color: 'base.secondary' }}>
                  インストゥルメンタル版
                </CVTypography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                <Box component="audio" controls src={props.projectDetail.audioInstrPath} />
              </Box>
            </Box>
          </Box>
          <CVTypography size="h" sx={{ color: 'primary.main', marginTop: 7 }}>
            作曲のヒント
          </CVTypography>
          <CVTypography size="b2" sx={{ color: 'primary.main', marginTop: 1 }}>
            「デザイン作曲」ではテンポやコード進行、メロディーと伴奏のスタイルなどが選択でき、曲の雰囲気を変化させられます。
          </CVTypography>
          <Box sx={{ marginTop: 9.75 }}>
            <CVHeadline size="h2">曲を評価する</CVHeadline>
            <CVTypography size="c1" sx={{ color: 'base.secondary', marginLeft: 5, marginTop: 1 }}>
              評価をすることで楽譜や動画などの作品データが表示され、ダウンロードできるようになります。
            </CVTypography>
            <ProjectRating rating={props.selfRating} setRating={props.updateRating} enableRating />
            {props.selfRating !== 0 && (
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 1 }}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={onClickPublicationAgreementCheckbox}
                        checked={props.projectDetail.project.publicationAgreed}
                      />
                    }
                    label="タイトル、歌詞や動画を含めた作品データの公開に同意します"
                  />
                </FormControl>
              </Box>
            )}
          </Box>
          <Box sx={{ marginTop: 8 }}>
            <CVHeadline size="h2">楽曲データのダウンロード</CVHeadline>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 5.25,
              }}
            >
              <CVButton
                text="楽曲データをダウンロードする"
                onClick={() => window.open(props.projectDetail.zipPath)}
                size="ll"
                disabled={props.selfRating === 0}
              />
            </Box>
          </Box>
          {props.selfRating !== 0 && (
            <Box>
              <Box
                component="img"
                sx={{
                  backgroundColor: 'white',
                  display: 'block',
                  width: { xs: '100%', lg: 1080 },
                  margin: 'auto',
                  height: 'auto',
                  marginTop: 10,
                }}
                src={props.projectDetail.scoreImagePath}
                alt="Score"
              />
              <Box
                component="video"
                controls
                sx={{
                  backgroundColor: 'white',
                  display: 'block',
                  width: { xs: '100%', lg: 1080 },
                  margin: 'auto',
                  height: 'auto',
                  marginTop: 15,
                }}
                src={props.projectDetail.videoPath}
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 10,
                }}
              >
                <CVButton
                  text="楽曲データをダウンロードする"
                  onClick={() => window.open(props.projectDetail.zipPath)}
                  size="ll"
                />
              </Box>
            </Box>
          )}
        </>
      )}
      <Box sx={{ marginTop: 8.5 }}>
        <CVHeadline size="h2">楽曲データの取扱いについて</CVHeadline>
        <CVTypography size="b2" addDot sx={{ marginTop: 4 }}>
          本ページに表示される、タイトルと歌詞はご利用者が自ら入力したものであり、本サービス提供者がその内容を承認したものではありません。
        </CVTypography>
        <CVTypography size="b2" addDot sx={{ marginTop: 1 }}>
          歌声合成には
          <a href="https://sinsy.jp/" target="_blank" rel="noreferrer noopener nofollow">
            Sinsy
          </a>
          を使っています。
        </CVTypography>
        <CVTypography size="b2" addDot sx={{ marginTop: 1 }}>
          楽譜表示には
          <a href="https://musescore.org/" target="_blank" rel="noreferrer noopener nofollow">
            MuseScore
          </a>
          を使っています。
        </CVTypography>
        <CVTypography size="b2" addDot sx={{ marginTop: 1 }}>
          作曲されたメロディーが既存曲に酷似していると判断された場合は、
          <a href="https://forms.gle/dcEY6A8m9PyWFfAf8" target="_blank" rel="noreferrer noopener nofollow">
            こちら
          </a>
          から報告し、作品データはダウンロードしないようにお願いします。
        </CVTypography>
        <CVTypography size="b2" addDot sx={{ marginTop: 1 }}>
          CREEVOで生成されたメロディーや伴奏は、パブリックドメイン（著作権なし）のデータとして扱われます。
          Sinsyの歌声が入っている音声データを公開・配布する際には「Sinsy」と表示する必要があります。
          <Box component="img" src="https://licensebuttons.net/p/mark/1.0/80x15.png" />
        </CVTypography>
        <CVTypography size="b2" sx={{ marginTop: 4.25, marginBottom: 50 }}>
          <a href="https://forms.gle/dcEY6A8m9PyWFfAf8" target="_blank" rel="noreferrer noopener nofollow">
            システムに関するコメント、不具合の報告、リクエストをする
          </a>
        </CVTypography>
      </Box>
    </>
  );
});
